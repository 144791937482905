import styled from "styled-components";
import { colors } from "../../../../../../styles/variables";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${colors.inputDefaultBorder};
    background-color: ${colors.white};
`;

const Label = styled.span`
    color: ${colors.activeText};
    font-family: Roboto;
    font-size: 16;
    font-style: normal;
    font-weight: 600;
    line-height: "105%";
`;

const Text = styled.span<{ $color?: string }>`
    color: ${({ $color }) => $color};
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
`;

export {
    Box,
    Label,
    Text,
};
