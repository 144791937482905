import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import styled from "styled-components";

import { colors } from "../../../../../../styles/variables";

export const SelectComponent = styled(Select)<SelectProps>`
    &&.ant-select .ant-select-selection-item {
        color: ${colors.activeText};
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
    }
`;
