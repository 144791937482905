import { useState } from "react";

import {
    averageTransactionsAmountConfigList,
    cardsConfigList,
    customersChartConfigList,
    numberTransactionsConfigList,
    statusesTransactionsConfigList,
} from "../../../../constants/chartsMerchantsStatistic";
import { currencyOptionsHandbook, getTranslatedHandbook } from "../../../../constants/handbooks";
import useTranslation from "../../../../hooks/useTranslation";
import merchantStore from "../../../../store/home/merchant/merchantStore";
import EmptyView from "../../../ui/emptyView/EmptyView";
import Select from "./components/Select/Select";
import ChartSection from "./components/ChartSection";

const MerchantCharts = () => {
    const { t, translate } = useTranslation();
    const currencyOptions = getTranslatedHandbook(translate, currencyOptionsHandbook);

    const [currencyValue, setCurrencyValue] = useState(currencyOptions.at(0)?.value);
    const changeCurrency = (value: string | number) => setCurrencyValue(value);

    const {
        amountCharts,
        numberTransactionsChart,
        statusesTransactionsCharts,
        cardsChart,
        customersChart,
    } = merchantStore;

    const hasData = amountCharts
        && numberTransactionsChart
        && statusesTransactionsCharts
        && cardsChart
        && customersChart;

    const actionsContentList = [
        {
            key: "transactions-currency-select",
            content: (
                <Select
                    options={currencyOptions}
                    defaultValue={currencyOptions.at(0)?.value}
                    onChange={changeCurrency}
                />
            ),
        },
    ];

    const chartsConfigList = [
        {
            key: "merchant-chart-transactions-number",
            title: t.home.chartsTitle.averageTransactionsAmount,
            data: amountCharts?.[currencyValue],
            lines: averageTransactionsAmountConfigList,
            actionsContent: actionsContentList,
        },
        {
            key: "merchant-chart-transactions-chart",
            title: t.home.chartsTitle.transactions,
            subtitle: t.home.chartsSubtitle.perCustomerID,
            data: numberTransactionsChart,
            lines: numberTransactionsConfigList,
        },
        {
            key: "merchant-chart-success-status",
            title: t.home.chartsTitle.successfulTransactions,
            subtitle: t.home.chartsSubtitle.perCustomerID,
            data: statusesTransactionsCharts?.success,
            lines: statusesTransactionsConfigList,
        },
        {
            key: "merchant-chart-declined-status",
            title: t.home.chartsTitle.declinedTransactions,
            subtitle: t.home.chartsSubtitle.perCustomerID,
            data: statusesTransactionsCharts?.decline,
            lines: statusesTransactionsConfigList,
        },
        {
            key: "merchant-chart-cards",
            title: t.home.chartsTitle.cards,
            subtitle: t.home.chartsSubtitle.perCustomerID,
            data: cardsChart,
            lines: cardsConfigList,
        },
        {
            key: "merchant-chart-customer",
            title: t.home.chartsTitle.customerIds,
            subtitle: t.home.chartsSubtitle.perCard,
            data: customersChart,
            lines: customersChartConfigList,
        },
    ];

    return hasData ? (
        <>{chartsConfigList.map((props) => <ChartSection {...props} />)}</>
    ) : (
        <EmptyView title={t.home.emptyView.title} text={t.home.emptyView.merchant.text} />
    );
};

export default MerchantCharts;
