import { HyperLinkInputContainer, InputLabel, Text } from "./InputStyles";
import ProtectedHyperLink from "./ProtectedHyperlink";

interface HyperlinkTextInputProps {
    permission?: string;
    elementId?: number | string |null;
    hyperlinkName?: string | null;
    navigateTo?: string;
    target?: "_blank" | "_parent" | "_top" | "_self";
    label: string;
    isGrid?: boolean;
    value?: string | number;
}

const HyperlinkTextInput = ({
    permission,
    elementId,
    hyperlinkName,
    navigateTo,
    target,
    label,
    isGrid,
    value = "",
}: HyperlinkTextInputProps) => (
    <HyperLinkInputContainer $isGrid={isGrid}>
        <InputLabel>{label}</InputLabel>

        <Text>
            {value}
            &nbsp;
            {hyperlinkName && navigateTo ? (
                <ProtectedHyperLink
                    target={target}
                    navigateTo={elementId ? `${navigateTo}/${elementId}` : navigateTo}
                    permission={permission}
                    linkName={hyperlinkName}
                />
            ) : (
                "-"
            )}
        </Text>
    </HyperLinkInputContainer>
);

export default HyperlinkTextInput;
