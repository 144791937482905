import { observer } from "mobx-react-lite";

import useTranslation from "../../../../../hooks/useTranslation";
import authStore from "../../../../../store/auth/authStore";
import MenuDropdown from "../../../../ui/menuDropdown/MenuDropdown";
import { getTransactionsMenuItems } from "./transactionsMenuHelpers";

interface TransactionsMenuProps {
    downloadFile: () => Promise<void>;
    toggleParametersModal: () => void;
}

const TransactionsMenu = ({ downloadFile, toggleParametersModal }: TransactionsMenuProps) => {
    const { translate } = useTranslation();
    const { userPermissions } = authStore;

    const menuDropDownItems = getTransactionsMenuItems(
        userPermissions,
        translate,
        downloadFile,
        toggleParametersModal,
    );

    return <MenuDropdown items={menuDropDownItems} />;
};

export default observer(TransactionsMenu);
