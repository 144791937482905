import { ReactNode } from "react";

import { MerchantChartEntryTypes } from "../../../../../types/merchantTypes";
import { PeriodicStatisticTypes } from "../../../../../types/transactionsStatisticTypes";
import TabHeader from "../../../../tabHeader/TabHeader";
import Chart, { LineConfig } from "../../components/Chart/Chart";
import ChartTooltip from "./ChartTooltip";

interface ChartSectionProps {
    title: string;
    subtitle?: string;
    data?: PeriodicStatisticTypes[] | MerchantChartEntryTypes[];
    lines: LineConfig[];
    actionsContent?: { content: ReactNode, key: string }[];
}

const ChartSection = ({
    title,
    subtitle,
    lines,
    data = [],
    actionsContent,
}: ChartSectionProps) => (
    <>
        <TabHeader title={title} subtitle={subtitle} actionsContent={actionsContent} />
        <Chart
            isDotWithAction
            data={data}
            lines={lines}
            TooltipComponent={ChartTooltip}
        />
    </>
);

export default ChartSection;
