import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";

import { permissionsMap } from "../../constants/permissions";
import RoutesLinks, { AuthRoutes } from "../../constants/routes";
import AuthorizationLayout from "../authorizationLayout/AuthorizationLayout";
import MainLayout from "../mainLayout/MainLayout";
import AlertDetails from "../pages/alerts/alerts/details/AlertDetails";
import NewAlert from "../pages/alerts/alerts/new/NewAlert";
import NotificationDetails from "../pages/alerts/notifications/details/NotificationDetails";
import PagesAlerts from "../pages/alerts/PagesAlerts";
import TgGroupDetails from "../pages/alerts/tgGroups/details/TgGroupDetails";
import NewTgGroup from "../pages/alerts/tgGroups/new/NewTgGroup";
import ChangePassword from "../pages/auth/password/recovery/PagesChangePassword";
import ElementDetails from "../pages/elements/details/ElementDetails";
import NewElement from "../pages/elements/new/NewElement";
import PagesElements from "../pages/elements/PagesElements";
import EmployeeDetails from "../pages/employees/employees/details/EmployeeDetails";
import NewEmployee from "../pages/employees/employees/new/NewEmployee";
import PagesEmployees from "../pages/employees/PagesEmployees";
import RoleDetails from "../pages/employees/roles/details/RoleDetails";
import NewRole from "../pages/employees/roles/new/NewRole";
import PagesEntities from "../pages/entities/PagesEntities";
import PartnerDetails from "../pages/entities/partners/details/PartnerDetails";
import NewPartners from "../pages/entities/partners/new/NewPartners";
import ProcessingDetails from "../pages/entities/processing/details/ProcessingDetails";
import NewProcessing from "../pages/entities/processing/new/NewProcessing";
import ServiceDetails from "../pages/entities/services/details/ServiceDetails";
import NewServices from "../pages/entities/services/new/NewServices";
import PagesHome from "../pages/home/PagesHome";
import CodeEdit from "../pages/limits/components/codeEdit/CodeEdit";
import LimitDetails from "../pages/limits/details/LimitDetails";
import NewLimit from "../pages/limits/new/NewLimit";
import PagesLimits from "../pages/limits/PagesLimits";
import ListDetails from "../pages/lists/details/ListDetails";
import NewList from "../pages/lists/new/NewList";
import PagesLists from "../pages/lists/PagesLists";
import PagesQueries from "../pages/queries/PagesQueries";
import QueryDetails from "../pages/queries/queries/details/QueryDetails";
import NewQuery from "../pages/queries/queries/new/NewQuery";
import ResultDetails from "../pages/queries/results/details/ResultDetails";
import RuleDetails from "../pages/rulesPacks/details/RuleDetails";
import NewRulesPack from "../pages/rulesPacks/new/NewRulesPack";
import PagesRulesPacks from "../pages/rulesPacks/PagesRulesPacks";
import PagesSettings from "../pages/settings/PagesSettings";
import TransactionDetails from "../pages/transactions/details/TransactionDetails";
import PagesTransactions from "../pages/transactions/PagesTransactions";
import ProtectedElement from "./components/ProtectedElement";

const AuthenticatedRoutes = () => (
    <Routes>
        <Route path={AuthRoutes.password.href} element={<AuthorizationLayout />}>
            <Route
                path={AuthRoutes.passwordChangedPassword.href}
                element={<ChangePassword />}
            />
        </Route>

        <Route path={RoutesLinks.home.href} element={<MainLayout />}>
            <Route path={RoutesLinks.home.href} element={<PagesHome />} />

            <Route path={RoutesLinks.lists.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesLists />}
                            requiredPermission={permissionsMap.lists.view}
                        />
                    )}
                />

                <Route
                    path="new"
                    element={(
                        <ProtectedElement
                            element={<NewList />}
                            requiredPermission={permissionsMap.lists.add}
                        />
                    )}
                />
                <Route
                    path=":id"
                    element={(
                        <ProtectedElement
                            element={<ListDetails />}
                            requiredPermission={permissionsMap.lists.view}
                        />
                    )}
                />
            </Route>

            <Route path={RoutesLinks.limits.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement element={<PagesLimits />} requiredPermission={permissionsMap.limits.view} />
                    )}
                />

                <Route
                    path="new"
                    element={<ProtectedElement element={<NewLimit />} requiredPermission={permissionsMap.limits.add} />}
                />
                <Route
                    path=":id"
                    element={(
                        <ProtectedElement element={<LimitDetails />} requiredPermission={permissionsMap.limits.view} />
                    )}
                />
                <Route path="code">
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement element={<CodeEdit />} requiredPermission={permissionsMap.limits.view} />
                        )}
                    />
                </Route>
            </Route>

            <Route path={RoutesLinks.elements.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesElements />}
                            requiredPermission={permissionsMap.elements.view}
                        />
                    )}
                />

                <Route
                    path="new"
                    element={(
                        <ProtectedElement
                            element={<NewElement />}
                            requiredPermission={permissionsMap.elements.add}
                        />
                    )}
                />
                <Route
                    path=":id"
                    element={(
                        <ProtectedElement
                            element={<ElementDetails />}
                            requiredPermission={permissionsMap.elements.view}
                        />
                    )}
                />
            </Route>

            <Route path={RoutesLinks.entities.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesEntities />}
                            requiredPermission={[
                                permissionsMap.partners.view,
                                permissionsMap.services.view,
                                permissionsMap.processings.view,
                            ]}
                        />
                    )}
                />

                <Route path={RoutesLinks.partners.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewPartners />}
                                requiredPermission={permissionsMap.partners.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<PartnerDetails />}
                                requiredPermission={permissionsMap.partners.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.services.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewServices />}
                                requiredPermission={permissionsMap.services.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<ServiceDetails />}
                                requiredPermission={permissionsMap.services.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.processing.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewProcessing />}
                                requiredPermission={permissionsMap.processings.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<ProcessingDetails />}
                                requiredPermission={permissionsMap.processings.view}
                            />
                        )}
                    />
                </Route>
            </Route>

            <Route path={RoutesLinks.transactions.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesTransactions />}
                            requiredPermission={permissionsMap.transactions.view}
                        />
                    )}
                />
                <Route
                    path=":id"
                    element={(
                        <ProtectedElement
                            element={<TransactionDetails />}
                            requiredPermission={permissionsMap.transactions.view}
                        />
                    )}
                />
            </Route>

            <Route path={RoutesLinks.rulesPacks.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesRulesPacks />}
                            requiredPermission={permissionsMap.rules_packs.view}
                        />
                    )}
                />
                <Route
                    path="new"
                    element={(
                        <ProtectedElement
                            element={<NewRulesPack />}
                            requiredPermission={permissionsMap.rules_packs.add}
                        />
                    )}
                />
                <Route
                    path=":id"
                    element={(
                        <ProtectedElement
                            element={<RuleDetails />}
                            requiredPermission={permissionsMap.rules_packs.view}
                        />
                    )}
                />
            </Route>

            <Route path={RoutesLinks.alerts.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesAlerts />}
                            requiredPermission={[
                                permissionsMap.alerts.view,
                                permissionsMap.notifications.view,
                                permissionsMap.tg_groups.view,
                            ]}
                        />
                    )}
                />

                <Route path={RoutesLinks.alert.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewAlert />}
                                requiredPermission={permissionsMap.alerts.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<AlertDetails />}
                                requiredPermission={permissionsMap.alerts.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.notifications.href}>
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<NotificationDetails />}
                                requiredPermission={permissionsMap.notifications.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.groups.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewTgGroup />}
                                requiredPermission={permissionsMap.tg_groups.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<TgGroupDetails />}
                                requiredPermission={permissionsMap.tg_groups.view}
                            />
                        )}
                    />
                </Route>
            </Route>

            <Route path={RoutesLinks.queries.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesQueries />}
                            requiredPermission={[permissionsMap.queries.view, permissionsMap.results.view]}
                        />
                    )}
                />

                <Route path={RoutesLinks.query.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewQuery />}
                                requiredPermission={permissionsMap.queries.add}
                            />
                        )}
                    />

                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<QueryDetails />}
                                requiredPermission={permissionsMap.queries.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.results.href}>
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<ResultDetails />}
                                requiredPermission={permissionsMap.results.view}
                            />
                        )}
                    />
                </Route>
            </Route>

            <Route path={RoutesLinks.employees.href}>
                <Route
                    path=""
                    element={(
                        <ProtectedElement
                            element={<PagesEmployees />}
                            requiredPermission={[permissionsMap.employees.view, permissionsMap.roles.view]}
                        />
                    )}
                />

                <Route path={RoutesLinks.employee.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewEmployee />}
                                requiredPermission={permissionsMap.employees.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<EmployeeDetails />}
                                requiredPermission={permissionsMap.employees.view}
                            />
                        )}
                    />
                </Route>

                <Route path={RoutesLinks.roles.href}>
                    <Route
                        path="new"
                        element={(
                            <ProtectedElement
                                element={<NewRole />}
                                requiredPermission={permissionsMap.roles.add}
                            />
                        )}
                    />
                    <Route
                        path=":id"
                        element={(
                            <ProtectedElement
                                element={<RoleDetails />}
                                requiredPermission={permissionsMap.roles.view}
                            />
                        )}
                    />
                </Route>
            </Route>

            <Route path={RoutesLinks.settings.href}>
                <Route path="" element={<PagesSettings />} />
            </Route>

            <Route path="*" element={<Navigate to={AuthRoutes.login.href} replace />} />
        </Route>
    </Routes>
);

export default observer(AuthenticatedRoutes);
