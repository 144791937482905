import { colors } from "../styles/variables";

const averageTransactionsAmountConfigList = [
    {
        key: "average_avg_value",
        name: "Average transaction amount",
        dataKey: "avg_value",
        activeDot: false,
        stroke: colors.textPrimary,
    },
    {
        key: "average_max_value",
        name: "Max transaction amount",
        dataKey: "max_value",
        activeDot: false,
        stroke: colors.error,
    },
    {
        key: "transaction_ids_at_peak",
        name: "Transaction ID",
        dataKey: "transaction_ids_at_peak",
        stroke: "",
        activeDot: false,
        hide: true,
    },
];

const numberTransactionsConfigList = [
    {
        key: "number_avg_value",
        name: "Average number of transactions",
        dataKey: "avg_value",
        activeDot: false,
        stroke: colors.textPrimary,
    },
    {
        key: "number_max_value",
        name: "Max number of transactions",
        dataKey: "max_value",
        activeDot: false,
        stroke: colors.error,
    },
    {
        key: "customer_ids_at_peak",
        name: "Customer ID",
        dataKey: "customer_ids_at_peak",
        stroke: "",
        activeDot: false,
        hide: true,
    },
];

const statusesTransactionsConfigList = [
    {
        key: "statuses_avg_value",
        name: "Average number of transactions",
        dataKey: "avg_value",
        activeDot: false,
        stroke: colors.textPrimary,
    },
    {
        key: "statuses_max_value",
        name: "Max number of transactions",
        dataKey: "max_value",
        activeDot: false,
        stroke: colors.error,
    },
    {
        key: "statuses_customer_ids_at_peak",
        name: "Customer ID",
        dataKey: "customer_ids_at_peak",
        stroke: "",
        activeDot: false,
        hide: true,
    },
];

const cardsConfigList = [
    {
        key: "cards_avg_value",
        name: "Average number of cards",
        dataKey: "avg_value",
        activeDot: false,
        stroke: colors.textPrimary,
    },
    {
        key: "cards_max_value",
        name: "Max number of cards",
        dataKey: "max_value",
        activeDot: false,
        stroke: colors.error,
    },
    {
        key: "cards_customer_ids_at_peak",
        name: "Customer ID",
        dataKey: "customer_ids_at_peak",
        stroke: "",
        activeDot: false,
        hide: true,
    },
];

const customersChartConfigList = [
    {
        key: "id_avg_value",
        name: "Average number of customer IDs",
        dataKey: "avg_value",
        activeDot: false,
        stroke: colors.textPrimary,
    },
    {
        key: "id_max_value",
        name: "Max number of customer IDs",
        dataKey: "max_value",
        activeDot: false,
        stroke: colors.error,
    },
    {
        key: "cards_at_peak",
        name: "Card",
        dataKey: "cards_at_peak",
        stroke: "",
        activeDot: false,
        hide: true,
    },
];

export {
    averageTransactionsAmountConfigList,
    numberTransactionsConfigList,
    statusesTransactionsConfigList,
    cardsConfigList,
    customersChartConfigList,
};
