export enum Tabs {
    PARTNERS = "partners",
    SERVICES = "services",
    PROCESSINGS = "processings",
    RULES_PACKS = "rules_packs",
    LISTS = "lists",
    LIMITS = "limits",
    ALERTS = "alerts",
    NOTIFICATIONS = "notifications",
    TG_GROUPS = "tg_groups",
    EMPLOYEES = "employees",
    ROLES = "roles",
    TRANSACTIONS = "transactions",
    MERCHANT = "merchant",
    NOT_SPECIFIED = "",
    QUERIES = "queries",
    RESULTS = "results",
}
