import { PermissionActionKeys, PermissionActions } from "../types/permissionsTypes";

export const createPermissions = (entity: PermissionActionKeys): PermissionActions => ({
    add: `add_${entity}`,
    view: `view_${entity}`,
    change: `change_${entity}`,
    delete: `delete_${entity}`,
    changeStatus: `change_${entity}_status`,
    run: `run_${entity}`,
    download: `download_${entity}`,
    uploadDownload: `upload_download_${entity}`,
});
