import request from "../request";

export interface GetFileStatusResponseType {
    message: string;
    response: { status: "SUCCESS" | "PENDING" | "ERROR" };
}

export const getTransactionsFileStatus = (fileId: string, signal?: AbortSignal) => request<GetFileStatusResponseType>({
    method: "GET",
    uri: `transactions/files/status/${fileId}/`,
    signal,
});
