import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { Tabs } from "../../../../../constants/tabs";
import { parseDate } from "../../../../../helpers";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useTranslation from "../../../../../hooks/useTranslation";
import { ReactComponent as PlayIcon } from "../../../../../resources/icons/play.svg";
import queriesStore from "../../../../../store/queries/queries/queriesStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper,
    DetailsPageWrapper,
    DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import GlobalLoader from "../../../../ui/loader/GlobalLoader";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditQueryModal from "./EditQueryModal";
import ProtectedSmallButton from "../../../../ui/button/ProtectedSmallButton";

const QueryDetails = () => {
    const { t, concatenate } = useTranslation();
    const { id: queryId } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = queriesStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = queryId ? `${t.queries.text.partOfLink} №${queryId}` : "";
    const headerTitle = generateHeaderName(t.queries.title, currentTitle);

    const handleRunQuery = async () => {
        if (queryId) {
            await queriesStore.runQuery(queryId);

            if (queriesStore.isRunQuerySuccess) {
                const snackbarSuccessText = concatenate(
                    t.queries.snackbar.runQuerySuccess,
                    { queryId: queriesStore.queryResultId as string },
                );
                snackbarStore.add({ variant: "success", text: snackbarSuccessText });
            } else if (queriesStore.isRunQueryFailed) {
                snackbarStore.add({ variant: "error", text: t.lists.snackbar.addFileError });
            }
        }
    };

    const handleNavigate = () => navigateBackward({ tab: Tabs.QUERIES });

    const actionHeaderContentList = [
        {
            key: "run-button-component",
            content: (
                <ProtectedSmallButton
                    title={t.buttons.run}
                    isLoading={queriesStore.isRunQueryLoading}
                    disabled={!selectedItem?.is_active || queriesStore.isRunQueryLoading}
                    iconLeft={<PlayIcon />}
                    variant="tertiary"
                    permission={permissionsMap.queries.run}
                    onClick={handleRunQuery}
                />
            ),
        },
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const preInputsParamsList = [
        { label: t.queries.placeholders.name, value: selectedItem?.name, key: "query-details-name" },
        { label: t.queries.placeholders.id, value: selectedItem?.id, key: "query-details-id" },
        {
            label: t.queries.placeholders.template,
            value: selectedItem?.template,
            key: "query-details-display-template",
        },
        {
            label: t.queries.placeholders.range,
            value: selectedItem?.date_range,
            key: "query-details-display-range",
        },
        {
            label: t.queries.placeholders.type,
            value: selectedItem?.query_type,
            key: "query-details-query-type",
        },
        {
            label: t.queries.placeholders.queryPeriod,
            value: selectedItem?.period,
            key: "query-details-data-period",
        },
        {
            label: t.queries.placeholders.queryStartTime,
            value: parseDate(selectedItem?.start_time),
            key: "query-start-time",
        },
        {
            label: t.alerts.placeholders.createdAt,
            value: selectedItem?.created,
            key: "query-details-created-at",
        },
        {
            label: t.alerts.placeholders.createdBy,
            value: selectedItem?.created_by_name,
            key: "query-details-created_by",
        },
        {
            label: t.alerts.placeholders.partner,
            value: selectedItem?.partners.map((partner) => partner.name).join(", "),
            key: "query-details-partners",
        },
        {
            label: t.alerts.placeholders.service,
            value: selectedItem?.services.map((service) => service.name).join(", "),
            key: "query-details-services",
        },
        {
            label: t.alerts.placeholders.processing,
            value: selectedItem?.processing?.id,
            key: "query-details-processing",
        },
    ];

    useEffect(() => {
        if (queryId) queriesStore.fetchSelectedItem(queryId);

        return () => {
            queriesStore.resetQueryResultId();
            queriesStore.clearSelectedItem();
        };
    }, [queryId]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        permission={permissionsMap.queries.change}
                        title={`${t.queries.text.partOfLink} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        isEditAllowed={queriesStore.isRunQueryLoading}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map((input) => <PreInput {...input} />)}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.queries.placeholders.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <EditQueryModal
                    title={t.queries.edit.title}
                    buttonText={t.buttons.save}
                    isOpen={isEditModalVisible}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
            <GlobalLoader
                isLoading={queriesStore.isGettingItemDetailsInProgress}
                size="32"
                variant="tertiary"
            />
        </>
    );
};

export default observer(QueryDetails);
