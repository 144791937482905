import request from "../request";
import { RulePackFiltersTypes } from "../../types/rulesPacksTypes";
import { RulesPacksServicesTypes } from "../../types/rulesPacksServicesTypes";

type GetRulesPacksServicesRequest = RulePackFiltersTypes & {
    rules_pack?: string | number | null
    limit?: number
    offset?: number
    ordering?: string | null
}

interface GetRulesPacksServicesResponse {
    message: string,
    response: {
        count: number,
        results: RulesPacksServicesTypes[],
    }
}

const getRulesPacksServices = (
    params?: GetRulesPacksServicesRequest,
    signal?: AbortSignal,
) => request<GetRulesPacksServicesResponse>({
    method: "GET",
    uri: "rules-packs/services/",
    params,
});

export default getRulesPacksServices;
