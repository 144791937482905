import { Dropdown, MenuProps } from "antd";
import { cloneElement, ReactElement, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../resources/icons/closeIcon.svg";
import { ReactComponent as MoreIcon } from "../../../resources/icons/moreIcon.svg";
import { dropdownStyle, MenuDropdownButton } from "./MenuDropdownComponents";

// TODO: refactor the code!
const MenuDropdown = ({ items, ...props }: MenuProps) => {
    const [isMenuDropdownVisible, setMenuDropdownVisibility] = useState(false);
    const toggleMenuDropdown = () => setMenuDropdownVisibility((prev) => !prev);

    const onClick: MenuProps["onClick"] = () => toggleMenuDropdown();

    const onHandleOpenChange = (_: boolean, info: { source: "trigger" | "menu" }) => {
        if (info.source !== "menu") toggleMenuDropdown();
    };

    return (
        <Dropdown
            {...props}
            menu={{ items, onClick }}
            trigger={["click"]}
            open={isMenuDropdownVisible}
            overlayClassName="lolkeke"
            onOpenChange={onHandleOpenChange}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={(menu) => <>{cloneElement(menu as ReactElement<any>, { style: dropdownStyle })}</>}
        >
            <MenuDropdownButton $isVisible={isMenuDropdownVisible}>
                {isMenuDropdownVisible ? <CloseIcon /> : <MoreIcon />}
            </MenuDropdownButton>
        </Dropdown>
    );
};

export default MenuDropdown;
