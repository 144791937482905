import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";

import { FieldMode } from "../../../../../../constants/selectFields";
import useTranslation from "../../../../../../hooks/useTranslation";
import filtersListsElementsStore from "../../../../../../store/filters/listsElementsFiltersStore";
import listElementsStore from "../../../../../../store/list/listElementsStore";
import { FiltersElementsType } from "../../../../../../types/elementsTypes";
import { FormContainer, HeaderContainer, HeaderTitle } from "../../../../../filters/FiltersModalComponents";
import Button from "../../../../../ui/button/Button";
import { TextButton } from "../../../../../ui/button/ButtonStyles";
import FloatInput from "../../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../../ui/textInput/FloatSelect";

interface ListsElementsFiltersProps {
    closeModal: () => void;
}

const ListsElementsFilters = ({ closeModal }: ListsElementsFiltersProps) => {
    const { t } = useTranslation();

    const initialValues: FiltersElementsType = {
        ...listElementsStore.filters,
    };

    const formik = useFormik<FiltersElementsType>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            listElementsStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        listElementsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.elements.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={filtersListsElementsStore.options}
                    mode={FieldMode.Multiple}
                    placeholder={t.filters.fields.elementsIdName}
                />
                <FloatInput
                    formik={formik}
                    name="description"
                    placeholder={t.filters.fields.description}
                />
                <Button
                    variant="primary"
                    isLoading={listElementsStore.loading}
                    type="submit"
                >
                    {t.filters.apply}
                </Button>
            </FormContainer>
        </>
    );
};

export default observer(ListsElementsFilters);
