import {
    AfStatisticTypes,
    CoreStatisticTypes,
    PeriodicStatisticTypes,
    StatisticFiltersTypes,
} from "../../types/transactionsStatisticTypes";
import request from "../request";

interface GetMerchantResponse {
    message: string,
    response: {
        core: CoreStatisticTypes;
        af: AfStatisticTypes;
        periodic: PeriodicStatisticTypes[];
    }
}

const getMerchant = (params: StatisticFiltersTypes, signal?: AbortSignal) => request<GetMerchantResponse>({
    method: "GET",
    uri: "home/merchant-info/",
    params,
    signal,
});

export default getMerchant;
