import * as Yup from "yup";

import {
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_EMAIL_LENGTH,
    MAX_FIELD_NAME_LENGTH,
    MAX_TRIGGER_FILED_LENGTH,
} from "../../../../constants/validationRules";
import { TranslationsTypes } from "../../../../hooks/useTranslation";
import { AlertTypeValue } from "../alerts/new/NewAlert";
import { NewAlertTypes } from "../../../../types/alertsTypes";

const fieldsList: Array<keyof NewAlertTypes> = [
    "name",
    "display_attributes",
    "alert_type",
    "trigger_text",
    "email",
    "tg_group",
    "importance",
    "partners",
    "services",
    "description",
];

const getInitialValues = (tgGroupId: number): NewAlertTypes => ({
    name: "",
    display_attributes: null,
    alert_type: tgGroupId ? AlertTypeValue.TG : null,
    trigger_text: "",
    tg_group: tgGroupId || null,
    importance: null,
    email: null,
    partners: null,
    services: null,
    processing: null,
    description: "",
    is_active: true,
});

const validationSchema = (t: TranslationsTypes, translate: (text: string) => string) => (
    Yup.object().shape({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),

        display_attributes: Yup.array().required(t.validation.required),
        alert_type: Yup.string().required(t.validation.required),

        trigger_text: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_TRIGGER_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_TRIGGER_FILED_LENGTH}`),
            ),

        importance: Yup.string().required(t.validation.required),

        tg_group: Yup.string()
            .when("alert_type", ([alert_type], schema) => ((
                alert_type === AlertTypeValue.TG
            )
                ? schema.required(t.validation.required)
                : schema.nullable())),

        email: Yup.string()
            .when("alert_type", ([alert_type], schema) => ((
                alert_type === AlertTypeValue.EMAIL
            )
                ? schema.email(t.validation.invalidEmail)
                    .required(t.validation.required)
                    .max(
                        MAX_EMAIL_LENGTH,
                        translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`),
                    )
                : schema.nullable())),

        partners: Yup.array().when(["services", "processing"], ([services, processing], schema) => (
            ((services && services.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        services: Yup.array().when(["partners", "processing"], ([partners, processing], schema) => (
            ((partners && partners.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        processing: Yup.string().when(["partners", "services"], ([partners, services], schema) => (
            ((partners && partners.length) || (services && services.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]])
);

const getFieldStates = (formikValues: NewAlertTypes, alertsLoading: boolean) => {
    const {
        services,
        partners,
        processing,
    } = formikValues;

    return {
        isPartnersFieldRequired: !services || !processing,
        isServicesFieldRequired: !partners || !processing,
        isProcessingFieldRequired: !services || !partners,

        isPartnersFieldDisabled: alertsLoading || !!services || !!processing,
        isServicesFieldDisabled: alertsLoading || !!partners || !!processing,
        isProcessingFieldDisabled: alertsLoading || !!partners || !!services,
    };
};

export {
    fieldsList,
    getInitialValues,
    validationSchema,
    getFieldStates,
};
