import { FC, ReactElement } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from "recharts";

import { MerchantChartEntryTypes } from "../../../../../types/merchantTypes";
import { PeriodicStatisticTypes } from "../../../../../types/transactionsStatisticTypes";
import { DetailsSectionWrapper } from "../../../../ui/details/DetailsComponents";
import ChartDot from "./ChartDot/ChartDot";
import ChartLegend from "./ChartLegend/ChartLegend";
import {
    lineChartStyle,
    tooltipItemStyle,
    tooltipLabelStyle,
    tooltipViewStyle,
} from "./chartStyles";
import ChartTick from "./ChartTick/ChartTick";
import { LimitsPeriodicStatisticTypes } from "../../../../../types/limitsStatisticTypes";

export interface LineConfig {
    name: string;
    dataKey: string;
    stroke: string;
}

interface ChartProps {
    data: PeriodicStatisticTypes[] | MerchantChartEntryTypes[] | LimitsPeriodicStatisticTypes[];
    lines: LineConfig[];
    isDotWithAction?: boolean;
    LegendComponent?: ReactElement;
    TooltipComponent?: FC<TooltipProps<string, any>>;
}

const Chart = ({
    data,
    lines,
    isDotWithAction = false,
    TooltipComponent,
    LegendComponent,
}: ChartProps) => (
    <DetailsSectionWrapper>
        <ResponsiveContainer height={300} width="100%">
            <LineChart data={data} margin={lineChartStyle}>
                <CartesianGrid strokeOpacity={0.3} />

                <YAxis axisLine={false} />
                <XAxis
                    interval={0}
                    dataKey="date"
                    axisLine={false}
                    tick={<ChartTick />}
                />

                <Tooltip
                    includeHidden
                    contentStyle={tooltipViewStyle}
                    labelStyle={tooltipLabelStyle}
                    itemStyle={tooltipItemStyle}
                    content={TooltipComponent}
                />

                <Legend
                    align="left"
                    verticalAlign="top"
                    content={LegendComponent || ChartLegend}
                />

                {lines.map((line) => (
                    <Line
                        {...line}
                        dot={isDotWithAction && <ChartDot />}
                        strokeWidth={2}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    </DetailsSectionWrapper>
);

export default Chart;
