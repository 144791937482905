import authStore from "../../../store/auth/authStore";
import { Hyperlink, Text } from "./InputStyles";

interface ProtectedHyperLinkType {
    navigateTo: string;
    linkName: string;
    target?: "_blank" | "_parent" | "_top" | "_self";
    permission?: string;
}

const ProtectedHyperLink = ({
    navigateTo,
    linkName,
    target = "_self",
    permission = "",
}: ProtectedHyperLinkType) => {
    const { userPermissions } = authStore;
    const hasPermission = userPermissions.includes(permission);

    return (
        hasPermission ? (
            <Hyperlink to={navigateTo} target={target}>
                {linkName}
            </Hyperlink>
        ) : (
            <Text>{linkName}</Text>
        )
    );
};

export default ProtectedHyperLink;
