import * as Yup from "yup";

import {
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_EMAIL_LENGTH,
    MAX_FIELD_NAME_LENGTH,
    MAX_TRIGGER_FILED_LENGTH,
} from "../../../../constants/validationRules";
import { TranslationsTypes } from "../../../../hooks/useTranslation";
import { EditAlertTypes } from "../../../../types/alertsTypes";
import { AlertTypeValue } from "../alerts/new/NewAlert";

const fieldsList: Array<keyof EditAlertTypes> = [
    "name",
    "importance",
    "display_attributes",
    "trigger_text",
    "services",
    "email",
    "alert_type",
    "partners",
    "tg_group",
    "processing",
    "description",
    "is_active",
];

const validationSchema = (t: TranslationsTypes, translate: (text: string) => string) => (
    Yup.object().shape({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),

        trigger_text: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_TRIGGER_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_TRIGGER_FILED_LENGTH}`),
            ),

        importance: Yup.string().required(t.validation.required),
        alert_type: Yup.string().required(t.validation.required),

        email: Yup.string()
            .when("alert_type", ([alert_type], schema) => ((
                alert_type === AlertTypeValue.EMAIL
            )
                ? schema.email(t.validation.invalidEmail)
                    .required(t.validation.required)
                    .max(
                        MAX_EMAIL_LENGTH,
                        translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`),
                    )
                : schema.nullable())),

        tg_group: Yup.string()
            .when("alert_type", ([alert_type], schema) => ((alert_type === AlertTypeValue.TG)
                ? schema.required(t.validation.required)
                : schema.nullable())),

        display_attributes: Yup.array().required(t.validation.required),

        partners: Yup.array().when(["services", "processing"], ([services, processing], schema) => (
            ((services && services.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        services: Yup.array().when(["partners", "processing"], ([partners, processing], schema) => (
            ((partners && partners.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        processing: Yup.string().when(["partners", "services"], ([partners, services], schema) => (
            ((partners && partners.length) || (services && services.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]])
);

const getFieldStates = (formikValues: EditAlertTypes, alertsLoading: boolean) => {
    const {
        services,
        partners,
        processing,
    } = formikValues;

    return {
        isPartnersFieldRequired: !services?.length || !processing,
        isServicesFieldRequired: !partners?.length || !processing,
        isProcessingFieldRequired: !services?.length || !partners?.length,

        isPartnersFieldDisabled: alertsLoading || !!services?.length || !!processing,
        isServicesFieldDisabled: alertsLoading || !!partners?.length || !!processing,
        isProcessingFieldDisabled: alertsLoading || !!partners?.length || !!services?.length,
    };
};

export {
    fieldsList,
    validationSchema,
    getFieldStates,
};
