const ALL_OPTION = "all";

enum FieldMode {
    Multiple = "multiple",
    Tags = "tags",
}

enum FieldModeOption {
    MultiSelect = "multi-select",
    Select = "select",
}

export {
    ALL_OPTION,
    FieldMode,
    FieldModeOption,
};
