/* eslint-disable camelcase */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent } from "react";
import { useLocation } from "react-router-dom";

import {
    alertImportanceOptionsHandbook,
    alertTypeOptionsHandbook,
    attributesOptionsHandbook,
    getTranslatedHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import filtersAlertsTgGroupsStore from "../../../../../store/filters/alerts/alertsTgGroupsFiltersStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { NewAlertTypes } from "../../../../../types/alertsTypes";
import { LocationState } from "../../../../../types/locationTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    Container,
    DoubleCellFieldWrapper,
    Row,
    SubmitButtonWrapper,
    ThreeColumnForm,
    Title,
} from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import {
    fieldsList,
    getFieldStates,
    getInitialValues,
    validationSchema,
} from "../../helpers/newAlertFormikHelpers";

export enum AlertTypeValue {
    AF = "af",
    TG = "tg",
    EMAIL = "email",
}

const NewAlert = () => {
    const { t, translate } = useTranslation();

    const { navigateBackward } = useNavigation();
    const state: LocationState = useLocation()?.state;
    const tgGroupId = state?.id;

    const handleNavigateToPrevPage = () => navigateBackward();

    const fieldsOptionsMap = {
        types: getTranslatedHandbook(translate, alertTypeOptionsHandbook),
        importance: getTranslatedHandbook(translate, alertImportanceOptionsHandbook),
        displayAttributes: getTranslatedHandbook(translate, attributesOptionsHandbook),
    };

    const formik = useFormik<NewAlertTypes>({
        enableReinitialize: true,
        initialValues: getInitialValues(Number(tgGroupId)),
        validationSchema: validationSchema(t, translate),
        onSubmit: async (fields) => {
            const partners = fields.partners || [];
            const services = fields.services || [];

            const fieldsData = { ...fields, partners, services };

            await alertsStore.postData(fieldsData, fieldsList);

            if (alertsStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                alertsStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.alerts.snackbar.addSuccess });
            } else if (alertsStore.postErrors?.common) {
                snackbarStore.add({ variant: "error", text: t.snackbar.addError });
            }
        },
    });

    const isTgAlertType = formik.values.alert_type === AlertTypeValue.TG;
    const isEmailAlertType = formik.values.alert_type as AlertTypeValue === AlertTypeValue.EMAIL;

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const fieldStates = getFieldStates(formik.values, alertsStore.loading);

    const toggleAlertStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const clearTgGroupOrEmailFieldsValues = () => {
        if (!isTgAlertType && formik.values.tg_group) formik.setFieldValue("tg_group", null);
        if (!isEmailAlertType && formik.values.email) formik.setFieldValue("email", null);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, alertsStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersPartnersStore.fetch(signal);
        filtersServicesStore.fetch(signal);
        filtersAlertsTgGroupsStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
    }, [], alertsStore.resetPostErrors);

    return (
        <>
            <SecondHeader title={t.alerts.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.alerts.text.title}</Title>
                    <Row>
                        <Title>{t.alerts.text.status}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={alertsStore.loading}
                            checked={formik.values.is_active}
                            onChange={toggleAlertStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="name"
                        isRequired
                        placeholder={t.alerts.placeholders.name}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="display_attributes"
                        options={fieldsOptionsMap.displayAttributes}
                        isRequired
                        mode={FieldMode.Multiple}
                        placeholder={t.alerts.placeholders.displayAttributes}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="alert_type"
                        isRequired
                        disabled={!!tgGroupId || alertsStore.loading}
                        options={fieldsOptionsMap.types}
                        placeholder={t.alerts.placeholders.alertType}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                        onChange={clearTgGroupOrEmailFieldsValues}
                    />
                    {isEmailAlertType && (
                        <FloatInput
                            formik={formik}
                            disabled={alertsStore.loading}
                            name="email"
                            isRequired
                            placeholder={t.alerts.placeholders.notificationsEmail}
                            serverErrors={alertsStore.postErrors}
                            resetServerError={alertsStore.resetPostError}
                        />
                    )}
                    {isTgAlertType && (
                        <FloatSelect
                            formik={formik}
                            name="tg_group"
                            isRequired
                            disabled={!!tgGroupId || alertsStore.loading}
                            options={filtersAlertsTgGroupsStore.options}
                            placeholder={t.alerts.placeholders.tgGroup}
                            serverErrors={alertsStore.postErrors}
                            resetServerError={alertsStore.resetPostError}
                        />
                    )}
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="trigger_text"
                        isRequired
                        placeholder={t.alerts.placeholders.triggerText}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="importance"
                        options={fieldsOptionsMap.importance}
                        isRequired
                        placeholder={t.alerts.placeholders.importance}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="partners"
                        mode={FieldMode.Multiple}
                        isRequired={fieldStates.isPartnersFieldRequired}
                        disabled={fieldStates.isPartnersFieldDisabled}
                        options={filtersPartnersStore.options}
                        placeholder={t.alerts.placeholders.partner}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="services"
                        mode={FieldMode.Multiple}
                        isRequired={fieldStates.isServicesFieldRequired}
                        disabled={fieldStates.isServicesFieldDisabled}
                        options={filtersServicesStore.options}
                        placeholder={t.alerts.placeholders.service}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="processing"
                        isRequired={fieldStates.isProcessingFieldRequired}
                        disabled={fieldStates.isProcessingFieldDisabled}
                        options={filtersProcessingStore.options}
                        placeholder={t.alerts.placeholders.processing}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />

                    {isTgAlertType || isEmailAlertType ? (
                        <DoubleCellFieldWrapper>
                            <FloatInput
                                formik={formik}
                                disabled={alertsStore.loading}
                                name="description"
                                placeholder={t.alerts.placeholders.message}
                                serverErrors={alertsStore.postErrors}
                                resetServerError={alertsStore.resetPostError}
                            />
                        </DoubleCellFieldWrapper>
                    ) : (
                        <FloatInput
                            formik={formik}
                            disabled={alertsStore.loading}
                            name="description"
                            placeholder={t.alerts.placeholders.message}
                            serverErrors={alertsStore.postErrors}
                            resetServerError={alertsStore.resetPostError}
                        />
                    )}

                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={alertsStore.loading}
                            disabled={alertsStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>

            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewAlert);
