import request from "../request";
import { RulesPacksTypes } from "../../types/rulesPacksTypes";

interface ResponseType {
    message: string,
    response: RulesPacksTypes,
}

const getSelectedRulesPack = (ruleId: string, signal?: AbortSignal) => request<ResponseType>({
    method: "GET",
    uri: `rules-packs/${ruleId}/`,
    signal,
});

export default getSelectedRulesPack;
