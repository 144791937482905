import { NotificationsFiltersTypes, NotificationsTypes } from "../../types/notificationsTypes";
import request from "../request";

type GetNotificationsRequest = NotificationsFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetNotificationsResponse {
    message: string,
    response: {
        count: number
        results: NotificationsTypes[];
    }
}

export const getNotifications = (
    params?: GetNotificationsRequest,
    signal?: AbortSignal,
) => request<GetNotificationsResponse>({
    method: "GET",
    uri: "notifications/",
    params,
    signal,
});
