import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect } from "react";
import * as Yup from "yup";

import { FieldMode } from "../../../../constants/selectFields";
import useTranslation from "../../../../hooks/useTranslation";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import rulesPackPartnersStore from "../../../../store/rulesPack/rulesPackPartnersStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import Button from "../../../ui/button/Button";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { AddButtonWrapper, NewItemFormWrapper } from "./NewRulesPackComponents";

const PARTNERS_FIELD_NAME = "partners";

interface FormTypes {
    partners: number[] | null,
}

interface AddRulesPacksPartnersModalProps {
    ruleId?: string;
    closeModal: () => void;
}

const AddRulesPacksPartnersModal = ({ ruleId, closeModal }: AddRulesPacksPartnersModalProps) => {
    const { t } = useTranslation();

    const formik = useFormik<FormTypes>({
        initialValues: { partners: null },
        enableReinitialize: true,
        validationSchema: Yup.object({
            partners: Yup.array().required(t.validation.required),
        }),
        onSubmit: async (values) => {
            const data = { ...values, rules_pack: Number(ruleId) };

            await rulesPackPartnersStore.postData(data, [PARTNERS_FIELD_NAME]);

            if (rulesPackPartnersStore.isPostRequestSuccess) {
                closeModal();
            } else {
                snackbarStore.add({ variant: "error", text: rulesPackPartnersStore.postErrors?.common });
                rulesPackPartnersStore.resetChangeStatusState();
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    useEffect(() => {
        filtersPartnersStore.fetchById(Number(ruleId));
    }, []);

    return (
        <NewItemFormWrapper onSubmit={handleSubmit} $gap="24">
            <FloatSelect
                formik={formik}
                disabled={rulesPackPartnersStore.loading}
                name={PARTNERS_FIELD_NAME}
                mode={FieldMode.Multiple}
                isSelectAllOption
                options={filtersPartnersStore.options}
                placeholder={t.filters.fields.partner}
                serverErrors={rulesPackPartnersStore.postErrors}
                resetServerError={rulesPackPartnersStore.resetPostError}
            />

            <AddButtonWrapper>
                <Button
                    type="submit"
                    variant="primary"
                    isLoading={rulesPackPartnersStore.loading}
                >
                    {t.buttons.add}
                </Button>
            </AddButtonWrapper>
        </NewItemFormWrapper>
    );
};

export default observer(AddRulesPacksPartnersModal);
