import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../constants/permissions";
import { generateHeaderName } from "../../../../helpers/secondHeaderHelper";
import { useNavigation } from "../../../../hooks/useNavigation";
import useTranslation from "../../../../hooks/useTranslation";
import rulesPackStore from "../../../../store/rulesPack/rulesPackStore";
import DetailsHeader from "../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../secondHeader/SecondHeader";
import SmallButton from "../../../ui/button/SmallButton";
import { DetailsPageWrapper, DetailsSectionWrapper } from "../../../ui/details/DetailsComponents";
import PreInput from "../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../ui/textInput/TextareaLabelInRow";
import ProtectedRulesDetailsTable from "./components/ProtectedRulesDetailsTable/ProtectedRulesDetailsTable";
import EditRuleModal from "./EditRuleModal";
import { FormWrapper, TextareaRow } from "./RulesDetailsComponents";

const DETAILS_TABLE_PERMISSIONS_LIST = [
    permissionsMap.rules_pack_partner.view,
    permissionsMap.rules_pack_services.view,
    permissionsMap.lists.view,
    permissionsMap.limits.view,
];

const RuleDetails = () => {
    const { t } = useTranslation();
    const { id: ruleId } = useParams();
    const { navigateBackward } = useNavigation();

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTabTitle = ruleId ? `${t.rulesPacks.details.rule} №${ruleId}` : "";
    const headerTitle = generateHeaderName(t.rulesPacks.title, currentTabTitle);

    const preInputParamsList = [
        {
            key: "rule-details-name",
            label: t.table.header.ruleName,
            value: rulesPackStore.selectedItem?.name || "",
        },
        {
            key: "rule-details-id",
            label: t.table.header.ruleId,
            value: rulesPackStore.selectedItem?.id.toString() || "",
        },
        {
            key: "rule-details-labels",
            label: t.table.header.labels,
            value: rulesPackStore.selectedItem?.tags.join(", "),
        },
        {
            key: "rule-details-processing-name",
            label: t.table.header.processingName,
            value: rulesPackStore.selectedItem?.processing_name || "",
        },
    ];

    const handleNavigate = () => navigateBackward();

    const actionHeaderContentList = [
        {
            key: "rule-details-back-button",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (ruleId) rulesPackStore.fetchSelectedItem(ruleId);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        permission={permissionsMap.rules_packs.change}
                        title={`${t.rulesPacks.details.rule} ${t.details.title}`}
                        isStatusActive={rulesPackStore.selectedItem?.is_active}
                        onEdit={toggleEditModal}
                    />

                    <FormWrapper>
                        {preInputParamsList.map((input) => <PreInput {...input} />)}

                        <TextareaRow>
                            <TextareaLabelInRow
                                label={t.table.header.description}
                                value={rulesPackStore.selectedItem?.description || ""}
                            />
                        </TextareaRow>
                    </FormWrapper>
                </DetailsSectionWrapper>

                <ProtectedRulesDetailsTable permissions={DETAILS_TABLE_PERMISSIONS_LIST} />

                <EditRuleModal
                    isOpen={isEditModalVisible}
                    title={t.rulesPacks.details.modal.title}
                    buttonText={t.buttons.save}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(RuleDetails);
