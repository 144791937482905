import request from "../request";

export interface GetFileResponseType {
    message: string;
    data: Blob;
}

export const getTransactionsFile = (fileId: string, signal?: AbortSignal) => request<GetFileResponseType>({
    method: "GET",
    uri: `transactions/files/download/${fileId}/`,
    responseType: "blob",
    signal,
});
