import { observer } from "mobx-react-lite";
import { memo } from "react";

import routes from "../../../../constants/routes";
import notificationsStore from "../../../../store/alerts/notifications/notificationsStore";
import { NotificationsTypes } from "../../../../types/notificationsTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import TableFieldsCheckStatusBadge from "../../../ui/tableFields/checkBadge/TableFieldsCheckStatusBadge";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsImportanceStatus from "../components/TableFieldImportanceStatus/TableFieldsImportanceStatus";
import { ColumnType } from "../../../ui/table/types";
import withPermission from "../../../../hocs/withPermissions";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";
import useFetchWithAbort from "../../../../hooks/useFetchWithAbort";

interface ProtectedNotificationsTableProps {
    permission: string;
}

const columns: ColumnType<NotificationsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.message",
        field: "message",
        isResizable: true,
        width: "328px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.notifications.href}/${dataItem.id}`}>
                {dataItem.message}
            </TableMainLink>
        ),
    },
    {
        header: "table.header.alertName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.alert.name,
    },
    {
        header: "table.header.notificationAlertId",
        field: "id",
        getField: (dataItem) => dataItem.alert.id,
    },
    { header: "table.header.trigger", field: "trigger", getField: (dataItem) => dataItem.alert.trigger },
    { header: "table.header.isActive", field: "is_checked", Component: TableFieldsCheckStatusBadge },
    { header: "table.header.takenBy", field: "taken_by" },
    {
        header: "table.header.customerId",
        field: "customer_id",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.partnerId", field: "partner" },
    { header: "table.header.serviceId", field: "service" },
    {
        header: "table.header.importance",
        field: "importance",
        getField: (dataItem) => (<TableFieldsImportanceStatus status={dataItem.alert.importance} />),
    },
    { header: "table.header.createdAt", field: "created", sortable: true },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.notifications.href} />
        ),
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...notificationsStore.pagination,
        setPaginationPage: notificationsStore.setPaginationPage,
        setPaginationSize: notificationsStore.setPaginationSize,
    };

    useFetchWithAbort((signal: AbortSignal) => {
        notificationsStore.fetch(signal);
    }, [notificationsStore.pagination, notificationsStore.filters, notificationsStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={notificationsStore.data}
            pagination={tablePagination}
            checkedItems={notificationsStore.checkedItems}
            sortMode={notificationsStore.ordering}
            loading={notificationsStore.loading}
            onSort={notificationsStore.toggleSort}
            onCheckboxChange={notificationsStore.toggleItem}
            onMainCheckboxChange={notificationsStore.toggleMainItem}
        />
    );
});

const ProtectedNotificationsTable = ({ permission }: ProtectedNotificationsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedNotificationsTable);
