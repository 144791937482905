import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent } from "react";
import * as Yup from "yup";

import {
    MAX_CHAT_ID_LENGTH,
    MAX_CHAT_LINK_LENGTH,
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_FIELD_NAME_LENGTH,
    MAX_THREAD_ID_LENGTH,
} from "../../../../../constants/validationRules";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import tgGroupsStore from "../../../../../store/alerts/tgGroups/tgGroupsStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import accountStore from "../../../../../store/user/account/accountStore";
import { NewTgGroupTypes } from "../../../../../types/tgGroupsTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    Container, Row, SubmitButtonWrapper, ThreeColumnForm, Title,
} from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";

const fieldsList: Array<keyof NewTgGroupTypes> = [
    "name", "chat_link", "chat_id", "thread_id", "processing", "description", "is_active",
];

const NewTgGroup = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const handleNavigateToPrevPage = () => navigateBackward();

    const userProcessingId = accountStore.userProfile?.processing || null;

    const initialValues: NewTgGroupTypes = {
        name: "",
        chat_link: "",
        chat_id: "",
        thread_id: "",
        processing: userProcessingId,
        description: "",
        is_active: true,
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),
        chat_link: Yup.string()
            .required(t.validation.required)
            .url(t.validation.url)
            .max(
                MAX_CHAT_LINK_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_CHAT_LINK_LENGTH}`),
            ),
        chat_id: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_CHAT_ID_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_CHAT_ID_LENGTH}`),
            ),
        thread_id: Yup.string()
            .max(
                MAX_THREAD_ID_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_THREAD_ID_LENGTH}`),
            ),
        processing: Yup.string().required(t.validation.required),
        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    });

    const formik = useFormik<NewTgGroupTypes>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            await tgGroupsStore.postData(fields, fieldsList);

            if (tgGroupsStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                tgGroupsStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.tgGroups.snackbar.addSuccess });
            } else {
                snackbarStore.add({ variant: "error", text: t.tgGroups.snackbar.addError });
            }
        },
    });

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const inputsParamsList = [
        {
            key: "new-tg-group-name",
            name: "name",
            isRequired: true,
            disabled: tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.name,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
        {
            key: "new-tg-group-chat-link",
            name: "chat_link",
            isRequired: true,
            disabled: tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.chatLink,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
        {
            key: "new-tg-group-chat-id",
            name: "chat_id",
            isRequired: true,
            disabled: tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.chatId,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
        {
            key: "new-tg-group-thread-id",
            name: "thread_id",
            disabled: tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.threadId,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
        {
            key: "new-tg-group-processing",
            name: "processing",
            isRequired: true,
            disabled: !!userProcessingId || tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.processing,
            options: filtersProcessingStore.options,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
        {
            key: "new-tg-group-description",
            name: "description",
            isRequired: false,
            disabled: tgGroupsStore.loading,
            placeholder: t.tgGroups.placeholders.description,
            serverErrors: tgGroupsStore.postErrors,
            resetServerError: tgGroupsStore.resetPostError,
        },
    ];

    const toggleTgGroupStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, tgGroupsStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersProcessingStore.fetch(signal);
    }, [], tgGroupsStore.resetPostErrors);

    return (
        <>
            <SecondHeader title={t.tgGroups.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.tgGroups.text.title}</Title>
                    <Row>
                        <Title>{t.status.title}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={tgGroupsStore.loading}
                            checked={formik.values.is_active}
                            onChange={toggleTgGroupStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    {inputsParamsList.map((input) => (
                        input.options
                            ? (<FloatSelect formik={formik} {...input} />)
                            : (<FloatInput formik={formik} {...input} />)
                    ))}

                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={tgGroupsStore.loading}
                            disabled={tgGroupsStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewTgGroup);
