import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getTranslatedHandbook, groupOptionsHandbook } from "../../../../constants/handbooks";
import { permissionsMap } from "../../../../constants/permissions";
import { generateHeaderName } from "../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../hooks/useTranslation";
import filtersListsElementsStore from "../../../../store/filters/listsElementsFiltersStore";
import listStore from "../../../../store/list/listStore";
import DetailsHeader from "../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../secondHeader/SecondHeader";
import { DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper } from "../../../ui/details/DetailsComponents";
import PreInput from "../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../ui/textInput/TextareaLabelInRow";
import EditListModal from "./EditListModal";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedElementsTable from "./tabsTable/ProtectedElementsTable";

const ListDetails = () => {
    const { t, translate } = useTranslation();
    const { id: listId } = useParams();
    const { selectedItem } = listStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = listId ? `${t.lists.text.partOfLink} №${listId}` : "";
    const headerTitle = generateHeaderName(t.lists.title, currentTitle);
    const listType = selectedItem?.type;
    const groupOption = getTranslatedHandbook(translate, groupOptionsHandbook)
        .find((option) => option.value === selectedItem?.is_global)?.label;

    const preInputsList = [
        { label: t.table.header.listName, value: selectedItem?.name, key: "list-details-name" },
        { label: t.table.header.category, value: selectedItem?.category?.toString(), key: "list-details-category" },
        { label: t.table.header.alert, value: selectedItem?.alert_name, key: "list-details-alert-name" },
        { label: t.table.header.type, value: selectedItem?.type?.toString(), key: "list-details-type" },
        { label: t.table.header.listId, value: selectedItem?.id?.toString(), key: "list-details-id" },
        { label: t.table.header.isGlobal, value: groupOption, key: "list-details-option" },
        {
            label: t.table.header.rulesPack,
            value: selectedItem?.rules_pack?.toString(),
            isHidden: selectedItem?.is_global || selectedItem?.category === "Alert Exception",
            key: "list-details-rules-pack",
        },
        {
            label: t.table.header.processing,
            value: selectedItem?.processings?.map((processing) => processing?.name).join(", "),
            isHidden: !selectedItem?.is_global,
            key: "list-details-processings",
        },
        { label: t.table.header.createdAt, value: selectedItem?.created, key: "list-details-created-at" },
        { label: t.table.header.lastUpdate, value: selectedItem?.last_update, key: "list-details-last-update" },
        { label: t.table.header.createdBy, value: selectedItem?.created_by_name, key: "list-details-created-by" },
    ];

    const {
        tabActionsList,
        actionHeaderContentList,
        ActionModalComponent,
        CloneListModalComponent,
        FilterModalComponent,
        UploadModalComponent,
    } = useTabsActions(listId, listType);

    useEffect(() => {
        if (listId) {
            listStore.fetchSelectedItem(listId);
            filtersListsElementsStore.fetchById(Number(listId));
        }
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.lists.text.partOfLink} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.lists.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsList.map(({ isHidden, ...input }) => (isHidden ? null : <PreInput {...input} />))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.table.header.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <ProtectedElementsTable
                    permission={permissionsMap.elements.view}
                    actionsContent={tabActionsList}
                />
            </DetailsPageWrapper>

            <EditListModal
                isOpen={isEditModalVisible}
                title={t.lists.edit.title}
                buttonText={t.buttons.save}
                setOn={toggleEditModal}
            />

            {ActionModalComponent}

            {UploadModalComponent}

            {FilterModalComponent}

            {CloneListModalComponent}
        </>

    );
};

export default observer(ListDetails);
