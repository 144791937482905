import request from "../../request";

interface GetAccountManagersResponse {
    message: string,
    response: string[],
}

export const getServicesAccountManagers = (signal?: AbortSignal) => request<GetAccountManagersResponse>({
    method: "GET",
    uri: "entities/services/filters/account-managers/",
    signal,
});
