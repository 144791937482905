import { RequestParamsType } from "../../../store/common/filtersStore";
import { ServicesTypes } from "../../../types/servicesTypes";
import request from "../../request";

export interface GetServicesResponse {
    message: string,
    response: {
        count: number,
        results: ServicesTypes[],
    }
}

export const getServices = (params?: RequestParamsType, signal?: AbortSignal) => request<GetServicesResponse>({
    method: "GET",
    uri: "entities/filters/services/",
    params,
    signal,
});
