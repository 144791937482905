import { ItemType } from "antd/es/menu/hooks/useItems";
import transactionStore from "../../../../../store/transaction/transactionStore";
import { permissionsMap } from "../../../../../constants/permissions";
import MenuDropdownItem from "../../../../ui/menuDropdown/MenuDropdownItem";
import { ReactComponent as DownloadIcon } from "../../../../../resources/icons/menu/download.svg";
import { ReactComponent as PropertiesIcon } from "../../../../../resources/icons/menu/settings.svg";
import { colors } from "../../../../../styles/variables";

const getTransactionsMenuItems = (
    userPermissions: string[],
    translate: (text: string) => string,
    downloadFile: () => Promise<void>,
    toggleParametersModal: () => void,
): ItemType[] => {
    const itemsPropsList = [
        {
            key: "download-item",
            disabled: !transactionStore.data.length || transactionStore.isFileLoading,
            permission: permissionsMap.transactions.download,
            label: <MenuDropdownItem
                isLoading={transactionStore.isFileLoading}
                icon={<DownloadIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={translate("transactions.buttons.download")}
            />,
            onClick: downloadFile,
        },
        {
            key: "properties-item",
            isItemShown: true,
            label: <MenuDropdownItem
                icon={<PropertiesIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={translate("transactions.buttons.parameters")}
            />,
            onClick: toggleParametersModal,
        },
    ];

    return itemsPropsList.filter(({ permission, isItemShown }) => (
        userPermissions.includes(permission ?? "") || isItemShown));
};

export {
    getTransactionsMenuItems,
};
