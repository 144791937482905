import { observer } from "mobx-react-lite";
import useTranslation from "../../../../hooks/useTranslation";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import {
    Box, IconContainer, Row, Title as ModalTitle,
} from "../ModalComponents";
import Button from "../../../ui/button/Button";
import { ActionModalState, ActionModalType, ActionModalVariantProps } from "../../../../types/actionModalTypes";
import { FetchStatusesType } from "../../../../types/fetchStatuses";
import { ReactComponent as BinIcon } from "../../../../resources/icons/bin.svg";
import { ReactComponent as CloseCircleIcon } from "../../../../resources/icons/closeCircleIcon.svg";
import { ReactComponent as TickCircleIcon } from "../../../../resources/icons/tickCircleIcon.svg";
import snackbarStore from "../../../../store/snackbar/snackbarStore";

interface ActionModalProps extends ModalProps {
    entityName: string | null;
    modalState: ActionModalState;
    toggleModal: (type?: ActionModalType) => void;
    unlinkEntityName?: string;
    customBodyText?: string;
    customTitleText?: string | null;
    store?: any;
    customFetchMethod?: () => void;
    handleDeactivate?: () => void;
    handleActivate?: () => void;
    handleDelete?: () => void;
    handleUnlink?: () => void;
}

const ActionModal = ({
    entityName = "",
    modalState,
    toggleModal,
    unlinkEntityName,
    customBodyText,
    customTitleText = "",
    store,
    customFetchMethod,
    handleDeactivate,
    handleActivate,
    handleDelete,
    handleUnlink,
    ...props
}: Omit<ActionModalProps, "isOpen" | "setOn" | "title">) => {
    const { t, concatenate } = useTranslation();

    const toggleStatusModal = () => toggleModal();

    const changeItemsStatus = async (action: "activate" | "deactivate") => {
        if (store) {
            const isActivateAction = action === "activate";
            let result: FetchStatusesType | null = null;
            if (isActivateAction) {
                result = await store.activateSelectedItems();
            } else {
                result = await store.deactivateSelectedItems();
            }
            toggleStatusModal();
            if (result === FetchStatusesType.success) {
                const itemsIdsList = isActivateAction ? store.activatedItems : store.deactivatedItems;
                const snackbarSuccessText = concatenate(
                    t.snackbar[action].success,
                    { itemsIdsList },
                );
                snackbarStore.add({ variant: "success", text: snackbarSuccessText });
                if (customFetchMethod) {
                    customFetchMethod();
                } else if (store.fetchDataMethod) {
                    if (store.fetchData) {
                        store.fetchData();
                    } else if (store.fetch) {
                        store.fetch();
                    }
                }
            } else {
                const changeStatusError = store.changeStatusErrors;
                snackbarStore.add({ variant: "error", text: changeStatusError?.name });
                store.resetChangeStatusErrors();
            }
        }
    };

    const deactivateItems = () => changeItemsStatus("deactivate");

    const activateItems = () => changeItemsStatus("activate");

    const modalProps: ActionModalVariantProps = {
        activate: {
            icon: <TickCircleIcon width="32px" height="32px" />,
            variant: "primary",
            action: handleActivate || activateItems,
            title: customTitleText || `${t.modal.modalTitle.activate} ${entityName?.toLowerCase()}`,
            buttonText: t.buttons.activate,
            bodyText: customBodyText || t.modal.modalBody.activate,
        },
        deactivate: {
            icon: <CloseCircleIcon />,
            variant: "error",
            action: handleDeactivate || deactivateItems,
            title: customTitleText || `${t.modal.modalTitle.deactivate} ${entityName?.toLowerCase()}`,
            buttonText: t.buttons.deactivate,
            bodyText: customBodyText || t.modal.modalBody.deactivate,
        },
        delete: {
            icon: <BinIcon width="32px" height="32px" />,
            variant: "error",
            action: handleDelete,
            title: customTitleText || `${t.modal.modalTitle.delete} ${entityName?.toLowerCase()}`,
            buttonText: t.buttons.delete,
            bodyText: customBodyText || t.modal.modalBody.delete,
        },
        unlink: {
            icon: <CloseCircleIcon />,
            variant: "error",
            action: handleUnlink,
            title: customTitleText
                || `${t.modal.modalTitle.unlink} ${unlinkEntityName} ${t.modal.modalTitle.from} ${entityName}`,
            buttonText: t.buttons.unlink,
            bodyText: customBodyText || t.modal.modalBody.unlink,
        },
    };

    const { type, isVisible } = modalState;
    const {
        icon, variant, action, title, buttonText, bodyText,
    } = modalProps[type];

    return (
        <Modal
            {...props}
            isOpen={isVisible}
            submitButton={false}
            setOn={toggleStatusModal}
            modalBodyText={bodyText}
            title={(
                <Box>
                    <IconContainer>{icon}</IconContainer>
                    <ModalTitle>{title}</ModalTitle>
                </Box>
            )}
        >
            <Row>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={toggleStatusModal}
                >
                    {t.buttons.cancel}
                </Button>
                <Button
                    variant={variant}
                    type="button"
                    onClick={action}
                    isLoading={store?.isStatusChanging}
                >
                    {buttonText}
                </Button>
            </Row>
        </Modal>
    );
};

export default observer(ActionModal);
