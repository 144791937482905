import request from "../../request";

interface GetAccountManagersResponse {
    message: string,
    response: string[],
}
export const getPartnersAccountManagers = (signal?: AbortSignal) => request<GetAccountManagersResponse>({
    method: "GET",
    uri: "entities/partners/filters/account-managers/",
    signal,
});
