import dayjs from "dayjs";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import {
    ChangeEvent, FormEvent, useRef, useState,
} from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { elementCardOptionsHandbook, getTranslatedHandbook } from "../../../../constants/handbooks";
import { RegExpressions } from "../../../../constants/regex";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { removeEmptyFields } from "../../../../helpers/removeEmptyFields";
import { useNavigation } from "../../../../hooks/useNavigation";
import { usePreventRedirect } from "../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../hooks/useTranslation";
import elementStore from "../../../../store/element/elementStore";
import filtersListsStore from "../../../../store/filters/lists/listsFiltersStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { NewElementType } from "../../../../types/elementsTypes";
import { ElementListTypes } from "../../../../types/listsTypes";
import { LocationState } from "../../../../types/locationTypes";
import SecondHeader from "../../../secondHeader/SecondHeader";
import Button from "../../../ui/button/Button";
import SmallButton from "../../../ui/button/SmallButton";
import PreDatePicker from "../../../ui/datepicker/PreDatePicker";
import ErrorLabel from "../../../ui/errors/ValidationErrorBox/ErrorLabel";
import Switch from "../../../ui/switch/Switch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import {
    Box, Container, DescriptionFieldWrapper, FormWrapper, Row, Title,
} from "./NewElementStyles";
import useFetchWithAbort from "../../../../hooks/useFetchWithAbort";

type ListTypes = Exclude<ElementListTypes, "Customer ID" | "Device ID">;

type ListTypeValidationTypes = {
    [K in ListTypes]: () => void;
};

const fieldsList = ["name"];

const currentDate = dayjs();

const NewElement = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const state: LocationState = useLocation()?.state;
    const listId = state?.id;
    const selectedListType = state?.selectedType;

    const listType = useRef<ElementListTypes | null>(null);
    const [isCardFieldDisabled, setCardFieldDisabled] = useState(true);
    const cardOptions = getTranslatedHandbook(translate, elementCardOptionsHandbook);

    const handleNavigateToPrevPage = () => navigateBackward();

    const validationSchema = Yup.object({
        list: Yup.string().required(t.validation.required),
        type: Yup.string(),
        need_hashing: Yup.boolean()
            .when(() => (isCardFieldDisabled
                ? Yup.boolean().notRequired()
                : Yup.boolean().required(t.validation.required))),
        time_on_the_list: Yup.string().nullable().test({
            message: t.validation.pastDate,
            test: (field) => dayjs(field).isAfter(currentDate),
        }),
        name: Yup.string()
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
    });

    const initialValues: NewElementType = {
        name: "",
        list: Number(listId) || null,
        is_active: true,
        need_hashing: null,
        description: "",
        time_on_the_list: "",
    };

    const formik = useFormik<NewElementType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active");
            filteredData.name = filteredData.name?.trim();

            await elementStore.postData(filteredData, fieldsList);

            if (elementStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                elementStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.elements.snackbar.addSuccess });
            }
        },
        validate: (values) => {
            const errors: Pick<NewElementType, "name"> = {};

            if (!values.name) {
                errors.name = t.validation.required;
            } else if (listType?.current) {
                const listTypeValidationMap: ListTypeValidationTypes = {
                    "IP": () => {
                        if (!(values.name?.match(RegExpressions.ipv4Address)
                            || values.name?.match(RegExpressions.ipv6Address))) {
                            errors.name = translate(`validation.listTypeError.${listType.current}`);
                        }
                    },
                    "Card": () => {
                        if (!values.need_hashing && !values.name?.match(RegExpressions.sha256hash)) {
                            errors.name = translate("validation.listTypeError.Hash");
                        } else if (values.need_hashing && !values.name?.match(RegExpressions.card)) {
                            errors.name = translate("validation.listTypeError.Card");
                        }
                    },
                    "E-mail": () => {
                        if (!values.name?.match(RegExpressions.emailWithSpaces)) {
                            errors.name = translate(`validation.listTypeError.${listType.current}`);
                        }
                    },
                    "Phone": () => {
                        if (!values.name?.match(RegExpressions.phoneWithSpaces)) {
                            errors.name = translate(`validation.listTypeError.${listType.current}`);
                        }
                    },
                };

                const validate = listTypeValidationMap[listType.current as ListTypes];
                validate?.();
            }

            return errors;
        },
    });

    const actionHeaderContentList = [
        {
            key: "cancel_button",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const toggleElementStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const handleListChange = (value: unknown) => {
        listType.current = filtersListsStore.options.find((list) => list.value === value)?.type || null;

        if (listType.current === "Card") {
            setCardFieldDisabled(false);
        } else {
            setCardFieldDisabled(true);
            formik.setFieldValue("need_hashing", null);
            formik.setFieldTouched("need_hashing", false);
        }
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, elementStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersListsStore.fetch(signal);

        if (listId && selectedListType === "Card") setCardFieldDisabled(false);
    }, [], elementStore.resetPostErrors);

    return (
        <>
            <SecondHeader title={t.elements.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.elements.text.info}</Title>
                    <Row>
                        <Title>{t.status.title}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={elementStore.loading}
                            checked={formik.values.is_active}
                            onChange={toggleElementStatus}
                        />
                    </Row>
                </Row>

                <FormWrapper onSubmit={handleSubmit}>
                    <Box>
                        <FloatInput
                            formik={formik}
                            disabled={elementStore.loading}
                            name="name"
                            isRequired
                            placeholder={t.placeholder.elementValue}
                            isCommonError={elementStore.postErrors?.common}
                            serverErrors={elementStore.postErrors}
                            resetServerError={elementStore.resetPostError}
                        />
                        {elementStore.postErrors?.common && ErrorLabel(elementStore.postErrors?.common)}
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            name="list"
                            onChange={handleListChange}
                            disabled={!!listId || elementStore.loading}
                            options={filtersListsStore.options}
                            resetServerError={elementStore.resetPostError}
                            isCommonError={elementStore.postErrors?.common}
                            isRequired
                            placeholder={t.placeholder.listIdName}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            disabled={isCardFieldDisabled || elementStore.loading}
                            name="need_hashing"
                            options={cardOptions}
                            isRequired={!isCardFieldDisabled}
                            placeholder={t.placeholder.cardFormat}
                        />
                    </Box>
                    <Box>
                        <PreDatePicker
                            isNowButtonShown={false}
                            disabled={elementStore.loading}
                            formik={formik}
                            name="time_on_the_list"
                            placeholder={t.placeholder.addForever}
                            addonBeforeText={t.placeholder.until}
                        />
                    </Box>
                    <DescriptionFieldWrapper>
                        <FloatTextArea
                            formik={formik}
                            disabled={elementStore.loading}
                            placeholder={t.placeholder.description}
                            name="description"
                            autoSize
                            maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        />
                    </DescriptionFieldWrapper>
                    <Box>
                        <Button
                            variant="primary"
                            type="submit"
                            isLoading={elementStore.loading}
                            disabled={elementStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </Box>
                </FormWrapper>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewElement);
