import request from "../request";

export interface GetFileStatusResponseType {
    message: string;
    response: {
        message: string;
        status: "SUCCESS" | "PENDING" | "ERROR"
    };
}

export const getListsElementsFileStatus = (fileId: string, signal?: AbortSignal) => request<GetFileStatusResponseType>({
    method: "GET",
    uri: `elements/files/status/${fileId}/`,
    signal,
});
