import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTabs from "../../../hooks/useTabs";
import useTranslation from "../../../hooks/useTranslation";
import createdByFilterStore from "../../../store/filters/createdByFilterStore";
import rolesFilterStore from "../../../store/filters/rolesFilterStore";
import employeeStore from "../../../store/user/employee/employeeStore";
import rolesStore from "../../../store/user/roles/roleStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import ProtectedEmployeeTable from "./employees/ProtectedEmployeesTable";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedRolesTable from "./roles/ProtectedRolesTable";
import { Tabs } from "../../../constants/tabs";
import { LocationState } from "../../../types/locationTypes";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";

const PagesEmployees = () => {
    const { t } = useTranslation();
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const employeesCount = employeeStore.pagination.count;
    const rolesCount = rolesStore.pagination.count;

    const tabsPropsList = [
        {
            title: t.employees.title,
            name: Tabs.EMPLOYEES,
            itemsCount: employeesCount,
            permission: permissionsMap.employees.view,
            store: employeeStore,
            content: <ProtectedEmployeeTable permission={permissionsMap.employees.view} />,
        },
        {
            title: t.roles.title,
            name: Tabs.ROLES,
            itemsCount: rolesCount,
            permission: permissionsMap.roles.view,
            store: rolesStore,
            content: <ProtectedRolesTable permission={permissionsMap.roles.view} />,
        },
    ];

    const {
        TabsLayout, activeTab, currentTab, currentTabTitle,
    } = useTabs(tabsPropsList, defaultActiveTab);

    const headerTitle = generateHeaderName(currentTabTitle);

    const {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    } = useTabsActions(currentTabTitle, currentTab);

    useFetchWithAbort((signal: AbortSignal) => {
        tabsPropsList.at(0)?.store.fetchData(signal);
        tabsPropsList.at(1)?.store.fetchData(signal);

        rolesFilterStore.fetch(signal);
        createdByFilterStore.fetch(signal);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} tabs={TabsLayout} actionsContent={actionHeaderContentList} />
            {activeTab}
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesEmployees);
