import request from "../request";
import { RolePermissionType } from "../../types/rolesTypes";

interface GetRolesPermissionsResponse {
    message: string,
    response: {
        results: RolePermissionType[];
    }
}

export const getRolesPermissions = (signal?: AbortSignal) => request<GetRolesPermissionsResponse>({
    method: "GET",
    uri: "roles/role-permissions/",
    signal,
});
