import {
    AfStatisticTypes,
    CoreStatisticTypes,
    PeriodicStatisticTypes,
    StatisticFiltersTypes,
} from "../../types/transactionsStatisticTypes";
import request from "../request";

interface GetStatisticResponse {
    message: string,
    response: {
        core: CoreStatisticTypes;
        af: AfStatisticTypes;
        periodic: PeriodicStatisticTypes[];
    }
}

const getTransactionsStatistic = (
    params: StatisticFiltersTypes,
    signal?: AbortSignal,
) => request<GetStatisticResponse>({
    method: "GET",
    uri: "home/statistic/",
    params,
    signal,
});

export default getTransactionsStatistic;
