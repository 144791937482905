import { Fragment, ReactNode } from "react";

import useTranslation from "../../hooks/useTranslation";
import {
    ActionsContainer,
    Container,
    SecondaryTitle,
    SecondaryTitleActive,
    Title,
    TitleContainer,
} from "./TabHeaderComponents";

export interface SecondHeaderProps {
    title: string;
    subtitle?: string;
    count?: { from: number, to: number };
    actionsContent?: { content: ReactNode, key: string }[];
}

const TabHeader = ({
    title,
    subtitle,
    actionsContent,
    count,
}: SecondHeaderProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <TitleContainer>
                <Title>{title}</Title>

                {subtitle && <SecondaryTitleActive>{` ${subtitle}`}</SecondaryTitleActive>}

                {count && (
                    <>
                        <SecondaryTitleActive>
                            {`${t.status.active} ${count.from}`}
                        </SecondaryTitleActive>
                        <SecondaryTitle>
                            {`/${count.to}`}
                        </SecondaryTitle>
                    </>
                )}
            </TitleContainer>

            <ActionsContainer>
                {actionsContent?.map((actionContent) => (
                    <Fragment key={actionContent.key}>
                        {actionContent.content}
                    </Fragment>
                ))}
            </ActionsContainer>

        </Container>
    );
};

export default TabHeader;
