import request from "../../request";
import { ListsTypes } from "../../../types/listsTypes";

interface GetListsResponse {
    message: string,
    response: {
        count: number
        results: ListsTypes[];
    }
}

export const getLists = (signal?: AbortSignal) => request<GetListsResponse>({
    method: "GET",
    uri: "entities/filters/lists/",
    signal,
});
