import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { objectToValuesList } from "../../../helpers";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import filtersCountriesStore from "../../../store/filters/countriesFiltersStore";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersProcessingServicesStore from "../../../store/filters/processingServicesFiltersStore";
import rulesPacksFiltersStore from "../../../store/filters/rulesPacksFiltersStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import transactionStore from "../../../store/transaction/transactionStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import TransactionsTable from "./TransactionsTable";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";

const PagesTransactions = () => {
    const { t } = useTranslation();

    const headerTitle = generateHeaderName(t.transactions.title);
    const filtersCount = objectToValuesList(transactionStore.filters as any || {}).length;

    const { FilterModal, ParametersModalComponent, actionHeaderContentList } = useTabsActions(filtersCount);

    // TODO a kostyl - to be cut after finishing user-friendly app major update feature
    const setUserIdFromToken = () => {
        if (!authStore.userId) {
            authStore.setUserId();
        }
    };

    useFetchWithAbort((signal: AbortSignal) => {
        filtersPartnersStore.fetch(signal);
        filtersServicesStore.fetch(signal);
        filtersProcessingServicesStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
        rulesPacksFiltersStore.fetch(signal);
        filtersCountriesStore.fetch(signal);
    }, []);

    // TODO a kostyl - to be cut after finishing user-friendly app major update feature
    useEffect(() => {
        setUserIdFromToken();
    }, []);

    useEffect(() => {
        transactionStore.resetSortWithRequiredFiltration(!!filtersCount);
    }, [filtersCount]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <TransactionsTable isFilterApplied={!!filtersCount} />
            {FilterModal}
            {ParametersModalComponent}
        </>
    );
};

export default observer(PagesTransactions);
