import { observer } from "mobx-react-lite";
import { memo } from "react";

import Routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import partnerStore from "../../../../store/entities/partner/partnerStore";
import { PartnerTypes } from "../../../../types/partnerTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";
import useFetchWithAbort from "../../../../hooks/useFetchWithAbort";

interface ProtectedPartnersTableProps {
    isCheckboxVisible: boolean;
    permission: string;
}

type WrappedComponentProps = Pick<ProtectedPartnersTableProps, "isCheckboxVisible">

const columns: ColumnType<PartnerTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.partnerName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${Routes.partners.href}/${dataItem.id}`}>{dataItem.name}</TableMainLink>
        ),
    },
    { header: "table.header.partnerId", field: "id", sortable: true },
    {
        header: "table.header.isActive",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.services",
        field: "services_count",
        sortable: true,
        cellCenter: true,
    },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "phone" },
    {
        header: "table.header.accountManager",
        field: "account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.timezone", field: "timezone" },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.processingId", field: "processing", sortable: true },
    {
        header: "table.header.processingName",
        field: "processing_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={Routes.partners.href}
            />
        ),
    },
];

const WrappedComponent = observer(({ isCheckboxVisible }: WrappedComponentProps) => {
    const tablePagination = {
        ...partnerStore.pagination,
        setPaginationPage: partnerStore.setPaginationPage,
        setPaginationSize: partnerStore.setPaginationSize,
    };

    useFetchWithAbort((signal: AbortSignal) => {
        partnerStore.fetch(signal);
    }, [partnerStore.pagination, partnerStore.filters, partnerStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={partnerStore.data}
            pagination={tablePagination}
            checkedItems={partnerStore.checkedItems}
            sortMode={partnerStore.ordering}
            loading={partnerStore.loading}
            onSort={partnerStore.toggleSort}
            onCheckboxChange={partnerStore.toggleItem}
            onMainCheckboxChange={partnerStore.toggleMainItem}
            isItemSelectionAllowed={isCheckboxVisible}
        />
    );
});

const ProtectedPartnersTable = ({ isCheckboxVisible, permission }: ProtectedPartnersTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isCheckboxVisible={isCheckboxVisible} />;
};

export default memo(ProtectedPartnersTable);
