import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import Routes from "../../../../constants/routes";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import rulesPackStore from "../../../../store/rulesPack/rulesPackStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import Link from "../../../ui/link/Link";
import RulesPacksMenu from "../components/RulesPacksMenu/RulesPacksMenu";
import RulesPacksMoveModal from "../components/RulesPacksMoveModal/RulesPacksMoveModal";
import RulesFilters from "../RulesPackFilters/RulesPackFilters";

export function useTabsActions(userPermissions: string[]) {
    const { t } = useTranslation();

    const filtersCount = objectToValuesList(rulesPackStore.filters || {}).length;

    const [isFiltersModalVisible, setFiltersModalVisibility] = useState(false);
    const toggleFiltersModal = () => setFiltersModalVisibility((prev) => !prev);

    const [isMoveModalVisible, setMoveModalVisibility] = useState(false);
    const toggleMoveModal = () => setMoveModalVisibility((prev) => !prev);

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const AddButtonComponent = (
        <Link to={`${Routes.rule.href}/new`}>
            <ProtectedSmallButton
                variant="tertiary"
                title={t.rulesPacks.buttons.addButton}
                permission={permissionsMap.rules_packs.add}
                iconLeft={<PlusIcon />}
            />
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFiltersModal}
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuDropdownComponent = (
        <RulesPacksMenu
            store={rulesPackStore}
            userPermissions={userPermissions}
            toggleActionModal={toggleActionModal}
            toggleMoveModal={toggleMoveModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal isOpen={isFiltersModalVisible} setOn={toggleFiltersModal}>
            <RulesFilters closeModal={toggleFiltersModal} />
        </FiltersModal>
    );

    const MoveModalComponent = (
        <RulesPacksMoveModal
            isOpen={isMoveModalVisible}
            setOn={setMoveModalVisibility}
        />
    );

    const ActionModalComponent = (
        <ActionModal
            store={rulesPackStore}
            entityName={t.rulesPacks.buttons.addButton}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuDropdownComponent },
    ];

    return {
        FilterModalComponent,
        MoveModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    };
}
