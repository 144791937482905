import request from "../request";
import { CountriesType } from "../../types/commonFiltersType";

type GetCountriesResponse = {
    message: string,
    response: CountriesType[]
};

export const getCountries = (signal?: AbortSignal) => request<GetCountriesResponse>({
    method: "GET",
    uri: "entities/filters/countries/",
    signal,
});
