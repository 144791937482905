import {
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";

import rulesPackStore from "../../../../../store/rulesPack/rulesPackStore";
import { RulesPacksLimitsType } from "../../../../../types/limitsTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import DraggableCell from "../../details/components/DraggableCell/DraggableCell";
import {
    tableContainerStyle,
} from "../../details/components/RulesPacksLimitsMoveTable/RulesPacksLimitsMoveTableComponents";

interface RulesPacksMoveTableTypes {
    data: RulesPacksLimitsType[]
    setData: (newLimits: RulesPacksLimitsType[]) => void
}

const columns: ColumnType<RulesPacksLimitsType>[] = [
    {
        header: "table.header.order",
        field: "order",
        cellStyles: { width: "0px", fontWeight: 600 },
        padding: "13px 8px 13px 16px",
    },
    {
        field: "move", cellStyles: { width: "0px" }, padding: "0px 8px", Component: DraggableCell,
    },
    {
        header: "table.header.label",
        field: "id",
        cellStyles: { width: "100%" },
        padding: "13px 16px",
    },
];

const RulesPacksMoveTable = ({ data, setData }: RulesPacksMoveTableTypes) => {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 200,
                tolerance: 6,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <Table
            columns={columns}
            data={data}
            setData={setData}
            sensors={sensors}
            loading={rulesPackStore.loading}
            tableContainerStyles={tableContainerStyle}
            scrollable
        />
    );
};

export default observer(RulesPacksMoveTable);
