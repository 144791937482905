import { copyTextToClipboard } from "../../../../../../helpers";

interface ChartDotProps {
    cx?: number;
    cy?: number;
    stroke?: string;
    payload?: Record<string, any>;
    value?: number;
}

const ChartDot = ({
    cx,
    cy,
    payload,
    value,
    stroke,
}: ChartDotProps) => {
    const peakItem = payload && Object.keys(payload).find((item) => item.toLocaleLowerCase().includes("at_peak"));

    const handleClick = () => {
        if (payload && peakItem?.length) copyTextToClipboard(payload[peakItem].join(", "));
    };

    if (cx && cy && value) {
        return (
            <svg
                x={cx - 4}
                y={cy - 4}
                width={8}
                height={8}
                viewBox="0 0 10 10"
                fill={stroke}
                style={{ cursor: "pointer" }}
                onClick={handleClick}
            >
                <rect x="0" y="0" width="10" height="10" rx="5" fill={stroke} stroke="white" />
            </svg>
        );
    }

    return null;
};

export default ChartDot;
