import getTags from "../../api/rulesPacks/filters/getTags";
import { TemplateType } from "../../types/commonFiltersType";
import FiltersDataStore from "../common/filtersStore";

class TagsFiltersStore extends FiltersDataStore<TemplateType> {
    protected fetchListMethod = getTags;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: item.name,
        }));
    }
}

const tagsFiltersStore = new TagsFiltersStore();

export default tagsFiltersStore;
