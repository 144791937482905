import { useState } from "react";

import { objectToValuesList } from "../../../../helpers";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import transactionsStore from "../../../../store/home/basic/transactionsStore";
import limitsStore from "../../../../store/home/limits/limitsStore";
import merchantStore from "../../../../store/home/merchant/merchantStore";
import { PermissionKeys } from "../../../../types/permissionsTypes";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon } from "../../../ui/button/ButtonComponents";
import SmallButton from "../../../ui/button/SmallButton";
import TransactionsChartsFilters from "../transactions/TransactionsChartsFilters/TransactionsChartsFilters";
import LimitsChartsFilters from "../limits/LimitsChartsFilters/LimitsChartsFilters";
import MerchantChartsFilters from "../merchant/MerchantChartsFilters/MerchantChartsFilters";

export type TabTitlesKeys = Extract<PermissionKeys, "transactions" | "limits" | "merchant_info">;

export function useTabsActions(currentTabTitle: PermissionKeys) {
    const { t } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);

    const modifiedCurrentTabTitle = currentTabTitle.split(" ").join("_").toLowerCase() as TabTitlesKeys;

    const tabsPropertiesMap = {
        transactions: {
            store: transactionsStore,
            isScrollable: true,
            filtersModalWidth: "400px",
            filters: <TransactionsChartsFilters closeModal={toggleFilterModal} />,
        },
        limits: {
            store: limitsStore,
            isScrollable: false,
            filtersModalWidth: "376px",
            filters: <LimitsChartsFilters closeModal={toggleFilterModal} />,
        },
        merchant_info: {
            store: merchantStore,
            isScrollable: false,
            filtersModalWidth: "376px",
            filters: <MerchantChartsFilters closeModal={toggleFilterModal} />,
        },
    };

    const {
        store,
        isScrollable,
        filtersModalWidth,
        filters,
    } = tabsPropertiesMap[modifiedCurrentTabTitle as TabTitlesKeys];

    const storesFilters = store.filters ?? {};
    const filtersCount = objectToValuesList(storesFilters as Record<string, unknown>).length;

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFilterModal}
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const FilterModalComponent = (
        <FiltersModal
            isOpen={isFilterModalVisible}
            width={filtersModalWidth}
            scrollable={isScrollable}
            setOn={toggleFilterModal}
        >
            {filters}
        </FiltersModal>
    );

    const actionHeaderContentList = [
        { key: "filters-button-component", content: FiltersButtonComponent },
    ];

    return {
        FilterModalComponent,
        actionHeaderContentList,
    };
}
