import { flow } from "mobx";

import activateLists from "../../../api/lists/activateLists";
import deactivateLists from "../../../api/lists/deactivateLists";
import { getLists } from "../../../api/lists/getLists";
import { ListsTypes, NewListType } from "../../../types/listsTypes";
import dataStore from "../../common/dataStore";

class AlertsListsStore extends dataStore<ListsTypes, any, NewListType> {
    protected deactivateSelectedItemsMethod = deactivateLists;

    protected activateSelectedItemsMethod = activateLists;

    constructor() {
        super("AlertsListsStore");
    }

    protected fetchDataByIdMethod = getLists;

    fetchData = flow(function* fetchData(this: AlertsListsStore, id: number | string) {
        yield this.fetchDataById({ alert: id });
    });
}

const alertsListsStore = new AlertsListsStore();

export default alertsListsStore;
