import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { validateDateRange } from "../../../../../helpers/validationHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import {
    FormContainer,
    HeaderContainer,
    HeaderTitle,
} from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { WarningText } from "../../components/Chart/ChartsFiltersComponents";
import FieldsList from "./FieldsList";
import merchantStore from "../../../../../store/home/merchant/merchantStore";
import { MerchantFiltersTypes } from "../../../../../types/merchantTypes";

interface MerchantChartsFiltersProps {
    closeModal: () => void;
}

const MerchantChartsFilters = ({ closeModal }: MerchantChartsFiltersProps) => {
    const { t, translate } = useTranslation();

    const initialValues: MerchantFiltersTypes = { ...merchantStore.filters };

    const formik = useFormik<MerchantFiltersTypes>({
        initialValues,
        enableReinitialize: true,

        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),

        initialTouched: {
            created_to: !!merchantStore.filters?.created_to,
        },

        onSubmit: async (fields) => {
            merchantStore.updateFilters(fields);
            await merchantStore.fetchData();

            if (merchantStore.isFetchRequestSuccess) {
                closeModal();
            } else {
                snackbarStore.add({ variant: "error", text: merchantStore.fetchErrors });
            }
        },
    });

    const isSubmitButtonDisabled = (!formik.values.created_from || !formik.values.created_to)
        || (!formik.values.partners && !formik.values.services);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        merchantStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.filters.title}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                <FieldsList formik={formik} />

                <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitButtonDisabled}
                    isLoading={merchantStore.loading}
                >
                    {t.filters.apply}
                </Button>

                {isSubmitButtonDisabled && (
                    <WarningText>{t.home.filtersModal.warning.merchant}</WarningText>
                )}
            </FormContainer>
        </>
    );
};

export default observer(MerchantChartsFilters);
