import axios, { AxiosError, AxiosResponse } from "axios";
import {
    action, computed, flow, makeObservable, observable, reaction,
} from "mobx";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";
import { FetchStatusesType } from "../../types/fetchStatuses";
import { getCommonError, getFieldsErrors } from "../../helpers/apiHelper";
import { calculateOffset } from "../../helpers/tableHelper";
import {
    DataType,
    DeactivateSelectedItemResponseType,
    DeactivateSelectedItemsRequestType,
    FetchDataByIdRequestType,
    FetchDataRequestType,
    FetchDataResponseType,
    FetchSelectedItemResponseType,
    PaginationType,
    PatchResponseType,
    PostResponseType,
    RequestIdType,
    UnlinkSelectedItemRequestType,
    UnlinkSelectedItemResponseType,
} from "../../types/tableStoreTypes";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

type AnyObject = {
    [key: string]: any
}

const paginationDefaultState = {
    page: 1,
    size: 10,
    count: 0,
    totalActiveCount: 0,
};

const defaultPropertiesToStore = ["_filters"];

abstract class DataStore<TData extends DataType, TFilters, TNewData> {
    @observable protected _data: TData[] = [];

    @observable protected fetchState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected cloneState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected _checkedItems: number[] = [];

    @observable protected _deactivatedItems: string[] = [];

    @observable protected _activatedItems: string[] = [];

    @observable protected _selectedItem: TData | null = null;

    @observable protected editItemState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected getItemDetailsState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected postState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected _postErrors: any | null = null;

    @observable protected _fetchErrors: any | null = null;

    @observable protected changeStatusState: FetchStatusesType = FetchStatusesType.unset;

    @observable protected _changeStatusErrors: any | null = null;

    @observable protected _ordering?: string = undefined;

    @observable protected _pagination: PaginationType = paginationDefaultState;

    @observable protected _preservedCheckedItems: number[] = [];

    @observable protected _editItemErrors?: any;

    @observable protected _filters: TFilters | null = null;

    constructor(storeName?: string, customPropertiesToStore?: string[]) {
        makeObservable(this);
        if (storeName) {
            const resultPropertiesToStore = customPropertiesToStore?.length
                ? [...defaultPropertiesToStore, ...customPropertiesToStore]
                : defaultPropertiesToStore;
            makePersistable(this, {
                name: storeName,
                // @ts-expect-error It's only one way to use private properties with mobx-persist
                properties: resultPropertiesToStore,
                storage: localStorage,
            });
        }

        reaction(
            () => this._pagination.size,
            (newSize, previousSize) => {
                if (newSize > previousSize) {
                    this._preservedCheckedItems = [...this._checkedItems];
                } else {
                    this._preservedCheckedItems = [];
                }
            },
        );
    }

    async clearPersistedData() {
        await clearPersistedStore(this);
        this.resetFilters();
    }

    @computed
    public get postErrors() {
        return this._postErrors;
    }

    @computed
    public get fetchErrors() {
        return this._fetchErrors;
    }

    @computed
    public get checkedItems() {
        return this._checkedItems;
    }

    @computed
    public get deactivatedItems() {
        return this._deactivatedItems.join(", ");
    }

    @computed
    public get activatedItems() {
        return this._activatedItems.join(", ");
    }

    @computed
    public get hasActiveCheckedItems() {
        return this.data.some((item) => this.checkedItems.includes(Number(item.id)) && item.is_active);
    }

    @computed
    public get hasDisabledCheckedItems() {
        return this.data.some((item) => this.checkedItems.includes(Number(item.id)) && !item.is_active);
    }

    @computed
    public get ordering() {
        return this._ordering;
    }

    @computed
    public get data() {
        return this._data;
    }

    @computed
    public get filters() {
        return this._filters;
    }

    @computed
    public get pagination() {
        return this._pagination;
    }

    @computed
    public get selectedItem() {
        return this._selectedItem;
    }

    @computed
    public get editItemErrors() {
        return this._editItemErrors;
    }

    @computed
    public get changeStatusErrors() {
        return this._changeStatusErrors;
    }

    @action
    public resetChangeStatusErrors = () => {
            this._changeStatusErrors = null;
        };

    @computed
    public get loading() {
        return this.fetchState === FetchStatusesType.pending
            || this.postState === FetchStatusesType.pending
            || this.editItemState === FetchStatusesType.pending
            || this.changeStatusState === FetchStatusesType.pending;
    }

    @computed
    public get isStatusChanging() {
        return this.changeStatusState === FetchStatusesType.pending;
    }

    @computed
    public get isCloning() {
        return this.cloneState === FetchStatusesType.pending;
    }

    @computed
    public get isGettingItemDetailsInProgress() {
        return this.getItemDetailsState === FetchStatusesType.pending;
    }

    @computed
    public get isFetchRequestSuccess() {
        return this.fetchState === FetchStatusesType.success;
    }

    @computed
    public get isPostRequestSuccess() {
        return this.postState === FetchStatusesType.success;
    }

    @computed
    public get isCloneRequestSuccess() {
        return this.cloneState === FetchStatusesType.success;
    }

    @computed
    public get isPatchRequestSuccess() {
        return this.editItemState === FetchStatusesType.success;
    }

    @computed
    public get isChangeStatusRequestSuccess() {
        return this.changeStatusState === FetchStatusesType.success;
    }

    @computed
    public get isEditItemSuccess() {
        return this.editItemState === FetchStatusesType.success;
    }

    @action
    public resetEditItemState = () => {
            this.editItemState = FetchStatusesType.unset;
        };

    @action
    public resetPostItemState = () => {
            this.postState = FetchStatusesType.unset;
        };

    @action
    public resetChangeStatusState = () => {
            this.changeStatusState = FetchStatusesType.unset;
        };

    @action
    public clearSelectedItem = () => {
            this._selectedItem = null;
        };

    @action
    public updateFilters = (_filters: typeof this._filters) => {
            this._filters = _filters && { ...this._filters, ..._filters };
            this._pagination.page = 1;
        };

    @action
    public setFilters = (_filters: typeof this._filters) => {
            this._filters = _filters;
            this._pagination.page = 1;
        };

    @action
    public toggleItem = (itemId: number | null | string) => {
            if (itemId && typeof itemId === "number") {
                if (this._checkedItems.includes(itemId)) {
                    this._checkedItems = this._checkedItems.filter((id) => id !== itemId);
                } else {
                    this._checkedItems = [...this._checkedItems, itemId];
                }
            }
        };

    @action
    public toggleRadioItem = (itemId: number | null | string) => {
            if (itemId && typeof itemId === "number") {
                this._checkedItems = [itemId];
            } else {
                this._checkedItems = [];
            }
        };

    @action
    public toggleMainItem = () => {
            const allItemsChecked = this._checkedItems.length === this._data.length && !!this._data.length;
            if (allItemsChecked) {
                this._checkedItems = [];
            } else (this._checkedItems = this._data.map((item) => +item.id));
        };

    @action
    public setCheckedItems = (_checkedItems: number[]) => {
            this._checkedItems = _checkedItems;
        };

    @action
    public setPaginationPage = (page: number) => {
            this._pagination = { ...this._pagination, page };
            this.setCheckedItems([]);
        };

    @action
    public setPaginationSize = (size: number) => {
            this._pagination = {
                ...this._pagination,
                page: 1,
                size,
            };
        };

    @action
    public toggleSort = (_ordering: string | null) => {
            this._ordering = _ordering ?? undefined;
        };

    @action
    public resetFetchErrors = () => {
            this._fetchErrors = null;
        };

    @action
    public resetPostErrors = () => {
            this._postErrors = null;
        };

    @action
    public resetPostError = (field: string) => {
            const newErrorsList = { ...this._postErrors };
            delete newErrorsList[field];
            delete newErrorsList.common;
            this._postErrors = newErrorsList;
        };

    @action
    public unsetEditState = () => {
            this.editItemState = FetchStatusesType.unset;
            this._editItemErrors = null;
        };

    @action
    public resetEditError = (field: string) => {
            const newErrorsList = { ...this._editItemErrors };
            delete newErrorsList[field];
            delete newErrorsList.common;
            this._editItemErrors = newErrorsList;
        };

    @action
    public resetFilters() {
        this._filters = null;
    }

    public parseFilters = (filters: typeof this._filters) => {
        const formattedFilters: AnyObject = { ...filters };
        Object.keys(formattedFilters).forEach((key) => {
            if (filters && Array.isArray(formattedFilters[key])) {
                formattedFilters[key] = formattedFilters[key]?.join(",");
            }
        });
        return formattedFilters as TFilters;
    };

    protected fetchDataMethod?: (
        request: FetchDataRequestType<TFilters>,
        signal?: AbortSignal
    ) => Promise<AxiosResponse<FetchDataResponseType<TData>>>;

    fetchWithoutSet = flow(function* fetchWithoutSet(
        this: DataStore<any, any, any>,
        signal?: AbortSignal,
    ) {
        if (this.fetchDataMethod) {
            try {
                this.fetchState = FetchStatusesType.pending;
                this._checkedItems = [];
                const offset = calculateOffset(this._pagination.page, this._pagination.size);
                const limit = this._pagination.size;
                const { _filters, _ordering } = this;
                const filters: TFilters = this.parseFilters(_filters);
                const requestData = {
                    ordering: _ordering, limit, offset, ...filters,
                };
                const response: AxiosResponse<FetchDataResponseType<TData>> = yield this.fetchDataMethod(
                    requestData as FetchDataRequestType<TFilters>,
                    signal,
                );
                if (this._preservedCheckedItems.length) {
                    this._checkedItems = [...this._preservedCheckedItems];
                }
                this._fetchErrors = null;
                this.fetchState = FetchStatusesType.success;
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn("Fetch request was cancelled.");
                } else {
                    this.fetchState = FetchStatusesType.failed;
                    this._fetchErrors = getCommonError(error);
                }
            }
        } else {
            console.error("Using fetchWithoutSet without creating abstract fetchDataMethod in subclass");
        }
        return undefined;
    });

    fetch = flow(function* fetch(
        this: DataStore<any, any, any>,
        signal?: AbortSignal,
    ) {
        const response = yield this.fetchWithoutSet(signal);
        if (response?.data.response) {
            this._data = (response.data as FetchDataResponseType<TData>)?.response.results
                || response.data.response;
            this._pagination.count = response.data.response.count;
        }
    });

    protected fetchDataByIdMethod?: (
        request: FetchDataByIdRequestType<TFilters>,
        signal?: AbortSignal
    ) => Promise<AxiosResponse<FetchDataResponseType<TData>>>;

    fetchDataByIdWithoutSet = flow(function* fetchDataByIdWithoutSet(
        this: DataStore<any, any, any>,
        id: RequestIdType,
        signal?: AbortSignal,
    ) {
        if (this.fetchDataByIdMethod) {
            try {
                this.fetchState = FetchStatusesType.pending;
                this._checkedItems = [];
                const offset = calculateOffset(this._pagination.page, this._pagination.size);
                const limit = this._pagination.size;
                const { _filters, _ordering } = this;
                const filters: TFilters = this.parseFilters(_filters);
                const requestData = {
                    ordering: _ordering, limit, offset, ...filters, ...id,
                };
                const response: AxiosResponse<FetchDataResponseType<TData>> = yield this.fetchDataByIdMethod(
                    requestData as FetchDataByIdRequestType<TFilters>,
                    signal,
                );
                if (this._preservedCheckedItems.length) {
                    this._checkedItems = [...this._preservedCheckedItems];
                }
                this.fetchState = FetchStatusesType.success;
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn("Fetch by ID request was cancelled.");
                } else {
                    this.fetchState = FetchStatusesType.failed;
                }
            }
        } else {
            console.error("Using fetchDataById without creating abstract fetchDataByIdMethod in subclass");
        }
        return undefined;
    });

    fetchDataById = flow(function* fetchDataById(
        this: DataStore<any, any, any>,
        id: RequestIdType,
        signal?: AbortSignal,
    ) {
        const response = yield this.fetchDataByIdWithoutSet(id, signal);
        if (response?.data.response) {
            this._data = (response.data as FetchDataResponseType<TData>)?.response.results
                || response.data.response;
            this._pagination.count = response.data.response.count;
        }
    });

    protected fetchSelectedItemMethod?: (
        id: string,
    ) => Promise<AxiosResponse<FetchSelectedItemResponseType<TData>>>;

    fetchSelectedItemWithoutSet = flow(function* fetchSelectedItemWithoutSet(
        this: DataStore<any, any, any>,
        id: number | string,
        forceFetch = false,
    ) {
        if (this.fetchSelectedItemMethod) {
            const parsedId = Number(id);
            const item = !forceFetch ? this._data.find((x) => x.id === parsedId) : false;
            if (!item) {
                try {
                    this.getItemDetailsState = FetchStatusesType.pending;
                    const response: AxiosResponse<FetchSelectedItemResponseType<TData>> = (
                        yield this.fetchSelectedItemMethod(id?.toString())
                    );
                    this.getItemDetailsState = FetchStatusesType.success;
                    return response;
                } catch (error) {
                    this.getItemDetailsState = FetchStatusesType.failed;
                }
            } else {
                this._selectedItem = item || null;
            }
        } else {
            console.error("Using getItemDetails without creating abstract fetchSelectedItemMethod in subclass");
        }
        return undefined;
    });

    fetchSelectedItem = flow(function* fetchSelectedItem(
        this: DataStore<any, any, any>,
        id: number | string,
        forceFetch = false,
    ) {
        const response = yield this.fetchSelectedItemWithoutSet(id, forceFetch);
        if (response?.data.response) {
            this._selectedItem = (response.data as FetchSelectedItemResponseType<TData>)?.response;
        }
    });

    protected postDataMethod?: (_data: TNewData) => Promise<AxiosResponse<PostResponseType<TData>>>;

    postData = flow(function* postData(
        this: DataStore<any, any, any>,
        _data: TNewData,
        fieldsList: string[],
    ) {
        if (this.postDataMethod) {
            try {
                this.postState = FetchStatusesType.pending;
                yield this.postDataMethod(_data);
                this._postErrors = null;
                this.postState = FetchStatusesType.success;
            } catch (error) {
                const axiosError = error as AxiosError;
                this._postErrors = getFieldsErrors(axiosError, fieldsList);
                this.postState = FetchStatusesType.failed;
            }
        } else {
            console.error("Using postData without creating abstract postDataMethod in subclass");
        }
    });

    protected cloneDataMethod?:
        (_data: TNewData, _id: string | number) => Promise<AxiosResponse<PostResponseType<TData>>>;

    cloneData = flow(function* cloneData(
        this: DataStore<any, any, any>,
        _data: TNewData,
        _id: string | number,
        fieldsList: string[],
    ) {
        if (this.cloneDataMethod) {
            try {
                this.cloneState = FetchStatusesType.pending;
                yield this.cloneDataMethod(_data, _id);
                this._postErrors = null;
                this.cloneState = FetchStatusesType.success;
            } catch (error) {
                const axiosError = error as AxiosError;
                this._postErrors = getFieldsErrors(axiosError, fieldsList);
                this.cloneState = FetchStatusesType.failed;
            }
        } else {
            console.error("Using cloneData without creating abstract cloneDataMethod in subclass");
        }
    });

    protected patchDataMethod?: (_data: Partial<TData>, id: string) => (
        Promise<AxiosResponse<PatchResponseType<TData>>>
        );

    patchDataWithoutSet = flow(function* patchDataWithoutSet(
        this: DataStore<any, any, any>,
        _data: Partial<TData>,
        limitId: number | string,
        fieldsList: string[],
    ) {
        if (this.patchDataMethod) {
            try {
                this.editItemState = FetchStatusesType.pending;
                const response: AxiosResponse<PatchResponseType<TData>> = yield this.patchDataMethod(
                    _data,
                    limitId?.toString(),
                );
                this.editItemState = FetchStatusesType.success;
                return response;
            } catch (error) {
                const errorData = error as AxiosError;
                this._editItemErrors = getFieldsErrors(errorData, fieldsList);
                this.editItemState = FetchStatusesType.failed;
            }
        }
        console.error("Using patchDataMethod without creating abstract patchDataMethod in subclass");

        return undefined;
    });

    patchData = flow(function* patchData(
        this: DataStore<any, any, any>,
        _data: Partial<TData>,
        limitId: number | string,
        fieldsList: string[],
    ) {
        const response = yield this.patchDataWithoutSet(_data, limitId, fieldsList);

        if (response?.data.response) {
            const changedItem = { ...this._selectedItem, ...response.data.response };
            this._selectedItem = changedItem;
            this._data = this._data.map((item) => (item.id === +limitId ? changedItem : item));
        }
    });

    protected deactivateSelectedItemsMethod?: (requestData: DeactivateSelectedItemsRequestType) => (
        Promise<AxiosResponse<DeactivateSelectedItemResponseType>>
        );

    deactivateSelectedItems = flow(function* deactivateSelectedItems(
        this: DataStore<any, any, any>,
    ) {
        if (this.deactivateSelectedItemsMethod) {
            if (this._data.length) {
                try {
                    this.changeStatusState = FetchStatusesType.pending;
                    const response: AxiosResponse<ChangeStatusResponseType> = yield this.deactivateSelectedItemsMethod({
                        id: this._checkedItems,
                    });

                    this._deactivatedItems = response.data.response.details.flatMap((item) => Object.keys(item));
                    this._checkedItems = [];
                    this.changeStatusState = FetchStatusesType.success;
                    return FetchStatusesType.success;
                } catch (error) {
                    this.changeStatusState = FetchStatusesType.failed;
                }
                return this.changeStatusState;
            }
        } else {
            console.error(
                "Using deactivateSelectedItems without creating abstract deactivateSelectedItemsMethod in subclass",
            );
        }
        return undefined;
    });

    protected activateSelectedItemsMethod?: (requestData: DeactivateSelectedItemsRequestType) => (
        Promise<AxiosResponse<DeactivateSelectedItemResponseType>>
        );

    activateSelectedItems = flow(function* activateSelectedItems(
        this: DataStore<any, any, any>,
    ) {
        if (this.activateSelectedItemsMethod) {
            if (this._data.length) {
                try {
                    this.changeStatusState = FetchStatusesType.pending;
                    const response: AxiosResponse<ChangeStatusResponseType> = yield this.activateSelectedItemsMethod({
                        id: this._checkedItems,
                    });

                    this._activatedItems = response.data.response.details.flatMap((item) => Object.keys(item));
                    this._checkedItems = [];
                    this.changeStatusState = FetchStatusesType.success;
                    return FetchStatusesType.success;
                } catch (error) {
                    this.changeStatusState = FetchStatusesType.failed;
                    const errorData = error as AxiosError;
                    this._changeStatusErrors = getFieldsErrors(errorData, ["name"]);
                }
                return this.changeStatusState;
            }
        } else {
            console.error(
                "Using activateSelectedItems without creating abstract activateSelectedItemsMethod in subclass",
            );
        }
        return undefined;
    });

    protected unlinkSelectedItemMethod?: (requestData: UnlinkSelectedItemRequestType) => (
        Promise<AxiosResponse<UnlinkSelectedItemResponseType>>
        );

    unlinkSelectedItem = flow(function* unlinkSelectedItems(
        this: DataStore<any, any, any>,
    ) {
        if (this.unlinkSelectedItemMethod) {
            if (this._checkedItems.length) {
                try {
                    this.changeStatusState = FetchStatusesType.pending;
                    yield this.unlinkSelectedItemMethod(this._checkedItems[0]);
                    this._checkedItems = [];
                    this.changeStatusState = FetchStatusesType.success;
                    return FetchStatusesType.success;
                } catch (error) {
                    this.changeStatusState = FetchStatusesType.failed;
                    const errorData = error as AxiosError;
                    this._changeStatusErrors = getCommonError(errorData);
                }
                return this.changeStatusState;
            }
        } else {
            console.error(
                "Using unlinkSelectedItems without creating abstract unlinkSelectedItemsMethod in subclass",
            );
        }
        return undefined;
    });
}

export default DataStore;
