import request from "../request";

export interface UploadFileResponse {
    message: string;
    data: {
        response: {
            task_id: string;
        }
    }
}

export const getUploadFileId = (data: FormData, signal?: AbortSignal) => request<UploadFileResponse>({
    method: "POST",
    uri: "elements/files/upload/",
    data,
    signal,
});
