/* eslint-disable camelcase */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { useParams } from "react-router-dom";

import {
    alertImportanceOptionsHandbook,
    alertTypeOptionsHandbook,
    attributesOptionsHandbook,
    getTranslatedHandbook,
} from "../../../../../constants/handbooks";
import { permissionsMap } from "../../../../../constants/permissions";
import { FieldMode } from "../../../../../constants/selectFields";
import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../../constants/validationRules";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import filtersTgGroupsStore from "../../../../../store/filters/tgGroupsFiltersStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import {
    AlertPartnerTypes,
    AlertProcessingTypes,
    AlertServiceTypes,
    EditAlertTypes,
} from "../../../../../types/alertsTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import {
    fieldsList,
    getFieldStates,
    validationSchema,
} from "../../helpers/editAlertFormikHelpers";
import { AlertTypeValue } from "../new/NewAlert";

const EditAlertModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: alertId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = alertsStore;

    const partnersIdsList = selectedItem?.partners.map((partner) => partner.id);
    const servicesIdsList = selectedItem?.services.map((service) => service.id);
    const processingId = (selectedItem?.processing as AlertProcessingTypes)?.id;

    const fieldsOptionsMap = {
        importance: getTranslatedHandbook(translate, alertImportanceOptionsHandbook),
        types: getTranslatedHandbook(translate, alertTypeOptionsHandbook),
        displayAttributes: getTranslatedHandbook(translate, attributesOptionsHandbook),
    };

    const formik = useFormik<EditAlertTypes & { processing?: number }>({
        initialValues: {
            ...selectedItem,
            partners: partnersIdsList,
            services: servicesIdsList,
            processing: processingId,
        },
        enableReinitialize: true,
        validationSchema: validationSchema(t, translate),

        onSubmit: async (values) => {
            if (alertId) {
                const partners = (values.partners || []) as AlertPartnerTypes[];
                const services = (values.services || []) as AlertServiceTypes[];

                const fieldsData = { ...values, partners, services };

                await alertsStore.patchSelectedItem(fieldsData, alertId, fieldsList);

                if (alertsStore.isEditItemSuccess) {
                    setOn(!isOpen);
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                } else if (editItemErrors?.common) {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
            }
        },
    });

    const isTgAlertType = formik.values.alert_type === AlertTypeValue.TG;
    const isEmailAlertType = formik.values.alert_type as AlertTypeValue === AlertTypeValue.EMAIL;

    const fieldStates = getFieldStates(formik.values, alertsStore.loading);

    const handleCloseModal = () => {
        alertsStore.unsetEditState();
        formik.resetForm();
    };

    const toggleAlertStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const clearTgGroupOrEmailFieldsValues = () => {
        if (isTgAlertType && formik.values.tg_group) formik.setFieldValue("tg_group", null);
        if (isEmailAlertType && formik.values.email) formik.setFieldValue("email", null);
    };

    useFetchWithAbort((signal: AbortSignal) => {
        if (isOpen) {
            filtersPartnersStore.fetch(signal);
            filtersServicesStore.fetch(signal);
            filtersProcessingStore.fetch(signal);
            filtersTgGroupsStore.fetch(signal);
        }
    }, [isOpen], handleCloseModal);

    return (
        <Modal
            isForm
            gap="0"
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={alertsStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
        >
            <EditSectionWrapper $margin="32px 0">
                <FloatInput
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="name"
                    isRequired
                    placeholder={t.alerts.placeholders.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="trigger_text"
                    isRequired
                    placeholder={t.alerts.placeholders.triggerText}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="alert_type"
                    isRequired
                    disabled={alertsStore.loading}
                    options={fieldsOptionsMap.types}
                    placeholder={t.alerts.placeholders.alertType}
                    serverErrors={alertsStore.postErrors}
                    resetServerError={alertsStore.resetPostError}
                    onChange={clearTgGroupOrEmailFieldsValues}
                />
                {isEmailAlertType && (
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="email"
                        isRequired
                        placeholder={t.alerts.placeholders.notificationsEmail}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                )}
                {isTgAlertType && (
                    <FloatSelect
                        formik={formik}
                        name="tg_group"
                        isRequired
                        disabled={alertsStore.loading}
                        placeholder={t.filters.fields.tgGroup}
                        options={filtersTgGroupsStore.options}
                        serverErrors={editItemErrors}
                        resetServerError={resetEditError}
                    />
                )}
                <FloatSelect
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="importance"
                    isRequired
                    placeholder={t.alerts.placeholders.importance}
                    options={fieldsOptionsMap.importance}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="display_attributes"
                    mode={FieldMode.Multiple}
                    isRequired
                    placeholder={t.alerts.placeholders.displayAttributes}
                    options={fieldsOptionsMap.displayAttributes}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="partners"
                    mode={FieldMode.Multiple}
                    isRequired={fieldStates.isPartnersFieldRequired}
                    disabled={fieldStates.isPartnersFieldDisabled}
                    placeholder={t.alerts.placeholders.partner}
                    options={filtersPartnersStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="services"
                    mode={FieldMode.Multiple}
                    isRequired={fieldStates.isServicesFieldRequired}
                    disabled={fieldStates.isServicesFieldDisabled}
                    placeholder={t.alerts.placeholders.service}
                    options={filtersServicesStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    isRequired={fieldStates.isProcessingFieldRequired}
                    disabled={fieldStates.isProcessingFieldDisabled}
                    options={filtersProcessingStore.options}
                    placeholder={t.alerts.placeholders.processing}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.alerts.placeholders.message}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />

                    <ProtectedSwitch
                        permission={permissionsMap.alerts.changeStatus}
                        disabled={alertsStore.loading}
                        checked={formik.values.is_active}
                        onChange={toggleAlertStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditAlertModal);
