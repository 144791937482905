/* eslint-disable no-plusplus */
import dayjs from "dayjs";

import { DATE_INPUT_FORMAT } from "../constants/dates";
import snackbarStore from "../store/snackbar/snackbarStore";

function fromStringWithCommasToArray(string: string): string[] {
    const stringArray = string.trim().split(",");
    if (stringArray.length === 1 && stringArray[0] === "") return [];
    return stringArray.map((word) => word.replace(" ", ""));
}

function parseDate(date?: string) {
    const parsedDate = dayjs(date);
    if (parsedDate.isValid()) {
        return parsedDate.format(DATE_INPUT_FORMAT) as any;
    }
    return undefined;
}

function parseDateFromServer<T extends Record<string, any>>(data: T[], ...keys: (keyof T)[]) {
    return data.map((dataObject) => {
        const clonedObject = { ...dataObject };
        keys.forEach((key) => {
            clonedObject[key] = parseDate(clonedObject[key]);
        });
        return clonedObject;
    });
}

function capitalizeFirstLetter(text?: string): string {
    if (text && typeof text === "string") {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return "";
}

function objectToValuesList<T>(obj: Record<string, T>): T[] {
    return Object.values(obj)
        .filter((value) => ![undefined, null, ""]
            .includes(value as string) && (value as string).length !== 0);
}

function isEmptyArray<T>(entities: T): boolean {
    return Array.isArray(entities) && !entities.length;
}

function extractValues<T extends Record<string, any>>(obj: T, inputList?: (keyof T)[]): T[keyof T][] {
    const outputList: T[keyof T][] = [];

    if (inputList) {
        inputList.forEach((field) => {
            if (field in obj) {
                outputList.push(obj[field]);
            }
        });
    }

    return outputList;
}

function selectObjectProperties<T extends Record<string, any>>(obj: T, itemsList: Array<keyof T>): Partial<T> {
    const outputObj = {} as T;

    itemsList.forEach((item) => {
        if (item in obj) outputObj[item] = obj[item];
    });

    return outputObj;
}

async function copyTextToClipboard(text: string) {
    try {
        await navigator.clipboard.writeText(text);
        snackbarStore.add({ variant: "success", text: "Data copied!" });
    } catch (error) {
        snackbarStore.add({ variant: "error", text: "Failed to copy data." });
    }
}

function togglePageScroll(isOpen: boolean) {
    if (isOpen) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "";
    }
    return () => {
        document.body.style.overflow = "";
    };
}

function generateRandomColor(): string {
    return `#${Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, "0")}`;
}

function generateUniqueId(): string {
    return `id_${Math.random().toString(36).slice(2, 11)}`;
}

export {
    fromStringWithCommasToArray,
    parseDate,
    parseDateFromServer,
    capitalizeFirstLetter,
    objectToValuesList,
    isEmptyArray,
    extractValues,
    selectObjectProperties,
    copyTextToClipboard,
    togglePageScroll,
    generateRandomColor,
    generateUniqueId,
};
