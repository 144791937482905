import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

import { permissionsMap } from "../../../constants/permissions";
import useTabs from "../../../hooks/useTabs";
import useTranslation from "../../../hooks/useTranslation";
import alertsStore from "../../../store/alerts/alerts/alertsStore";
import notificationsStore from "../../../store/alerts/notifications/notificationsStore";
import tgGroupsStore from "../../../store/alerts/tgGroups/tgGroupsStore";
import filtersAlertsStore from "../../../store/filters/alerts/alertsFiltersStore";
import filtersCreatedByStore from "../../../store/filters/createdByFilterStore";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersTgGroupsStore from "../../../store/filters/tgGroupsFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import ProtectedAlertsTable from "./alerts/ProtectedAlertsTable";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedNotificationsTable from "./notifications/ProtectedNotificationsTable";
import ProtectedTgGroupsTable from "./tgGroups/ProtectedTgGroupsTable";
import { LocationState } from "../../../types/locationTypes";
import { Tabs } from "../../../constants/tabs";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";

const PagesAlerts = () => {
    const { t } = useTranslation();
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const alertsCount = alertsStore.pagination.count;
    const notificationsCount = notificationsStore.countToCheck;
    const tgGroupsCount = tgGroupsStore.pagination.count;

    const headerTitle = t.alerts.headerTitles;

    const tabsPropsList = [
        {
            title: t.alerts.title,
            name: Tabs.ALERTS,
            itemsCount: alertsCount,
            permission: permissionsMap.alerts.view,
            content: <ProtectedAlertsTable permission={permissionsMap.alerts.view} />,
        },
        {
            title: t.notifications.title,
            name: Tabs.NOTIFICATIONS,
            itemsCount: notificationsCount,
            permission: permissionsMap.notifications.view,
            content: <ProtectedNotificationsTable permission={permissionsMap.notifications.view} />,
        },
        {
            title: t.tgGroups.title,
            name: Tabs.TG_GROUPS,
            itemsCount: tgGroupsCount,
            permission: permissionsMap.tg_groups.view,
            content: <ProtectedTgGroupsTable permission={permissionsMap.tg_groups.view} />,
        },
    ];

    const {
        TabsLayout, activeTab, currentTab, currentTabTitle,
    } = useTabs(tabsPropsList, defaultActiveTab);

    const {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    } = useTabsActions(currentTabTitle, currentTab);

    useFetchWithAbort((signal: AbortSignal) => {
        alertsStore.fetchData(signal);
        notificationsStore.fetch(signal);
        tgGroupsStore.fetch(signal);

        filtersAlertsStore.fetch(signal);
        filtersTgGroupsStore.fetch(signal);
        filtersPartnersStore.fetch(signal);
        filtersServicesStore.fetch(signal);
        filtersProcessingStore.fetch(signal);
        filtersCreatedByStore.fetch(signal);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} tabs={TabsLayout} actionsContent={actionHeaderContentList} />

            {activeTab}

            {FilterModalComponent}

            {ActionModalComponent}
        </>
    );
};

export default observer(PagesAlerts);
