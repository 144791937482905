import { useMemo, useState } from "react";

import { permissionsMap } from "../../../../../../constants/permissions";
import { Tabs } from "../../../../../../constants/tabs";
import { toggleActionModalState } from "../../../../../../helpers/modalHelper";
import { useNavigation } from "../../../../../../hooks/useNavigation";
import useTranslation from "../../../../../../hooks/useTranslation";
import alertsListsStore from "../../../../../../store/alerts/alerts/alertsListsStore";
import { ActionModalState, ActionModalType } from "../../../../../../types/actionModalTypes";
import ActionModal from "../../../../../common/modal/actionModal/ActionModal";
import SmallButton from "../../../../../ui/button/SmallButton";
import ProtectedListsMenu from "../../../../lists/components/ProtectedListsMenu";

export function useTabsActions(alertId = "") {
    const { t } = useTranslation();
    const { navigateBackward } = useNavigation();

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const MenuComponent = (
        <ProtectedListsMenu
            store={alertsListsStore}
            permission={permissionsMap.lists.changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const handleNavigate = () => navigateBackward({ tab: Tabs.ALERTS });

    const handleListsFetch = () => alertsListsStore.fetchData(alertId);

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const listsTableHeaderActionContentList = useMemo(() => [
        { key: "menu-dropdown-component", content: MenuComponent },
    ], [permissionsMap, alertsListsStore]);

    const ActionModalComponent = (
        <ActionModal
            store={alertsListsStore}
            entityName={t.lists.text.partOfLink}
            customFetchMethod={handleListsFetch}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    return {
        ActionModalComponent,
        actionHeaderContentList,
        listsTableHeaderActionContentList,
    };
}
