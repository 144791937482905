import { ServicesTypes } from "../../../types/servicesTypes";
import request from "../../request";

interface GetProcessingServicesResponse {
    message: string,
    response: {
        count: number,
        results: ServicesTypes[],
    }
}

export const getProcessingServices = (signal?: AbortSignal) => request<GetProcessingServicesResponse>({
    method: "GET",
    uri: "entities/filters/processing-services/",
    signal,
});
