import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";

import * as Yup from "yup";
import {
    attributesOptionsHandbook,
    alertTriggerOptionsHandbook,
    alertTypeOptionsHandbook,
    getTranslatedHandbook,
    statusOptionsHandbook,
} from "../../../../constants/handbooks";
import useTranslation from "../../../../hooks/useTranslation";
import alertsStore from "../../../../store/alerts/alerts/alertsStore";
import alertsFiltersStore from "../../../../store/filters/alerts/alertsFiltersStore";
import createdByFilterStore from "../../../../store/filters/createdByFilterStore";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import filtersServicesStore from "../../../../store/filters/servicesFilterStore";
import { AlertsFiltersTypes } from "../../../../types/alertsTypes";

import {
    ButtonStickyContainer,
    FormContainer,
    HeaderContainer,
    HeaderTitle,
    ScrollableFormContainer,
    SubTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import DatePicker from "../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { validateDateRange } from "../../../../helpers/validationHelper";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import { FieldMode } from "../../../../constants/selectFields";

interface AlertsFiltersProps {
    closeModal: () => void;
}

const AlertsFilters = ({ closeModal }: AlertsFiltersProps) => {
    const { t, translate } = useTranslation();

    const fieldsOptionsMap = {
        types: getTranslatedHandbook(translate, alertTypeOptionsHandbook),
        trigger: getTranslatedHandbook(translate, alertTriggerOptionsHandbook),
        status: getTranslatedHandbook(translate, statusOptionsHandbook),
        displayAttributes: getTranslatedHandbook(translate, attributesOptionsHandbook),
    };

    const floatFieldsParamsList = [
        {
            key: "alerts-filters-id",
            name: "id",
            options:
            alertsFiltersStore.options,
            placeholder: t.filters.fields.alert,
        },
        {
            key: "alerts-filters-type",
            name: "alert_type",
            options: fieldsOptionsMap.types,
            placeholder: t.filters.fields.type,
        },
        {
            key: "alerts-filters-trigger",
            name: "trigger",
            options: fieldsOptionsMap.trigger,
            placeholder: t.filters.fields.trigger,
        },
        {
            key: "alerts-filters-partners",
            name: "partners",
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
        },
        {
            key: "alerts-filters-services",
            name: "services",
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
        },
        {
            key: "alerts-filters-processings",
            name: "processings",
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
            mode: FieldMode.Multiple,
        },
        {
            key: "alerts-filters-display-attributes",
            name: "display_attributes",
            options: fieldsOptionsMap.displayAttributes,
            placeholder: t.filters.fields.displayAttributes,
        },
        {
            key: "alerts-filters-status",
            name: "is_active",
            options: fieldsOptionsMap.status,
            placeholder: t.filters.fields.isActive,
        },
    ];

    const initialValues: AlertsFiltersTypes = { ...alertsStore.filters };

    const formik = useFormik<AlertsFiltersTypes>({
        initialValues,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!alertsStore.filters?.created_to,
        },
        enableReinitialize: true,
        onSubmit: (fields) => {
            alertsStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        alertsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.alerts.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit} $scrollable>
                <ScrollableFormContainer>
                    {floatFieldsParamsList.map((params) => <FloatSelect formik={formik} {...params} />)}

                    <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                    <DatePicker
                        formik={formik}
                        maxDate={formik.values.created_to}
                        name="created_from"
                        placeholder={t.filters.fields.from}
                    />
                    <DatePicker
                        formik={formik}
                        minDate={formik.values.created_from}
                        name="created_to"
                        placeholder={t.filters.fields.to}
                        isAddMilliseconds
                    />
                    <FloatSelect
                        formik={formik}
                        name="created_by"
                        options={createdByFilterStore.options}
                        placeholder={t.filters.fields.createdBy}
                    />
                </ScrollableFormContainer>

                <ButtonStickyContainer>
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={alertsStore.loading}
                    >
                        {t.filters.apply}
                    </Button>
                </ButtonStickyContainer>
            </FormContainer>
        </>
    );
};

export default observer(AlertsFilters);
