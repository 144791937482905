/* eslint-disable max-len */
import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import getMerchant from "../../../api/home/getMerchant";
import { getCommonError } from "../../../helpers/apiHelper";
import { formatListDataDate, formatMapDataDate } from "../../../helpers/statisticsHelper";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import {
    AmountChartsTypes,
    MerchantChartEntryTypes,
    MerchantFiltersTypes,
    MerchantTypes,
    StatusesTransactionsCharts,
} from "../../../types/merchantTypes";
import dataStore from "../../common/dataStore";

interface ResponseTypes {
    data: MerchantTypes;
}

class MerchantStore extends dataStore<MerchantTypes, MerchantFiltersTypes, null> {
    @observable private _amountCharts: AmountChartsTypes | null = null;

    @observable private _numberTransactionsChart: MerchantChartEntryTypes[] = [];

    @observable private _statusesTransactionsCharts: StatusesTransactionsCharts | null = null;

    @observable private _cardsChart: MerchantChartEntryTypes[] = [];

    @observable private _customersChart: MerchantChartEntryTypes[] = [];

    constructor() {
        super("MerchantStore");
        makeObservable(this);
    }

    @computed
    public get amountCharts() {
        return this._amountCharts;
    }

    @computed
    public get numberTransactionsChart() {
        return this._numberTransactionsChart;
    }

    @computed
    public get statusesTransactionsCharts() {
        return this._statusesTransactionsCharts;
    }

    @computed
    public get cardsChart() {
        return this._cardsChart;
    }

    @computed
    public get customersChart() {
        return this._customersChart;
    }

    @action
    private setChartsData(data: ResponseTypes["data"]["response"]) {
        this._amountCharts = formatMapDataDate(data.amount_charts, data.step);
        this._numberTransactionsChart = formatListDataDate(data.number_transactions_chart, data.step);
        this._statusesTransactionsCharts = formatMapDataDate(data.statuses_transactions_charts, data.step);
        this._cardsChart = formatListDataDate(data.cards_chart, data.step);
        this._customersChart = formatListDataDate(data.customers_chart, data.step);
    }

    public fetchData = flow(function* fetchData(this: MerchantStore) {
        try {
            if (this._filters) {
                this.fetchState = FetchStatusesType.pending;

                const filtersData = this.parseFilters(this._filters);

                const response: ResponseTypes = yield getMerchant(filtersData);

                this.setChartsData(response.data.response);
                this.fetchState = FetchStatusesType.success;
                this._fetchErrors = null;
            }
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
            this._fetchErrors = getCommonError(error);
        }
    });
}

const merchantStore = new MerchantStore();

export default merchantStore;
