import { ListsCategoryTypes } from "../../../../types/listsTypes";
import StatusBadge from "../../textField/StatusBadge";
import { StatusTextFieldVariants } from "../../textField/StatusBadgeComponents";

interface DataType {
    list_category?: ListsCategoryTypes;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const typeVariantMap: Record<string, StatusTextFieldVariants> = {
    "Other": "inactive",
    "Alert Exception": "primary",
    "Black": "blacklisted",
    "White": "whitelisted",
};

const TableFieldsListTypeBadge = <D extends DataType>({ dataItem }: PropsType<D>) => (
    dataItem.list_category ? (
        <StatusBadge variant={typeVariantMap[dataItem.list_category]}>
            {dataItem.list_category.split(" ").at(0)}
        </StatusBadge>
    ) : null
);

export default TableFieldsListTypeBadge;
