import { TooltipProps } from "recharts";

import { Box, Label, Text } from "../../components/Chart/ChartTooltip/ChartTooltipComponents";

type ChartTooltipProps = TooltipProps<string, any>;

const DISPLAYED_TRANSACTIONS = 5;

const ChartTooltip = ({ active, payload, label }: ChartTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <Box>
                <Label>{label}</Label>

                {payload.map(({ value, color, name }) => (
                    Array.isArray(value)
                        ? value.map((item, index) => (
                            (index < DISPLAYED_TRANSACTIONS) && <Text key={item}>{`${name}: ${item}`}</Text>))
                        : <Text $color={color} key={name}>{`${name.split(" ").at(0)}: ${value}`}</Text>
                ))}
            </Box>
        );
    }

    return null;
};

export default ChartTooltip;
