import request from "../request";
import { RulePackFiltersTypes } from "../../types/rulesPacksTypes";
import { RulesPacksPartnersTypes } from "../../types/rulesPacksPartnersTypes";

type GetRulesPacksPartnersRequest = RulePackFiltersTypes & {
    rules_pack?: string | number | null
    limit?: number
    offset?: number
    ordering?: string | null
}

interface GetRulesPacksPartnersResponse {
    message: string,
    response: {
        count: number,
        results: RulesPacksPartnersTypes[],
    }
}

const getRulesPacksPartners = (
    params: GetRulesPacksPartnersRequest,
    signal?: AbortSignal,
) => request<GetRulesPacksPartnersResponse>({
    method: "GET",
    uri: "rules-packs/partners/",
    params,
    signal,
});

export default getRulesPacksPartners;
