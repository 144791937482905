/* eslint-disable @typescript-eslint/no-use-before-define */
import { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

import { permissionsMap } from "../../../../../../../constants/permissions";
import Routes from "../../../../../../../constants/routes";
import { Tabs } from "../../../../../../../constants/tabs";
import { getDeactivateAndActivateMenuItems } from "../../../../../../../helpers/menuHelper";
import { toggleActionModalState } from "../../../../../../../helpers/modalHelper";
import useTabs from "../../../../../../../hooks/useTabs";
import useTranslation from "../../../../../../../hooks/useTranslation";
import rulesPackLimitsStore from "../../../../../../../store/rulesPack/rulesPackLimitsStore";
import rulesPackListsStore from "../../../../../../../store/rulesPack/rulesPackListsStore";
import rulesPackPartnersStore from "../../../../../../../store/rulesPack/rulesPackPartnersStore";
import rulesPackServicesStore from "../../../../../../../store/rulesPack/rulesPackServicesStore";
import rulesPackStore from "../../../../../../../store/rulesPack/rulesPackStore";
import snackbarStore from "../../../../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../../../../types/actionModalTypes";
import { LocationState } from "../../../../../../../types/locationTypes";
import { PermissionKeys } from "../../../../../../../types/permissionsTypes";
import ActionModal from "../../../../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../../../../ui/button/SmallButton";
import Link from "../../../../../../ui/link/Link";
import MenuDropdown from "../../../../../../ui/menuDropdown/MenuDropdown";
import Modal from "../../../../../../ui/modal/Modal";
import AddRulesPacksPartnersModal from "../../../../new/AddRulesPacksPartnersModal";
import AddRulesPacksServiceModal from "../../../../new/AddRulesPacksServiceModal";
import CloneRulesPackListModal from "../../../../new/CloneRulesPackListModal";
import RulesPacksLimitsTable from "../../../tabTables/RulesPacksLimitsTable";
import RulesPacksListsTable from "../../../tabTables/RulesPacksListsTable";
import RulesPacksPartnersTable from "../../../tabTables/RulesPacksPartnersTable";
import RulesPacksServicesTable from "../../../tabTables/RulesPacksServicesTable";
import ProtectedRulesPackDetailsMenu from "../../RulesPackDetailsMenu/ProtectedRulesPackDetailsMenu";
import { getRulesPackLimitsMenuItems } from "../../RulesPackDetailsMenu/rulesPacksLimitsMenuHelpers";
import RulesPacksLimitsMoveModal from "../../RulesPacksLimitsMoveModal/RulesPacksLimitsMoveModal";
import RulesPacksPartnersFilters from "../../RulesPacksPartnersFilters";
import RulesPacksServicesFilters from "../../RulesPacksServicesFilters";

type TabTitlesKeys = Extract<PermissionKeys, "partners" | "services" | "lists" | "limits">;

type TabsPropertiesMapTypes = {
    [key in TabTitlesKeys]: {
        store?: any;
        entityName: string;
        addModalTitle?: string;
        unlinkEntity?: string;
        addModalContent?: ReactNode;
        filterModalContent?: ReactNode;
        unlinkMethod?: () => Promise<void>;
        customFetchMethod?: () => Promise<void>;
        deactivateMethod?: () => Promise<void>;
    };
}

export function useTabsActions(ruleId: string, userPermissions: string[]) {
    const { t, translate } = useTranslation();
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const [isAddModalVisible, setAddModalVisibility] = useState(false);
    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const [isCloneModalVisible, setCloneModalVisibility] = useState(false);
    const [isMoveModalVisible, setMoveModalVisibility] = useState(false);

    const hasPartnerDeletePermission = userPermissions.includes(permissionsMap.rules_pack_partner.delete);
    const hasServiceDeletePermission = userPermissions.includes(permissionsMap.rules_pack_services.delete);

    const hasListsChangeStatusPermission = userPermissions.includes(permissionsMap.lists.changeStatus);
    const hasLimitsChangeStatusPermission = userPermissions.includes(permissionsMap.limits.changeStatus);

    const toggleAddModal = () => setAddModalVisibility((prev) => !prev);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);
    const toggleCloneModal = () => setCloneModalVisibility((prev) => !prev);
    const toggleMoveModal = () => setMoveModalVisibility((prev) => !prev);
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const listsMenuDropdownItemsList = getDeactivateAndActivateMenuItems(
        translate,
        toggleActionModal,
        rulesPackListsStore,
    );

    const limitsMenuDropdownItemsList = getRulesPackLimitsMenuItems(
        rulesPackLimitsStore,
        userPermissions,
        translate,
        toggleMoveModal,
        toggleActionModal,
    );

    const unlinkEntityMethod = async (store: typeof rulesPackPartnersStore | typeof rulesPackServicesStore) => {
        await store.unlinkSelectedItem();

        if (store.isChangeStatusRequestSuccess && ruleId) {
            toggleActionModal();
            snackbarStore.add({ variant: "success", text: t.snackbar.add.success });

            store.fetchData(ruleId);
            rulesPackStore.fetchSelectedItem(ruleId);
        }
    };

    const handleCloseAddItemModal = () => {
        toggleAddModal();
        snackbarStore.add({ variant: "success", text: t.snackbar.add.success });

        if (ruleId) {
            store.fetchData(ruleId);
            rulesPackStore.fetchSelectedItem(ruleId);
        }
    };

    const AddPartnerButtonComponent = (
        <ProtectedSmallButton
            key="add-partner-button-component"
            permission={[
                permissionsMap.partners.view,
                permissionsMap.rules_pack_partner.view,
                permissionsMap.rules_pack_partner.add,
            ]}
            isFullPermissionsMatchingRequired
            title={t.entities.partners.details.title}
            variant="tertiary"
            iconLeft={<PlusIcon />}
            onClick={toggleAddModal}
        />
    );

    const UnlinkPartnerButtonComponent = (
        <ProtectedSmallButton
            key="unlink-partner-button-component"
            isFullPermissionsMatchingRequired
            permission={[
                permissionsMap.partners.view,
                permissionsMap.rules_pack_partner.view,
                permissionsMap.rules_pack_partner.delete,
            ]}
            title={t.buttons.unlink}
            variant="secondary"
            type="button"
            disabled={!rulesPackPartnersStore.checkedItems.length}
            onClick={() => toggleActionModal("unlink")}
        />
    );

    const AddServiceButtonComponent = (
        <ProtectedSmallButton
            key="add-service-button-component"
            title={t.entities.services.details.title}
            isFullPermissionsMatchingRequired
            permission={[
                permissionsMap.services.view,
                permissionsMap.rules_pack_services.view,
                permissionsMap.rules_pack_services.add,
            ]}
            variant="tertiary"
            iconLeft={<PlusIcon />}
            onClick={toggleAddModal}
        />
    );

    const FiltersButtonComponent = (
        <SmallButton
            key="filter-button-component"
            variant="primary"
            onClick={toggleFilterModal}
            iconLeft={<FilterIcon />}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const UnlinkServiceButtonComponent = (
        <ProtectedSmallButton
            key="unlink-service-button-component"
            title={t.buttons.unlink}
            isFullPermissionsMatchingRequired
            permission={[
                permissionsMap.services.view,
                permissionsMap.rules_pack_services.view,
                permissionsMap.rules_pack_services.delete,
            ]}
            variant="secondary"
            onClick={() => toggleActionModal("unlink")}
            type="button"
            disabled={!rulesPackServicesStore.checkedItems.length}
        />
    );

    const AddListButtonComponent = (
        <ProtectedSmallButton
            key="add-list-button-component"
            permission={permissionsMap.lists.add}
            title={t.lists.text.listWithClone}
            variant="tertiary"
            iconLeft={<PlusIcon />}
            onClick={toggleCloneModal}
            isCapitalized={false}
        />
    );

    const ListMenuDropdownComponent = (
        <ProtectedRulesPackDetailsMenu
            key="dropdown-menu-button-component"
            menuItemsList={listsMenuDropdownItemsList}
            permission={permissionsMap.lists.changeStatus}
        />
    );

    const AddLimitButtonComponent = (
        <Link
            key="add-limit-button-component"
            to={`${Routes.limits.href}/new`}
            state={{ id: ruleId, tab: Tabs.LIMITS }}
        >
            <ProtectedSmallButton
                title={t.limits.text.partOfLink}
                permission={permissionsMap.limits.add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
            />
        </Link>
    );

    const LimitsMenuDropdownComponent = (
        <MenuDropdown
            key="dropdown-menu-button-component"
            items={limitsMenuDropdownItemsList}
        />
    );

    const tabsPropsList = [
        {
            title: t.entities.partners.title,
            name: Tabs.PARTNERS,
            itemsCount: rulesPackPartnersStore.pagination.count,
            permission: [permissionsMap.partners.view, permissionsMap.rules_pack_partner.view],
            content: <RulesPacksPartnersTable ruleId={ruleId} isRadioButtonVisible={hasPartnerDeletePermission} />,
            actionButtons: [AddPartnerButtonComponent, FiltersButtonComponent, UnlinkPartnerButtonComponent],
        },
        {
            title: t.entities.services.title,
            name: Tabs.SERVICES,
            itemsCount: rulesPackServicesStore.pagination.count,
            permission: [permissionsMap.services.view, permissionsMap.rules_pack_services.view],
            content: <RulesPacksServicesTable ruleId={ruleId} isRadioButtonVisible={hasServiceDeletePermission} />,
            actionButtons: [AddServiceButtonComponent, FiltersButtonComponent, UnlinkServiceButtonComponent],
        },
        {
            title: t.lists.title,
            name: Tabs.LISTS,
            itemsCount: rulesPackListsStore.pagination.count,
            permission: permissionsMap.lists.view,
            content: <RulesPacksListsTable ruleId={ruleId} isCheckboxVisible={hasListsChangeStatusPermission} />,
            actionButtons: [AddListButtonComponent, ListMenuDropdownComponent],
        },
        {
            title: t.limits.title,
            name: Tabs.LIMITS,
            itemsCount: rulesPackLimitsStore.pagination.count,
            permission: permissionsMap.limits.view,
            content: <RulesPacksLimitsTable ruleId={ruleId} isCheckboxVisible={hasLimitsChangeStatusPermission} />,
            actionButtons: [AddLimitButtonComponent, LimitsMenuDropdownComponent],
        },
    ];

    const tabsPropertiesMap: TabsPropertiesMapTypes = {
        partners: {
            store: rulesPackPartnersStore,
            entityName: t.rulesPacks.shortTitle,
            addModalTitle: t.rulesPacks.details.addPartner,
            unlinkEntity: t.entities.partners.partOfLink,
            addModalContent: <AddRulesPacksPartnersModal ruleId={ruleId} closeModal={handleCloseAddItemModal} />,
            filterModalContent: <RulesPacksPartnersFilters ruleId={ruleId} closeModal={toggleFilterModal} />,
            unlinkMethod: () => unlinkEntityMethod(rulesPackPartnersStore),
        },
        services: {
            store: rulesPackServicesStore,
            entityName: t.rulesPacks.shortTitle,
            addModalTitle: t.rulesPacks.details.addService,
            unlinkEntity: t.entities.services.partOfLink,
            addModalContent: <AddRulesPacksServiceModal ruleId={ruleId} closeModal={handleCloseAddItemModal} />,
            filterModalContent: <RulesPacksServicesFilters ruleId={ruleId} closeModal={toggleFilterModal} />,
            unlinkMethod: () => unlinkEntityMethod(rulesPackServicesStore),
        },
        lists: {
            store: rulesPackListsStore,
            entityName: t.lists.text.partOfLink,
            customFetchMethod: () => rulesPackListsStore.fetchData(ruleId),
        },
        limits: {
            store: rulesPackLimitsStore,
            entityName: t.limits.text.partOfLink,
            customFetchMethod: () => rulesPackLimitsStore.fetchData(ruleId),
        },
    };

    const { TabsLayout, activeTab, currentTabTitle } = useTabs(tabsPropsList, defaultActiveTab);

    const {
        store,
        entityName,
        addModalTitle,
        unlinkEntity,
        addModalContent,
        filterModalContent,
        unlinkMethod,
        customFetchMethod,
    } = tabsPropertiesMap[currentTabTitle as TabTitlesKeys];

    const ActionModalComponent = (
        <ActionModal
            store={store}
            customFetchMethod={customFetchMethod}
            handleUnlink={unlinkMethod}
            entityName={entityName}
            unlinkEntityName={unlinkEntity}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal width="400px" isOpen={isFilterModalVisible} setOn={toggleFilterModal}>
            {filterModalContent}
        </FiltersModal>
    );

    const CloneRulesPackListModalComponent = (
        <CloneRulesPackListModal
            isOpen={isCloneModalVisible}
            setOn={toggleCloneModal}
            title={t.rulesPacks.details.addList}
            buttonText={t.buttons.add}
        />
    );

    const RulesPacksLimitsMoveModalComponent = (
        <RulesPacksLimitsMoveModal
            ruleId={ruleId}
            isOpen={isMoveModalVisible}
            setOn={setMoveModalVisibility}
        />
    );

    const AddEntityModalComponent = (
        <Modal
            gap={!addModalTitle ? "0" : ""}
            title={addModalTitle}
            isOpen={isAddModalVisible}
            submitButton={false}
            buttonText={t.buttons.add}
            setOn={toggleAddModal}
        >
            {addModalContent}
        </Modal>
    );

    return {
        activeTab,
        TabsLayout,
        FilterModalComponent,
        ActionModalComponent,
        CloneRulesPackListModalComponent,
        RulesPacksLimitsMoveModalComponent,
        AddEntityModalComponent,
    };
}
