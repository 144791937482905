import request from "../request";
import { TransactionFiltersTypes } from "../../types/transactionTypes";

interface GetCountRequest extends TransactionFiltersTypes {
    last_transaction_id: number
}

interface GetCountResponse {
    message: string,
    response: {
        count: string
    }
}

export const getCount = (params?: GetCountRequest, signal?: AbortSignal) => request<GetCountResponse>({
    method: "GET",
    uri: "transactions/count/",
    params,
    signal,
});
