import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../../constants/permissions";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_USER_NAME_LENGTH } from "../../../../../constants/validationRules";
import { selectObjectProperties } from "../../../../../helpers";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import rolesFilterStore from "../../../../../store/filters/rolesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import accountStore from "../../../../../store/user/account/accountStore";
import employeeStore from "../../../../../store/user/employee/employeeStore";
import { EditEmployeeType } from "../../../../../types/employeesTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import { ModalSubText } from "../../../../ui/TextStyle";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";

const fieldsList: Array<keyof EditEmployeeType> = ["username", "is_active", "role", "info", "processing"];

const EditEmployeesModal = ({
    isOpen, title, buttonText, setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = employeeStore;

    const userProcessingId = accountStore.userProfile?.processing || null;

    const formik = useFormik<EditEmployeeType>({
        initialValues: {},
        validationSchema: Yup.object({
            username: Yup.string()
                .required(t.validation.required)
                .max(
                    MAX_FIELD_USER_NAME_LENGTH,
                    translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_USER_NAME_LENGTH}`),
                ),
            info: Yup.string().nullable(),
            role: Yup.string().required(t.validation.required),
        }),
        onSubmit: async (values) => {
            if (id) {
                await employeeStore.patchData(values, id, fieldsList);

                if (employeeStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    setOn(!isOpen);
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            }
        },
    });

    const processingPlaceholder = formik.values.processing ? t.placeholder.processing : t.placeholder.allProcessings;

    const handleCloseModal = () => {
        employeeStore.unsetEditState();
        formik.resetForm();
    };

    const toggleEmployeeStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useFetchWithAbort((signal: AbortSignal) => {
        if (isOpen) {
            if (!userProcessingId) filtersProcessingStore.fetch(signal);
            rolesFilterStore.fetch(signal);
        }

        const filteredFieldsData = selectObjectProperties({ ...selectedItem }, fieldsList);

        formik.setValues(filteredFieldsData);
    }, [selectedItem, isOpen]);

    useEffect(() => () => {
        handleCloseModal();
    }, []);

    return (
        <Modal
            isForm
            gap="0"
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={employeeStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
        >
            <ModalSubText $margin="16px 0 0 0">
                <span>{t.employees.edit.subTitle}</span>
                {!userProcessingId && <span>{t.employees.processingWarning}</span>}
            </ModalSubText>

            <EditSectionWrapper $margin="32px 0">
                <FloatSelect
                    formik={formik}
                    disabled={employeeStore.loading}
                    name="role"
                    options={rolesFilterStore.options}
                    isRequired
                    placeholder={t.table.header.role}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={employeeStore.loading}
                    name="username"
                    defaultValue={selectedItem?.username}
                    isRequired
                    placeholder={t.table.header.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                {!userProcessingId && (
                    <FloatSelect
                        formik={formik}
                        disabled={employeeStore.loading}
                        name="processing"
                        placeholder={processingPlaceholder}
                        options={filtersProcessingStore.options}
                        serverErrors={employeeStore.postErrors}
                        resetServerError={employeeStore.resetPostError}
                    />
                )}
                <FloatTextArea
                    formik={formik}
                    disabled={employeeStore.loading}
                    name="info"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.employees.placeholders.info}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        disabled={employeeStore.loading}
                        permission={permissionsMap.employees.changeStatus}
                        checked={formik.values.is_active}
                        onChange={toggleEmployeeStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditEmployeesModal);
