import request from "../request";
import { ListsTypes } from "../../types/listsTypes";

interface ResponseType {
    message: string,
    response: ListsTypes[],
}

export const getTransactionLists = (
    id: string | number,
    listType: string,
    signal: AbortSignal,
) => request<ResponseType>({
    method: "GET",
    uri: `transactions/${id}/lists/${listType}/`,
    signal,
});
