import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import {
    categoryOptionsHandbook,
    getTranslatedHandbook,
    groupOptionsHandbook,
    typeOptionsHandbook,
} from "../../../../constants/handbooks";
import { FieldMode } from "../../../../constants/selectFields";
import { validateDateRange } from "../../../../helpers/validationHelper";
import useTranslation from "../../../../hooks/useTranslation";
import filtersAlertsStore from "../../../../store/filters/alerts/alertsFiltersStore";
import createdByFilterStore from "../../../../store/filters/createdByFilterStore";
import filtersListsStore from "../../../../store/filters/lists/listsFiltersStore";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import filtersRulesPacksStore from "../../../../store/filters/rulesPacksFiltersStore";
import listStore from "../../../../store/list/listStore";
import { ListsFiltersTypes } from "../../../../types/listsTypes";
import {
    ButtonStickyContainer,
    FormContainer,
    HeaderContainer,
    HeaderTitle,
    ScrollableFormContainer,
    SubTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import DatePicker from "../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../ui/textInput/FloatSelect";

interface ListsFiltersProps {
    closeModal: () => void;
}

const ListsFilters = ({ closeModal }: ListsFiltersProps) => {
    const { t, translate } = useTranslation();
    const initialValues: ListsFiltersTypes = { ...listStore.filters };

    const fieldsOptionsMap = {
        type: getTranslatedHandbook(translate, typeOptionsHandbook),
        category: getTranslatedHandbook(translate, categoryOptionsHandbook),
        group: getTranslatedHandbook(translate, groupOptionsHandbook),
    };

    const formik = useFormik<ListsFiltersTypes>({
        initialValues,
        enableReinitialize: true,

        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
            updated_to: validateDateRange(translate, "updated_from"),
        }),
        initialTouched: {
            created_to: !!listStore.filters?.created_to,
            updated_to: !!listStore.filters?.updated_to,
        },

        onSubmit: (fields) => {
            listStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        listStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.lists.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit} $scrollable>
                <ScrollableFormContainer>
                    <FloatSelect
                        formik={formik}
                        name="id"
                        mode={FieldMode.Multiple}
                        options={filtersListsStore.options}
                        placeholder={t.filters.fields.list}
                    />
                    <FloatSelect
                        formik={formik}
                        name="category"
                        options={fieldsOptionsMap.category}
                        placeholder={t.table.header.category}
                    />
                    <FloatSelect
                        formik={formik}
                        name="type"
                        mode={FieldMode.Multiple}
                        options={fieldsOptionsMap.type}
                        placeholder={t.table.header.type}
                    />
                    <FloatSelect
                        formik={formik}
                        name="is_global"
                        options={fieldsOptionsMap.group}
                        placeholder={t.lists.placeholders.group}
                    />
                    <FloatSelect
                        formik={formik}
                        name="rules_pack"
                        mode={FieldMode.Multiple}
                        options={filtersRulesPacksStore.options}
                        placeholder={t.table.header.ruleIdName}
                    />
                    <FloatSelect
                        formik={formik}
                        name="processings"
                        mode={FieldMode.Multiple}
                        options={filtersProcessingStore.options}
                        placeholder={t.table.header.processingIdName}
                    />
                    <FloatSelect
                        formik={formik}
                        name="alert"
                        mode={FieldMode.Multiple}
                        options={filtersAlertsStore.options}
                        placeholder={t.table.header.alertIdName}
                    />

                    <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                    <DatePicker
                        formik={formik}
                        maxDate={formik.values.created_to}
                        name="created_from"
                        placeholder={t.filters.fields.from}
                    />
                    <DatePicker
                        formik={formik}
                        minDate={formik.values.created_from}
                        name="created_to"
                        placeholder={t.filters.fields.to}
                        isAddMilliseconds
                    />
                    <FloatSelect
                        formik={formik}
                        name="created_by"
                        options={createdByFilterStore.options}
                        placeholder={t.filters.fields.createdBy}
                    />

                    <SubTitle>{t.filters.fields.lastUpdate}</SubTitle>
                    <DatePicker
                        formik={formik}
                        name="updated_from"
                        maxDate={formik.values.updated_to}
                        placeholder={t.filters.fields.from}
                    />
                    <DatePicker
                        formik={formik}
                        name="updated_to"
                        minDate={formik.values.updated_from}
                        placeholder={t.filters.fields.to}
                        isAddMilliseconds
                    />
                </ScrollableFormContainer>

                <ButtonStickyContainer>
                    <Button variant="primary" type="submit" isLoading={listStore.loading}>{t.filters.apply}</Button>
                </ButtonStickyContainer>
            </FormContainer>
        </>
    );
};

export default observer(ListsFilters);
