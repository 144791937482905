import request from "../request";
import { TimezonesType } from "../../types/commonFiltersType";

type GetTimezonesResponse = {
    message: string;
    response: TimezonesType[];
};

export const getTimezones = (signal?: AbortSignal) => request<GetTimezonesResponse>({
    method: "GET",
    uri: "entities/filters/timezones/",
    signal,
});
