import {
    computed, flow, makeObservable, observable,
} from "mobx";

import deleteNotifications from "../../../api/notifications/deleteNotifications";
import { getNotifications } from "../../../api/notifications/getNotifications";
import { getSelectedNotification } from "../../../api/notifications/getSelectedNotification";
import toggleNotificationsStatus, {
    ToggleNotificationResponseType,
} from "../../../api/notifications/toggleNotificationsStatus";
import { NotificationAction } from "../../../components/pages/alerts/data";
import { parseDateFromServer } from "../../../helpers";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import { NotificationsFiltersTypes, NotificationsTypes } from "../../../types/notificationsTypes";
import dataStore from "../../common/dataStore";
import { FetchSelectedItemResponseType } from "../../../types/tableStoreTypes";

class NotificationsStore extends dataStore<NotificationsTypes, NotificationsFiltersTypes, null> {
    constructor() {
        super("NotificationsStore");
        makeObservable(this);
    }

    @observable protected _countToCheck = 0;

    @computed
    public get countToCheck() {
        return this._countToCheck;
    }

    @computed
    public get hasCheckedItems() {
        return this.data.some((item) => this.checkedItems.includes(Number(item.id)) && item.is_checked);
    }

    @computed
    public get hasUncheckedItems() {
        return this.data.some((item) => this.checkedItems.includes(Number(item.id)) && !item.is_checked);
    }

    protected deleteState: FetchStatusesType = FetchStatusesType.unset;

    protected fetchDataMethod = getNotifications;

    protected fetchSelectedItemMethod = getSelectedNotification;

    fetch = flow(function* fetchData(this: NotificationsStore, signal?: AbortSignal) {
        const response = yield this.fetchWithoutSet(signal);
        if (response?.data.response.results) {
            this._data = parseDateFromServer<NotificationsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
            this._countToCheck = response.data.response.count_to_check;
        }
    });

    fetchSelectedItem = flow(function* fetchSelectedItem(
        this: NotificationsStore,
        id: number | string,
    ) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);
        if (response?.data.response) {
            this._selectedItem = (response.data as FetchSelectedItemResponseType<NotificationsTypes>)?.response;
        }
    });

    public deleteSelectedItems = flow(function* deleteSelectedItems(
        this: NotificationsStore,
    ) {
        try {
            this.deleteState = FetchStatusesType.pending;
            yield deleteNotifications(this._checkedItems.join(","));
            this._checkedItems = [];
            this.deleteState = FetchStatusesType.success;
            return FetchStatusesType.success;
        } catch (error) {
            this.deleteState = FetchStatusesType.failed;
        }
        return this.deleteState;
    });

    public toggleSelectedItemsStatus = flow(function* toggleSelectedItemsStatus(
        this: NotificationsStore,
        actionType: NotificationAction,
    ) {
        try {
            this.editItemState = FetchStatusesType.pending;

            const selectedItemId = this.selectedItem?.id;

            const notificationsIdList = selectedItemId ? [selectedItemId] : this._checkedItems;

            const response = yield toggleNotificationsStatus({ id: notificationsIdList }, actionType);

            if (selectedItemId) {
                const isNotificationChecked = (response.data as ToggleNotificationResponseType)
                    .response.details.at(0)?.[selectedItemId].is_checked;

                const changedItem = { ...this._selectedItem, is_checked: isNotificationChecked };
                this._selectedItem = changedItem as NotificationsTypes;
            } else {
                this._checkedItems = [];
            }

            this.editItemState = FetchStatusesType.success;

            return FetchStatusesType.success;
        } catch (error) {
            this.editItemState = FetchStatusesType.failed;
        }

        return this.editItemState;
    });
}

const notificationsStore = new NotificationsStore();

export default notificationsStore;
