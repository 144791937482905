import { TemplateType } from "../../../types/commonFiltersType";
import request from "../../request";

interface GetTagsResponse {
    message: string,
    response: {
        count: number,
        results: TemplateType[],
    }
}

const getTags = (signal?: AbortSignal) => request<GetTagsResponse>({
    method: "GET",
    uri: "entities/filters/tags/",
    signal,
});

export default getTags;
