import request from "../request";

interface RequestType {
    list_id: string;
    element_ids: number[];
}

export interface GetFileIdResponse {
    message: string;
    data: { response: { task_id: string } }
}

export const getListsElementsFileId = (data: RequestType, signal?: AbortSignal) => request<GetFileIdResponse>({
    method: "POST",
    uri: "elements/files/request/",
    data,
    signal,
});
