import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import Routes from "../../../../../constants/routes";
import { Tabs } from "../../../../../constants/tabs";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useTranslation from "../../../../../hooks/useTranslation";
import resultsStore from "../../../../../store/queries/results/resultsStore";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper,
    DetailsPageWrapper,
    DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import HyperlinkTextInput from "../../../../ui/textInput/HyperlinkTextInput";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";

const ResultDetails = () => {
    const { t } = useTranslation();
    const { id: resultId } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = resultsStore;

    const currentTitle = resultId ? `${t.results.text.partOfLink} №${resultId}` : "";
    const headerTitle = generateHeaderName(t.results.title, currentTitle);

    const handleNavigate = () => navigateBackward({ tab: Tabs.RESULTS });

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const preInputsParamsList = [
        { label: t.results.placeholders.id, value: selectedItem?.id, key: "result-details-id" },
        {
            label: t.results.placeholders.createdAt,
            value: selectedItem?.created,
            key: "result-details-created-at",
        },
        {
            label: t.results.placeholders.queryName,
            key: "result-details-query-name",
            isHyperLink: true,
            permission: permissionsMap.queries.view,
            hyperlinkName: selectedItem?.query_name,
            elementId: selectedItem?.query,
            sourceId: resultId,
            navigateTo: Routes.query.href,
            navigateFrom: Routes.results.href,
        },
        {
            label: t.results.placeholders.queryId,
            value: selectedItem?.query,
            key: "result-details-query-id",
        },
    ];

    useEffect(() => {
        if (resultId) resultsStore.fetchSelectedItem(resultId);

        return () => resultsStore.clearSelectedItem();
    }, [resultId]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsBodyWrapper>
                        {preInputsParamsList.map(({ isHyperLink, ...props }) => (isHyperLink
                            ? <HyperlinkTextInput {...props} /> : <PreInput {...props} />))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow
                        label={t.results.placeholders.message}
                        value={selectedItem?.message}
                        isCopyIconShown
                    />
                </DetailsSectionWrapper>
            </DetailsPageWrapper>
        </>
    );
};

export default observer(ResultDetails);
