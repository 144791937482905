import dayjs, { ManipulateType } from "dayjs";

import { FormFieldType } from "../../../../../constants/filterTypes";
import { FieldMode } from "../../../../../constants/selectFields";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersLimitsStore from "../../../../../store/filters/limitsFilterStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import { FormikTypes } from "../../../../../types/formikTypes";
import { ListsFiltersTypes } from "../../../../../types/listsTypes";
import { Row } from "../../../../detailsHeader/DetailsHeaderComponents";
import { ScrollableFormContainer, SubTitle } from "../../../../filters/FiltersModalComponents";
import SmallButton from "../../../../ui/button/SmallButton";
import DatePicker from "../../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../../ui/textInput/FloatSelect";

interface FieldListProps {
    formik: FormikTypes<ListsFiltersTypes>;
}

const buttonsConfigList = [
    { label: "Today", duration: { unit: "day", value: null } },
    { label: "24h", duration: { unit: "hour", value: 24 } },
    { label: "7d", duration: { unit: "day", value: 7 } },
    { label: "30d", duration: { unit: "day", value: 30 } },
];

const FieldsList = ({ formik }: FieldListProps) => {
    const { t } = useTranslation();

    const formFieldsList = [
        {
            key: "charts-filter-partner",
            type: FormFieldType.floatSelect,
            name: "partners",
            mode: FieldMode.Multiple,
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
        },
        {
            key: "charts-filter-service",
            type: FormFieldType.floatSelect,
            name: "services",
            mode: FieldMode.Multiple,
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
        },
        {
            key: "charts-filter-processing",
            type: FormFieldType.floatSelect,
            name: "processings",
            mode: FieldMode.Multiple,
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
        },
        { type: FormFieldType.subTitle, content: t.filters.fields.limits, key: "charts-filter-limits" },
        {
            key: "charts-filter-limit",
            type: FormFieldType.floatSelect,
            name: "limits",
            mode: FieldMode.Multiple,
            options: filtersLimitsStore.options,
            placeholder: t.filters.fields.limit,
        },
        { type: FormFieldType.subTitle, content: t.filters.fields.creationDate, key: "charts-filter-date" },
        {
            key: "charts-filter-created-from",
            type: FormFieldType.datePicker,
            name: "created_from",
            maxDate: formik.values.created_to,
            placeholder: t.filters.fields.from,
        },
        {
            key: "charts-filter-created-to",
            type: FormFieldType.datePicker,
            name: "created_to",
            minDate: formik.values.created_from,
            placeholder: t.filters.fields.to,
            isAddMilliseconds: true,
        },
    ];

    const setDateRange = (label: string) => {
        const currentDate = dayjs();

        const date = buttonsConfigList.find((config) => config.label === label);

        if (date) {
            const from = date.duration.value
                ? currentDate.subtract(date.duration.value, date.duration.unit as ManipulateType).toISOString()
                : dayjs().startOf(date.duration.unit as ManipulateType).toISOString();
            const to = currentDate.toISOString();

            formik.setFieldValue("created_from", from);
            formik.setFieldValue("created_to", to);
        }
    };

    return (
        <ScrollableFormContainer>
            {formFieldsList.map((field) => {
                const {
                    type,
                    key,
                    content,
                    name,
                    placeholder,
                    options,
                    mode,
                    maxDate,
                    minDate,
                    isAddMilliseconds,
                } = field;

                switch (type) {
                    case FormFieldType.floatSelect:
                        return (
                            <FloatSelect
                                key={key}
                                formik={formik}
                                name={name}
                                mode={mode}
                                options={options}
                                placeholder={placeholder}
                            />
                        );
                    case FormFieldType.subTitle:
                        return <SubTitle key={key}>{content}</SubTitle>;
                    case FormFieldType.datePicker:
                        return (
                            <DatePicker
                                key={key}
                                formik={formik}
                                name={name}
                                maxDate={maxDate}
                                minDate={minDate}
                                placeholder={placeholder}
                                isAddMilliseconds={isAddMilliseconds}
                            />
                        );
                    default:
                        return null;
                }
            })}

            <Row>
                {buttonsConfigList.map(({ label }) => (
                    <SmallButton
                        key={label}
                        width="100%"
                        variant="secondary"
                        type="button"
                        onClick={() => setDateRange(label)}
                    >
                        {label}
                    </SmallButton>
                ))}
            </Row>
        </ScrollableFormContainer>
    );
};

export default FieldsList;
