import { RequestParamsType } from "../../../store/common/filtersStore";
import { ElementsTypes } from "../../../types/elementsTypes";
import request from "../../request";

export interface GetServicesResponse {
    message: string,
    response: {
        count: number,
        results: ElementsTypes[],
    }
}

export const getElements = (params?: RequestParamsType, signal?: AbortSignal) => request<GetServicesResponse>({
    method: "GET",
    uri: "entities/filters/elements/",
    params,
    signal,
});
