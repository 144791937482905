import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

import { getTransactionLists } from "../../api/transactions/getTransactionLists";
import { FetchStatusesType } from "../../types/fetchStatuses";
import { ListsTypes } from "../../types/listsTypes";

interface FetchListResponseType<T> {
    message: string;
    response: T[];
}

class FiltersTransactionListsStore {
    private data: ListsTypes[] | null = null;

    private fetchState: FetchStatusesType = FetchStatusesType.unset;

    private controller = new AbortController();

    constructor() {
        makeObservable<FiltersTransactionListsStore, "data" | "fetchState">(this, {
            data: observable,
            fetchState: observable,
            options: computed,
            loading: computed,
            fetch: action,
            clear: action,
        });
    }

    public get options() {
        return this.data?.map(({
            id, name, is_global, category, type,
        }) => ({
            value: id,
            label: `${id}, ${name}`,
            is_global,
            category,
            type,
        }));
    }

    public get loading() {
        return this.fetchState === FetchStatusesType.pending;
    }

    public clear = () => {
        this.data = null;
    };

    public fetch = async (id: string | number, listType: string, signal?: AbortSignal) => {
        try {
            this.clear();

            let finalSignal = signal;
            if (!finalSignal) {
                this.controller.abort();
                this.controller = new AbortController();
                finalSignal = this.controller.signal;
            }

            this.fetchState = FetchStatusesType.pending;

            const response = await getTransactionLists(id, listType.toLowerCase(), finalSignal);

            runInAction(() => {
                this.data = (response.data as FetchListResponseType<ListsTypes>).response;

                this.fetchState = FetchStatusesType.success;
            });
        } catch (error: any) {
            runInAction(() => {
                this.fetchState = FetchStatusesType.failed;
            });
        }
    };
}

const filtersTransactionListsStore = new FiltersTransactionListsStore();

export default filtersTransactionListsStore;
