import request from "../request";
import { RoleFiltersType, RoleType } from "../../types/rolesTypes";

type GetRolesRequest = RoleFiltersType & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetRolesResponse {
    message: string,
    response: {
        count: number
        results: RoleType[];
    }
}

export const getRoles = (params?: GetRolesRequest, signal?: AbortSignal) => request<GetRolesResponse>({
    method: "GET",
    uri: "roles/",
    params,
    signal,
});
