import { useNavigate } from "react-router-dom";

import { AuthRoutes } from "../../../../../../constants/routes";
import useTranslation from "../../../../../../hooks/useTranslation";
import { ReactComponent as MailIcon } from "../../../../../../resources/icons/mailFilledIcon.svg";
import { PrimaryButton } from "../../../../../ui/button/ButtonStyles";
import { HeaderText } from "../../../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../../../CommonPagesStyles";
import { HeaderSection } from "../../../login/authTFA/AuthTFAStyles";
import {
    CheckInboxFooter,
    CheckInboxWrapper,
    IconAndHeaderSection,
    MalIconButton,
} from "./CheckInboxStyle";

const PagesCheckInbox = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleClickOnButton = () => {
        navigate(AuthRoutes.login.href);
    };
    return (
        <RightSidePageWrapper>
            <CheckInboxWrapper>
                <IconAndHeaderSection>
                    <MalIconButton>
                        <MailIcon />
                    </MalIconButton>
                    <HeaderSection>
                        <HeaderText>
                            {t.passwordRecovery.text.checkInbox}
                        </HeaderText>
                        <div>
                            {t.passwordRecovery.text.alreadySentPassword}
                        </div>
                    </HeaderSection>
                </IconAndHeaderSection>
            </CheckInboxWrapper>
            <CheckInboxFooter>
                <PrimaryButton onClick={handleClickOnButton}>
                    {t.buttons.ok}
                </PrimaryButton>
            </CheckInboxFooter>
        </RightSidePageWrapper>
    );
};

export default PagesCheckInbox;
