import { flow } from "mobx";
import dataStore from "../common/dataStore";
import { parseDateFromServer } from "../../helpers";
import { NewRuleTypes, RulesPacksFiltersTypes, RulesPacksTypes } from "../../types/rulesPacksTypes";
import getRulesPacks from "../../api/rulesPacks/getRulesPacks";
import getSelectedRulesPack from "../../api/rulesPacks/getSelectedRules";
import deactivateRulesPacks from "../../api/rulesPacks/deactivateRulesPacks";
import postRulesPack from "../../api/rulesPacks/postRulesPack";
import patchRulesPack from "../../api/rulesPacks/patchRulesPack";
import activateRulesPacks from "../../api/rulesPacks/activateRulesPacks";

class RulesPackStore extends dataStore<RulesPacksTypes, RulesPacksFiltersTypes, NewRuleTypes> {
    constructor() {
        super("RulesPackStore");
    }

    protected fetchDataMethod = getRulesPacks;

    protected fetchSelectedItemMethod = getSelectedRulesPack;

    protected deactivateSelectedItemsMethod = deactivateRulesPacks;

    protected activateSelectedItemsMethod = activateRulesPacks;

    protected postDataMethod = postRulesPack;

    protected patchDataMethod = patchRulesPack;

    fetchData = flow(function* fetchData(this: RulesPackStore, fetch?: AbortSignal) {
        const response = yield this.fetchWithoutSet(fetch);
        if (response?.data.response.results) {
            this._data = parseDateFromServer<RulesPacksTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const rulesPackStore = new RulesPackStore();

export default rulesPackStore;
