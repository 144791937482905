import { ChangeEvent, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RegExpressions } from "../../../../../constants/regex";
import {
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_EMAIL_LENGTH,
    MAX_FIELD_NAME_LENGTH,
} from "../../../../../constants/validationRules";
import { fromStringWithCommasToArray } from "../../../../../helpers";
import { FormProcessingType, NewProcessingType } from "../../../../../types/processingTypes";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import {
    Container, FormWrapper, Row, Title,
} from "./NewProcessingComponents";
import useTranslation from "../../../../../hooks/useTranslation";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import processingStore from "../../../../../store/entities/processing/processingStore";
import { removeEmptyFields } from "../../../../../helpers/removeEmptyFields";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import FloatInput from "../../../../ui/textInput/FloatInput";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";

const fieldsList: Array<keyof NewProcessingType> = [
    "name", "email", "timezone", "ip_addresses", "description",
];

const initialValues: FormProcessingType = {
    name: "",
    email: "",
    timezone: null,
    ip_addresses: "",
    description: "",
    is_active: true,
};

const PagesNewProcessing = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const handleNavigateToPrevPage = () => navigateBackward();

    const inputsParamsList = [
        {
            name: "name",
            placeholder: t.entities.processing.placeholder.name,
            isRequired: true,
            serverErrors: processingStore.postErrors,
            resetServerError: processingStore.resetPostError,
        },
        {
            name: "email",
            placeholder: t.placeholder.email,
            serverErrors: processingStore.postErrors,
            resetServerError: processingStore.resetPostError,
        },
        {
            name: "timezone",
            placeholder: t.placeholder.timezone,
            isRequired: true,
            options: filtersTimezonesStore.options,
            serverErrors: processingStore.postErrors,
            resetServerError: processingStore.resetPostError,
        },
        {
            name: "ip_addresses",
            placeholder: t.placeholder.ipAddresses,
            serverErrors: processingStore.postErrors,
            resetServerError: processingStore.resetPostError,
        },
    ];

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        email: Yup.string().email(t.validation.email)
            .max(MAX_EMAIL_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`)),
        timezone: Yup.string().required(t.validation.required),
    });

    const formik = useFormik<FormProcessingType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active");
            const fieldsData: NewProcessingType = {
                ...filteredData,
                ip_addresses: fromStringWithCommasToArray(fields.ip_addresses || ""),
            };

            await processingStore.postData(fieldsData, fieldsList);

            if (processingStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                processingStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.entities.processing.snackbar.addSuccess });
            }
        },
        validate: (values) => {
            const errors: Pick<FormProcessingType, "ip_addresses"> = {};
            const ipsList = fromStringWithCommasToArray(values.ip_addresses || "");
            ipsList.forEach((item) => {
                if (!(item.match(RegExpressions.ipv4Address) || item.match(RegExpressions.ipv6Address))) {
                    errors.ip_addresses = t.validation.ipAddress;
                }
            });
            return errors;
        },
    });

    const togglePartnerStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, processingStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        filtersTimezonesStore.fetch(signal);
    }, [], processingStore.resetPostErrors);

    return (
        <>
            <SecondHeader title={t.entities.processing.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.entities.processing.text.title}</Title>
                    <Row>
                        <Title>{t.partners.text.status}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={processingStore.loading}
                            checked={formik.values.is_active}
                            onChange={togglePartnerStatus}
                        />
                    </Row>
                </Row>

                <FormWrapper onSubmit={handleSubmit}>
                    {inputsParamsList.map((input) => {
                        const {
                            options, name, isRequired, placeholder, serverErrors, resetServerError,
                        } = input;

                        return (
                            options
                                ? (
                                    <FloatSelect
                                        key={name}
                                        disabled={processingStore.loading}
                                        formik={formik}
                                        isRequired={isRequired}
                                        placeholder={placeholder}
                                        name={name}
                                        options={options}
                                        serverErrors={serverErrors}
                                        resetServerError={resetServerError}
                                    />
                                ) : (
                                    <FloatInput
                                        key={name}
                                        disabled={processingStore.loading}
                                        formik={formik}
                                        name={name}
                                        isRequired={isRequired}
                                        placeholder={placeholder}
                                        serverErrors={serverErrors}
                                        resetServerError={resetServerError}
                                    />
                                )
                        );
                    })}
                    <FloatTextArea
                        formik={formik}
                        disabled={processingStore.loading}
                        name="description"
                        placeholder={t.placeholder.description}
                        serverErrors={processingStore.postErrors}
                        resetServerError={processingStore.resetPostError}
                        autoSize
                        maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    />
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={processingStore.loading}
                        disabled={processingStore.loading}
                    >
                        {t.buttons.add}
                    </Button>
                </FormWrapper>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(PagesNewProcessing);
