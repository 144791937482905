import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import getTransactionsStatistic from "../../../api/home/getTransactionsStatistic";
import { getCommonError } from "../../../helpers/apiHelper";
import { formatListDataDate } from "../../../helpers/statisticsHelper";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import {
    AfStatisticTypes,
    CoreStatisticTypes,
    PeriodicStatisticTypes,
    StatisticFiltersTypes,
    StatisticTypes,
} from "../../../types/transactionsStatisticTypes";
import dataStore from "../../common/dataStore";

interface ResponseTypes {
    data: StatisticTypes;
}

class TransactionsStore extends dataStore<PeriodicStatisticTypes, StatisticFiltersTypes, null> {
    @observable private _periodicStatistic: PeriodicStatisticTypes[] = [];

    @observable private _coreStatistic: CoreStatisticTypes | null = null;

    @observable private _afStatistic: AfStatisticTypes | null = null;

    constructor() {
        super("TransactionsStore");
        makeObservable(this);
    }

    @computed
    public get periodicStatistic() {
        return this._periodicStatistic;
    }

    @computed
    public get coreStatistic() {
        return this._coreStatistic;
    }

    @computed
    public get afStatisticInfo() {
        return this._afStatistic;
    }

    @action
    public resetData() {
        this._periodicStatistic = [];
        this._coreStatistic = null;
        this._afStatistic = null;
    }

    @action
    private setChartsData(data: ResponseTypes["data"]["response"]) {
        this._periodicStatistic = formatListDataDate(data.periodic, data.step);
        this._coreStatistic = data.core;
        this._afStatistic = data.af;
    }

    public fetchData = flow(function* fetchData(this: TransactionsStore) {
        try {
            if (this._filters) {
                this.fetchState = FetchStatusesType.pending;

                const filtersData = this.parseFilters(this._filters);

                const response: ResponseTypes = yield getTransactionsStatistic(filtersData);

                this.setChartsData(response.data.response);
                this.fetchState = FetchStatusesType.success;
                this._fetchErrors = null;
            }
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
            this._fetchErrors = getCommonError(error);
        }
    });
}

const transactionsStore = new TransactionsStore();

export default transactionsStore;
