import { flow } from "mobx";

import deactivateLists from "../../api/lists/deactivateLists";
import { getLists } from "../../api/lists/getLists";
import { getSelectedList } from "../../api/lists/getSelectedList";
import patchList from "../../api/lists/patchList";
import postCloneList from "../../api/lists/postCloneList";
import { postLists } from "../../api/lists/postLists";
import { parseDateFromServer } from "../../helpers";
import {
    ListsFiltersTypes, ListsTypes, NewListType,
} from "../../types/listsTypes";
import dataStore from "../common/dataStore";
import activateLists from "../../api/lists/activateLists";

class ListStore extends dataStore<ListsTypes, ListsFiltersTypes, NewListType> {
    constructor() {
        super("ListsStore");
    }

    protected fetchDataMethod = getLists;

    protected fetchSelectedItemMethod = getSelectedList;

    protected deactivateSelectedItemsMethod = deactivateLists;

    protected activateSelectedItemsMethod = activateLists;

    protected postDataMethod = postLists;

    protected patchDataMethod = patchList;

    protected cloneDataMethod = postCloneList;

    fetchSelectedItem = flow(function* fetchSelectedItem(this: ListStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);

        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<ListsTypes>(
                [response.data.response],
                "created",
                "last_update",
            );
        }
    });

    fetchData = flow(function* fetchData(this: ListStore, signal?: AbortSignal) {
        const response = yield this.fetchWithoutSet(signal);

        if (response?.data.response.results) {
            this._data = parseDateFromServer<ListsTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });

    patchSelectedItem = flow(
        function* patchData(this: ListStore, data: Partial<ListsTypes>, id: number | string, fieldsList: string[]) {
            const response = yield this.patchDataWithoutSet(data, id, fieldsList);

            if (response?.data.response) {
                [this._selectedItem] = parseDateFromServer<ListsTypes>(
                    [response.data.response],
                    "created",
                    "last_update",
                );
            }
        },
    );
}

const listStore = new ListStore();

export default listStore;
