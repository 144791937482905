import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import * as Yup from "yup";

import useTranslation from "../../../../../hooks/useTranslation";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import accountStore from "../../../../../store/user/account/accountStore";
import { EditUserType } from "../../../../../types/userType";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";

const EditPersonalInfoModal = ({
    title,
    buttonText,
    isOpen,
    setOn,
}: ModalProps) => {
    const { t } = useTranslation();
    const { userProfile, editUserProfileErrors, resetEditError } = accountStore;
    const timezones = filtersTimezonesStore.options;

    const formik = useFormik<EditUserType>({
        initialValues: { ...userProfile },
        enableReinitialize: true,

        validationSchema: Yup.object({
            username: Yup.string().required(t.validation.required),
            timezone: Yup.string().required(t.validation.required),
            info: Yup.string().nullable(),
        }),

        onSubmit: async (values) => {
            if (userProfile) {
                await accountStore.editUserProfile(values);

                if (accountStore.isEditProfileSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    setOn(false);
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            }
        },
    });
    const handleCloseModal = () => {
        accountStore.unsetEditState();
        formik.resetForm();
    };

    useEffect(() => {
        if (isOpen && !timezones?.length) filtersTimezonesStore.fetch();

        return () => handleCloseModal();
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={buttonText}
            isLoading={accountStore.loading}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
            isForm
        >
            <FloatInput
                formik={formik}
                disabled={accountStore.loading}
                name="username"
                isRequired
                placeholder={t.table.header.name}
                serverErrors={editUserProfileErrors}
                resetServerError={resetEditError}
            />
            <FloatSelect
                formik={formik}
                disabled={accountStore.loading}
                name="timezone"
                options={timezones}
                isRequired
                placeholder={t.table.header.timezone}
                serverErrors={editUserProfileErrors}
                resetServerError={resetEditError}
            />
            <FloatInput
                formik={formik}
                disabled={accountStore.loading}
                name="info"
                placeholder={t.table.header.info}
                serverErrors={editUserProfileErrors}
                resetServerError={resetEditError}
            />
        </Modal>
    );
};

export default observer(EditPersonalInfoModal);
