import request from "../request";
import { LimitsFiltersTypes, LimitsTypes } from "../../types/limitsTypes";

type GetLimitsRequest = LimitsFiltersTypes & {
    rules_pack?: number | null
    limit?: number
    offset?: number
    ordering?: string
}

interface GetLimitsResponse {
    message: string,
    response: {
        count: number
        results: LimitsTypes[];
    }
}

export const getLimits = (params?: GetLimitsRequest, signal?: AbortSignal) => request<GetLimitsResponse>({
    method: "GET",
    uri: "limits/",
    params,
    signal,
});
