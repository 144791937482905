import { flow } from "mobx";

import FiltersDataStore from "../common/filtersStore";
import { ElementsTypes } from "../../types/elementsTypes";
import { getElements } from "../../api/elements/filters/getElements";

class ListsElementsFiltersStore extends FiltersDataStore<ElementsTypes> {
    protected fetchListMethod = getElements;

    protected fetchDataByIdMethod = getElements;

    public fetchById = flow(function* fetchData(this: ListsElementsFiltersStore, id: number) {
        yield this.fetchDataById({ list: id });
    });

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersListsElementsStore = new ListsElementsFiltersStore();

export default filtersListsElementsStore;
