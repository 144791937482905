import { Segmented } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../../styles/variables";

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
`;

const UL = styled.ul`
    display: flex;
    gap: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const LI = styled.li`
    display: flex;
    align-items: baseline;
    gap: 5px;
`;

const Indicator = styled.div<{ $color?: string }>`
    width: 10px;
    height: 10px;
    border-radius: 40px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: ${(props) => props.$color};
`;

const Title = styled.span`
    color: ${colors.activeText};
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
`;

const Segment = styled(Segmented)`
    color: ${colors.activeText};
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    padding: 4px;

    &&.ant-segmented .ant-segmented-group {
        gap: 5px;
        
        .ant-segmented-item-selected {
            color: ${colors.buttonPrimary};
            background-color: ${colors.buttonDefaultBackground};
        }

        .ant-segmented-thumb {
            background-color: ${colors.buttonDefaultBackground};
        }

        .ant-segmented-item {
            height: 24px;
            width: 43px;

            &:hover {
                background-color: ${colors.buttonDefaultBackground};
                border-radius: 4px;
            }
        }
    }
`;

export {
    Box,
    UL,
    LI,
    Title,
    Indicator,
    Segment,
};
