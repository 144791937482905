import { observer } from "mobx-react-lite";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import limitsCreatedByStore from "../../../store/filters/createdByFilterStore";
import limitsFilterStore from "../../../store/filters/limitsFilterStore";
import rulesPacksFiltersStore from "../../../store/filters/rulesPacksFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedLimitsTable from "./ProtectedLimitsTable";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";

const PagesLimits = () => {
    const { t } = useTranslation();
    const { userPermissions } = authStore;
    const headerTitle = generateHeaderName(t.limits.title);

    const isLimitsTableCheckboxVisible = userPermissions.includes(permissionsMap.limits.changeStatus);

    const {
        FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    } = useTabsActions();

    useFetchWithAbort((signal: AbortSignal) => {
        limitsFilterStore.fetch(signal);
        limitsCreatedByStore.fetch(signal);
        rulesPacksFiltersStore.fetch(signal);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedLimitsTable
                isCheckboxVisible={isLimitsTableCheckboxVisible}
                permission={permissionsMap.limits.view}
            />
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesLimits);
