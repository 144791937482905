import useTranslation from "../../../../hooks/useTranslation";
import limitsStore from "../../../../store/home/limits/limitsStore";
import TabHeader from "../../../tabHeader/TabHeader";
import EmptyView from "../../../ui/emptyView/EmptyView";
import Chart from "../components/Chart/Chart";

const LimitsCharts = () => {
    const { t } = useTranslation();

    return (
        limitsStore.limitsStatistic.length ? (
            <>
                <TabHeader title={t.home.chartsTitle.limits} />

                <Chart
                    data={limitsStore.limitsStatistic}
                    lines={limitsStore.limitsChartConfigList}
                />
            </>
        ) : (
            <EmptyView
                title={t.home.emptyView.title}
                text={t.home.emptyView.basic.text}
            />
        )
    );
};

export default LimitsCharts;
