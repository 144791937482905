import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { togglePageScroll } from "../../../helpers";
import { ReactComponent as CloseCrossIcon } from "../../../resources/icons/closeCrossIcon.svg";
import Button from "../button/Button";
import { HeaderText } from "../TextStyle";
import {
    CloseIcon,
    HeaderSection,
    ModalBody,
    ModalBox,
    ModalOverlay,
    Position,
} from "./modalStyle";
import ReactPortal from "./ReactPortal";

export interface ModalProps {
    submitButton?: boolean;
    title?: string | ReactNode;
    buttonText?: string;
    isOpen: boolean,
    modalBodyText?: ReactNode;
    position?: Position;
    scrollable?: boolean;
    gap?: string;
    iconURL?: string;
    children?: ReactNode;
    navigateTo?: string;
    buttonDisabled?: boolean;
    isLoading?: boolean;
    isForm?: boolean;
    isFilter?: boolean;
    hasChildModal?: boolean;
    blockOverflow?: boolean;
    width?: string,
    setOn: Dispatch<SetStateAction<boolean>>,
    handleSubmit?: () => void;
    handleClose?: () => void;
}

const Modal = ({
    isOpen,
    submitButton = true,
    title,
    buttonText,
    modalBodyText,
    position = "center",
    scrollable,
    gap,
    iconURL,
    children,
    width,
    navigateTo,
    buttonDisabled = false,
    isLoading = false,
    isForm = false,
    isFilter = false,
    hasChildModal = false,
    blockOverflow = true,
    setOn,
    handleSubmit,
    handleClose,
}: ModalProps) => {
    const navigate = useNavigate();

    const handleCloseModal = () => {
        handleClose?.();
        if (navigateTo) {
            navigate(navigateTo);
        }
        if (hasChildModal) {
            return;
        }
        setOn(false);
    };

    const handleSubmitModal = () => {
        handleSubmit?.();
    };

    useEffect(() => {
        if (blockOverflow) {
            togglePageScroll(isOpen);
        }
        return undefined;
    }, [isOpen]);

    return (
        isOpen
            ? (
                <ReactPortal wrapperId="react-portal-modal-container">
                    <ModalOverlay onClick={handleCloseModal} />

                    <ModalBox
                        className="modal"
                        $position={position}
                        $scrollable={scrollable}
                        $width={width}
                        $isFilter={isFilter}
                    >
                        <CloseIcon onClick={handleCloseModal} $position={position}>
                            <CloseCrossIcon />
                        </CloseIcon>

                        <ModalBody $position={position} $gap={gap}>
                            <HeaderSection>
                                {
                                    iconURL && <img src={iconURL} width="56" height="56" alt="icon" />
                                }
                                <HeaderText>{title}</HeaderText>
                                {modalBodyText && <div>{modalBodyText}</div>}
                            </HeaderSection>

                            {children}

                            {submitButton && (
                                <Button
                                    variant="primary"
                                    isLoading={isLoading ?? buttonDisabled}
                                    disabled={buttonDisabled || isLoading}
                                    onClick={() => (isForm ? handleSubmitModal() : handleCloseModal())}
                                    type={isForm ? "submit" : "button"}
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </ModalBody>
                    </ModalBox>
                </ReactPortal>
            ) : null
    );
};
export default Modal;
