import { AlertsFiltersTypes, AlertsTypes } from "../../types/alertsTypes";
import request from "../request";

type GetAlertsRequest = AlertsFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetAlertsResponse {
    message: string,
    response: {
        count: number
        results: AlertsTypes[];
    }
}

export const getAlerts = (params?: GetAlertsRequest, signal?: AbortSignal) => request<GetAlertsResponse>({
    method: "GET",
    uri: "alerts/",
    params,
    signal,
});
