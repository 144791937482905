import { Select } from "antd";
import { SelectComponent } from "./SelectComponents";

type OptionType = {
    value: string | number;
    label: string;
};

type BasicSelectProps = {
    options?: OptionType[];
    defaultValue?: string | number;
    onChange?: (value: string | number) => void;
};

const BasicSelect = ({ options = [], defaultValue, onChange }: BasicSelectProps) => (
    <SelectComponent defaultValue={defaultValue} onChange={onChange}>
        {options.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
                {label}
            </Select.Option>
        ))}
    </SelectComponent>
);

export default BasicSelect;
