import { Space } from "antd";
import FloatInput from "./FloatInput";
import { FormikTypes } from "../../../types/formikTypes";
import useTranslation from "../../../hooks/useTranslation";

interface FloatInputCompactProps {
    range?: {
        from: string;
        to: string;
    };
    formik: FormikTypes;
}

const FloatRangeInput = ({ formik, range }: FloatInputCompactProps) => {
    const { t } = useTranslation();

    return (
        <Space.Compact>
            <FloatInput
                formik={formik}
                name={range?.from}
                placeholder={t.filters.fields.from}
                style={{ borderRightWidth: 0 }}
            />
            <FloatInput
                formik={formik}
                name={range?.to}
                placeholder={t.filters.fields.to}
            />
        </Space.Compact>
    );
};

export default FloatRangeInput;
