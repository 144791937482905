import request from "../../request";
import { LimitsTypes } from "../../../types/limitsTypes";

interface GetLimitsResponse {
    message: string,
    response: {
        count: number
        results: LimitsTypes[];
    }
}

export const getLimits = (signal?: AbortSignal) => request<GetLimitsResponse>({
    method: "GET",
    uri: "entities/filters/limits/",
    signal,
});
