/* eslint-disable prefer-const */
import type { DatePickerProps as AntDDatePickerProps } from "antd";
import {
    FocusEvent,
    useEffect,
    useMemo,
    useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePickerComponent, DatePickerContainer } from "./DatePickerComponents";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { FormikTypes } from "../../../types/formikTypes";
import { DATE_BACK_FORMAT, DATE_FORMAT, DATE_INPUT_FORMAT } from "../../../constants/dates";

dayjs.extend(utc);

export type DatePickerProps = Omit<AntDDatePickerProps, "value" | "onOk" | "maxDate" | "minDate"> & {
    value?: string | Dayjs;
    formik?: FormikTypes;
    error?: boolean;
    serverErrors?: Record<string, string>;
    addonBeforeText?: string;
    maxDate?: Dayjs | string;
    minDate?: Dayjs | string;
    isNowButtonShown?: boolean;
    isAddMilliseconds?: boolean;
    onChange?: (date: string | string[]) => void;
    resetServerError?: (field: string) => void;
}

const DatePicker = ({
    formik,
    serverErrors,
    isAddMilliseconds,
    minDate,
    maxDate,
    disabled,
    onChange,
    resetServerError,
    ...props
}: DatePickerProps) => {
    const name = props.name || "";
    const [error, setError] = useState<string | false>(false);

    const formattedValue = useMemo(() => {
        const formikValue = formik?.values[name];

        return formikValue
            ? dayjs(formikValue, [DATE_FORMAT, DATE_BACK_FORMAT, DATE_INPUT_FORMAT])
            : null;
    }, [formik]);

    const formattedMinDate = useMemo(() => (
        minDate ? dayjs(minDate, DATE_BACK_FORMAT) : undefined
    ), [minDate]);

    const formattedMaxDate = useMemo(() => (
        maxDate ? dayjs(maxDate, DATE_BACK_FORMAT) : undefined
    ), [maxDate]);

    const handleChange = (_: unknown, date: string | string[]) => {
        if (typeof date !== "string") return;

        const parsedDayjsDate = date
            ? dayjs(date, [DATE_FORMAT, DATE_INPUT_FORMAT, DATE_BACK_FORMAT])
                .add(isAddMilliseconds ? 999 : 0, "millisecond")
                .format(DATE_BACK_FORMAT)
            : "";

        formik?.setFieldTouched(name, true);
        formik?.setFieldValue(name, parsedDayjsDate);
        resetServerError?.(name);
        onChange?.(parsedDayjsDate);
    };

    const handleBlur = (event: FocusEvent<HTMLElement, Element>) => {
        formik?.handleBlur(event as FocusEvent<HTMLInputElement>);
    };

    // TODO: check if it's possible to use useMemo()
    // instead of useEffect() as it was implemented above
    useEffect(() => {
        if (formik) {
            setError(serverErrors?.[name] || (formik?.touched[name] && formik.errors[name]));
        }
    }, [formik]);

    return (
        <DatePickerContainer className="date-picker-container">
            <DatePickerComponent
                {...props}
                showTime
                value={formattedValue}
                minDate={formattedMinDate}
                maxDate={formattedMaxDate}
                $isError={!!error}
                $isDisabled={!!disabled}
                onBlurCapture={handleBlur}
                onChange={handleChange}
                format={{
                    format: DATE_INPUT_FORMAT,
                    type: "mask",
                }}
            />
            {!!error && ErrorLabel(error || "")}
        </DatePickerContainer>
    );
};

export default DatePicker;
