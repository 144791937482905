import type { Payload } from "recharts/types/component/DefaultLegendContent";
import { ReactElement } from "react";
import {
    Box,
    Indicator,
    LI,
    UL,
    Title,
} from "./ChartLegendComponents";
import { generateUniqueId } from "../../../../../../helpers";

interface ChartLegendProps {
    payload?: Payload[];
    ChartSettings?: ReactElement;
}

const ChartLegend = ({ payload, ChartSettings }: ChartLegendProps) => (
    <Box>
        <UL>
            {payload && payload.map(({ value, inactive, color }) => (
                inactive ? null : (
                    <LI key={generateUniqueId()}>
                        <Indicator $color={color} />
                        <Title>{value}</Title>
                    </LI>
                )
            ))}
        </UL>
        {ChartSettings}
    </Box>
);

export default ChartLegend;
