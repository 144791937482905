const MAX_PHONE_LENGTH = 128;
const MAX_FIELD_USER_NAME_LENGTH = 150;
const MAX_FIELD_NAME_LENGTH = 250;
const MAX_CHAT_LINK_LENGTH = 250;
const MAX_CHAT_ID_LENGTH = 250;
const MAX_THREAD_ID_LENGTH = 250;
const MAX_EMAIL_LENGTH = 254;
const MAX_TRIGGER_FILED_LENGTH = 255;
const MAX_DESCRIPTION_FILED_LENGTH = 256;
const MAX_SITE_URL_LENGTH = 1024;

export {
    MAX_PHONE_LENGTH,
    MAX_FIELD_USER_NAME_LENGTH,
    MAX_FIELD_NAME_LENGTH,
    MAX_CHAT_LINK_LENGTH,
    MAX_CHAT_ID_LENGTH,
    MAX_THREAD_ID_LENGTH,
    MAX_EMAIL_LENGTH,
    MAX_TRIGGER_FILED_LENGTH,
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_SITE_URL_LENGTH,
};
