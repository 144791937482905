import { flow } from "mobx";
import { AxiosError } from "axios";
import dataStore from "../../common/dataStore";
import { parseDateFromServer } from "../../../helpers";
import { EmployeesFiltersTypes, EmployeesTypes, NewEmployeeType } from "../../../types/employeesTypes";
import { getEmployees } from "../../../api/employees/getEmployees";
import { getSelectedEmployee } from "../../../api/employees/getSelectedEmployee";
import deactivateEmployees from "../../../api/employees/deactivateEmployees";
import { postEmployees } from "../../../api/employees/postEmployees";
import patchEmployees from "../../../api/employees/patchEmployees";
import activateEmployees from "../../../api/employees/activateEmployees";
import { resendMail } from "../../../api/employees/resendMail";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import { getFieldsErrors } from "../../../helpers/apiHelper";

class EmployeeStore extends dataStore<EmployeesTypes, EmployeesFiltersTypes, NewEmployeeType> {
    constructor() {
        super("EmployeeStore");
    }

    protected fetchDataMethod = getEmployees;

    protected fetchSelectedItemMethod = getSelectedEmployee;

    protected deactivateSelectedItemsMethod = deactivateEmployees;

    protected activateSelectedItemsMethod = activateEmployees;

    protected postDataMethod = postEmployees;

    protected patchDataMethod = patchEmployees;

    fetchSelectedItem = flow(function* fetchSelectedItem(
        this: EmployeeStore,
        id: number | string,
    ) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);
        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<EmployeesTypes>(
                [response.data.response],
                "created_at",
            );
        }
    });

    fetchData = flow(function* fetchData(this: EmployeeStore, signal?: AbortSignal) {
        const response = yield this.fetchWithoutSet(signal);
        if (response?.data.response.results) {
            this._data = parseDateFromServer<EmployeesTypes>(
                response.data.response.results,
                "created_at",
            );
            this._pagination.count = response.data.response.count;
        }
    });

    resendEmail = flow(function* resendEmail(this: EmployeeStore, email: string) {
        try {
            this.postState = FetchStatusesType.pending;
            yield resendMail({ email });
            this.postState = FetchStatusesType.success;
        } catch (error) {
            const axiosError = error as AxiosError;
            this._postErrors = getFieldsErrors(axiosError, ["email"]);
            this.postState = FetchStatusesType.failed;
        }
    });
}

const employeeStore = new EmployeeStore();

export default employeeStore;
