import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import useTranslation from "../../../../../hooks/useTranslation";
import rulesPackLimitsStore from "../../../../../store/rulesPack/rulesPackLimitsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { RulesPacksLimitsType } from "../../../../../types/limitsTypes";
import BlockModal from "../../../../common/modal/warning/blockModal/BlockModal";
import Button from "../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { Icon } from "../../../../ui/new/NewComponents";
import { ModalButtonRow } from "../../../transactions/details/components/sections/SectionsComponents";
import RulesPacksMoveTable from "../RulesPacksMoveTable/RulesPacksMoveTable";

const RulesPacksMoveModal = ({ setOn, isOpen }: ModalProps) => {
    const { t } = useTranslation();

    const [limits, setLimits] = useState<RulesPacksLimitsType[]>([]);
    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);

    const toggleWarningModal = () => {
        setWarningModalVisibility(!isWarningModalVisible);
    };

    const sortedStoreLimits = [...rulesPackLimitsStore.orderingLimits].sort((a, b) => a.order - b.order);

    const isTableChanged = limits.some((limit, index) => (
        limit.id !== sortedStoreLimits[index].id));

    const changeLimitsOrdering = () => {
        rulesPackLimitsStore.changeOrdering(limits, "167").then(() => {
            if (!rulesPackLimitsStore.postErrors?.common) {
                snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
            } else {
                snackbarStore.add({ variant: "error", text: t.snackbar.editError });
            }
            setOn(false);
        });
    };

    const closeModal = () => {
        if (isTableChanged) {
            toggleWarningModal();
        } else {
            setOn(false);
        }
    };

    const confirmExit = () => {
        setWarningModalVisibility(false);
        setOn(false);
    };

    const changeLimitsOrder = (currentLimits: RulesPacksLimitsType[]) => {
        const newLimits = currentLimits.map((limit, index) => ({ ...limit, order: index + 1 }));
        setLimits(newLimits);
    };

    useEffect(() => {
        if (isOpen) rulesPackLimitsStore.fetchAllData(167);
    }, [isOpen]);

    useEffect(() => {
        setLimits(sortedStoreLimits);
    }, [rulesPackLimitsStore.orderingLimits]);

    return (
        <>
            <Modal
                gap="0"
                width="984px"
                title={t.rulesPacks.text.moveLimit}
                isOpen={isOpen}
                submitButton={false}
                setOn={setOn}
                handleClose={closeModal}
                hasChildModal
                scrollable
            >
                <RulesPacksMoveTable data={limits} setData={changeLimitsOrder} />

                <ModalButtonRow $marginTop="auto">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setOn(false)}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={rulesPackLimitsStore.loading}
                        disabled={!isTableChanged || rulesPackLimitsStore.loading}
                        onClick={changeLimitsOrdering}
                    >
                        {t.buttons.save}
                    </Button>
                </ModalButtonRow>
            </Modal>

            <BlockModal
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={confirmExit}
                blockOverflow={false}
            />
        </>
    );
};

export default observer(RulesPacksMoveModal);
