import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_USER_NAME_LENGTH } from "../../../../../constants/validationRules";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import permissionsStore from "../../../../../store/permissions/permissionsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import rolesStore from "../../../../../store/user/roles/roleStore";
import { PermissionsTablePropsType } from "../../../../../types/permissionsType";
import { NewRoleType } from "../../../../../types/rolesTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    Container, Spacer, ThreeColumnForm, Title,
} from "../../../../ui/new/NewComponents";
import FloatInput from "../../../../ui/textInput/FloatInput";
import PermissionsTableEditable from "./Table/PermissionsTableEditable";
import { Row } from "./Table/TableComponents";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useFetchWithAbort from "../../../../../hooks/useFetchWithAbort";

const fieldsList: Array<keyof NewRoleType> = ["name", "description"];

const permissionTablePropsList: PermissionsTablePropsType[] = [
    { variant: "add", key: "role-permission-table-add" },
    { variant: "delete", key: "role-permission-table-delete" },
];

const initialValues: NewRoleType = {
    name: "",
    description: "",
};

const NewRole = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const handleNavigateToPrevPage = () => navigateBackward();

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_USER_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_USER_NAME_LENGTH}`),
            ),
        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    });

    const formik = useFormik<NewRoleType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const permissionsIdsList = permissionsStore.addedItems.map((permission) => permission.id);
            const fieldsData = permissionsIdsList.length ? { ...fields, permissions: permissionsIdsList } : fields;

            await rolesStore.postData(fieldsData, fieldsList);

            if (rolesStore.isPostRequestSuccess) {
                rolesStore.resetPostItemState();
                handleNavigateToPrevPage();
                snackbarStore.add({ variant: "success", text: t.roles.snackbar.addSuccess });
            } else if (rolesStore.postErrors?.common) {
                snackbarStore.add({ variant: "error", text: t.roles.snackbar.addError });
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, rolesStore.isPostRequestSuccess);

    useFetchWithAbort((signal: AbortSignal) => {
        permissionsStore.fetch(signal);
    }, [], () => {
        rolesStore.resetPostErrors();
        permissionsStore.clearAddedItems();
    });

    return (
        <>
            <SecondHeader title={t.roles.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Title>{t.roles.text.title}</Title>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={rolesStore.loading}
                        name="name"
                        isRequired
                        placeholder={t.roles.placeholders.name}
                        serverErrors={rolesStore.postErrors}
                        resetServerError={rolesStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        disabled={rolesStore.loading}
                        name="description"
                        placeholder={t.roles.placeholders.description}
                        serverErrors={rolesStore.postErrors}
                        resetServerError={rolesStore.resetPostError}
                    />

                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={rolesStore.loading}
                        disabled={rolesStore.loading}
                    >
                        {t.buttons.add}
                    </Button>
                </ThreeColumnForm>
            </Container>

            <Spacer />

            <Container>
                <Title>{t.roles.text.permissions}</Title>
            </Container>

            <Spacer />

            <Row>
                {permissionTablePropsList.map((permission) => {
                    const { variant, key } = permission;

                    return <PermissionsTableEditable key={key} variant={variant} />;
                })}
            </Row>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewRole);
