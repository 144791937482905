import { useEffect } from "react";

const useFetchWithAbort = (
    fetchRequest: (signal: AbortSignal) => void,
    deps: unknown[] = [],
    clearFunction: (() => void) | null = null,
): void => {
    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        fetchRequest(signal);

        return () => {
            clearFunction?.();
            controller.abort();
        };
    }, deps);
};

export default useFetchWithAbort;
