import { LimitsPeriodicStatisticTypes } from "../../types/limitsStatisticTypes";
import { StatisticFiltersTypes } from "../../types/transactionsStatisticTypes";
import request from "../request";

interface GetStatisticResponse {
    message: string,
    response: {
        periodic: LimitsPeriodicStatisticTypes[];
    }
}

const getLimitsStatistic = (params: StatisticFiltersTypes, signal?: AbortSignal) => request<GetStatisticResponse>({
    method: "GET",
    uri: "home/limits/",
    params,
    signal,
});

export default getLimitsStatistic;
