import { colors } from "../styles/variables";

const transactionsPercentsLinesConfigList = [
    {
        key: "af-approve",
        name: "AF approved",
        dataKey: "af_approve_percent",
        stroke: colors.successBackground,
        strokeDasharray: "8 8",
    },
    {
        key: "af-decline",
        name: "AF decline",
        dataKey: "af_decline_percent",
        stroke: colors.error,
        strokeDasharray: "8 8",
    },
    {
        key: "core-success",
        name: "Core success",
        dataKey: "core_success_percent",
        stroke: colors.successBackground,
    },
    {
        key: "core-decline",
        name: "Core decline",
        dataKey: "core_decline_percent",
        stroke: colors.error,
    },
];

const transactionsValuesLinesConfigList = [
    {
        key: "af-all",
        name: "AF",
        dataKey: "af_all",
        stroke: colors.textPrimary,
        strokeDasharray: "8 8",
    },
    {
        key: "af-approve",
        name: "AF approved",
        dataKey: "af_approve",
        stroke: colors.successBackground,
        strokeDasharray: "8 8",
    },
    {
        key: "af-decline",
        name: "AF decline",
        dataKey: "af_decline",
        stroke: colors.error,
        strokeDasharray: "8 8",
    },
    {
        key: "core-all",
        name: "Core",
        dataKey: "core_all",
        stroke: colors.textPrimary,
    },
    {
        key: "core-success",
        name: "Core success",
        dataKey: "core_success",
        stroke: colors.successBackground,
    },
    {
        key: "core-decline",
        name: "Core decline",
        dataKey: "core_decline",
        stroke: colors.error,
    },
];

export {
    transactionsPercentsLinesConfigList,
    transactionsValuesLinesConfigList,
};
