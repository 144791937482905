import { ItemType } from "antd/es/menu/hooks/useItems";

import { permissionsMap } from "../../../../../../constants/permissions";
import { ReactComponent as ArrangeSquareIcon } from "../../../../../../resources/icons/arrangeSquare.svg";
import { ReactComponent as CloseCircleIcon } from "../../../../../../resources/icons/closeCircleIcon.svg";
import { ReactComponent as TickCircleIcon } from "../../../../../../resources/icons/tickCircleIcon.svg";
import { colors } from "../../../../../../styles/variables";
import { ActionModalType } from "../../../../../../types/actionModalTypes";
import MenuDropdownItem from "../../../../../ui/menuDropdown/MenuDropdownItem";

const MIN_LIMITS_SORT_QUANTITY = 2;

export const getRulesPackLimitsMenuItems = (
    store: any,
    userPermissions: string[],
    translate: (text: string) => string,
    toggleMoveModal: () => void,
    toggleActionModal: (type: ActionModalType) => void,
): ItemType[] => {
    const itemsPropsList = [
        {
            key: "limits-move-item",
            isItemShown: true,
            label: <MenuDropdownItem
                icon={<ArrangeSquareIcon width="16px" height="16px" />}
                title={translate("rulesPacks.details.moveLimit")}
            />,
            disabled: store.data.length < MIN_LIMITS_SORT_QUANTITY,
            onClick: toggleMoveModal,
        },
        {
            key: "activate-item",
            label: <MenuDropdownItem
                icon={<TickCircleIcon width="16px" height="16px" />}
                title={translate("buttons.activate")}
            />,
            disabled: !store.hasDisabledCheckedItems,
            permission: permissionsMap.limits.changeStatus,
            onClick: () => toggleActionModal("activate"),
        },
        {
            key: "deactivate-item",
            label: <MenuDropdownItem
                icon={<CloseCircleIcon fill={colors.error} width="16px" height="16px" />}
                title={translate("buttons.deactivate")}
            />,
            disabled: !store.hasActiveCheckedItems,
            permission: permissionsMap.limits.changeStatus,
            onClick: () => toggleActionModal("deactivate"),
        },
    ];

    return itemsPropsList.filter(({ permission, isItemShown }) => (
        userPermissions.includes(permission ?? "") || isItemShown));
};
