import request from "../request";
import { ListsTypes } from "../../types/listsTypes";

interface ResponseType {
    message: string,
    response: ListsTypes,
}

export const getSelectedList = (listId: string, signal?: AbortSignal) => request<ResponseType>({
    method: "GET",
    uri: `lists/${listId}/`,
    signal,
});
