import { observer } from "mobx-react-lite";

import useTranslation from "../../../../../hooks/useTranslation";
import { ActionModalType } from "../../../../../types/actionModalTypes";
import MenuDropdown from "../../../../ui/menuDropdown/MenuDropdown";
import { getRulesPacksMenuItems } from "./rulesPacksMenuHelpers";

interface RulesPacksMenuMenuProps {
    store: any;
    userPermissions: string[]
    toggleActionModal: (type?: ActionModalType) => void;
    toggleMoveModal: () => void,
}

const RulesPacksMenu = observer(({
    store,
    userPermissions,
    toggleActionModal,
    toggleMoveModal,
}: RulesPacksMenuMenuProps) => {
    const { translate } = useTranslation();

    const menuDropdownItemsList = getRulesPacksMenuItems(
        store,
        userPermissions,
        translate,
        toggleActionModal,
        toggleMoveModal,
    );

    return <MenuDropdown items={menuDropdownItemsList} />;
});

export default RulesPacksMenu;
