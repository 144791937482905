import { TransactionFiltersTypes, TransactionTypes } from "../../types/transactionTypes";
import request from "../request";

interface RequestType {
    filters: TransactionFiltersTypes | null;
    fields: (keyof TransactionTypes)[];
}

export interface GetFileIdResponse {
    message: string;
    data: { response: { task_id: string } }
}

export const getTransactionsFileId = (data: RequestType, signal?: AbortSignal) => request<GetFileIdResponse>({
    method: "POST",
    uri: "transactions/files/request/",
    data,
    signal,
});
