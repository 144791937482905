import { observer } from "mobx-react-lite";
import { memo } from "react";

import Routes from "../../../constants/routes";
import withPermission from "../../../hocs/withPermissions";
import useFetchWithAbort from "../../../hooks/useFetchWithAbort";
import rulesPackStore from "../../../store/rulesPack/rulesPackStore";
import { RulesPacksTypes } from "../../../types/rulesPacksTypes";
import Table from "../../ui/table/Table";
import { tableContainerStyle } from "../../ui/table/TableComponents";
import { ColumnType } from "../../ui/table/types";
import TableFieldsDetailsLink from "../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../ui/tableFields/statusBadge/TableFieldsStatusBadge";

interface ProtectedRulesPacksTableProps {
    permission: string;
}

const columns: ColumnType<RulesPacksTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.ruleName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${Routes.rulesPacks.href}/${dataItem.id}`}>{dataItem.name}</TableMainLink>
        ),
    },
    { header: "table.header.ruleId", field: "id", sortable: true },
    {
        header: "table.header.labels",
        field: "tags",
        getField: (dataItem) => dataItem.tags?.join(", "),
    },
    {
        header: "table.header.processing",
        field: "processing_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.partners", field: "partners_count" },
    { header: "table.header.services", field: "services_count", cellCenter: true },
    { header: "table.header.createdAt", field: "created" },
    { header: "table.header.lastUpdate", field: "last_update", sortable: true },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        sortable: true,
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={Routes.rulesPacks.href} />
        ),
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...rulesPackStore.pagination,
        setPaginationPage: rulesPackStore.setPaginationPage,
        setPaginationSize: rulesPackStore.setPaginationSize,
    };

    useFetchWithAbort((signal: AbortSignal) => {
        rulesPackStore.fetchData(signal);
    }, [rulesPackStore.pagination, rulesPackStore.filters, rulesPackStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={tableContainerStyle}
            columns={columns}
            data={rulesPackStore.data}
            pagination={tablePagination}
            checkedItems={rulesPackStore.checkedItems}
            sortMode={rulesPackStore.ordering}
            loading={rulesPackStore.loading}
            onSort={rulesPackStore.toggleSort}
            onCheckboxChange={rulesPackStore.toggleItem}
            onMainCheckboxChange={rulesPackStore.toggleMainItem}
        />
    );
});

const ProtectedRulesPacksTable = ({ permission }: ProtectedRulesPacksTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedRulesPacksTable);
