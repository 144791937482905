import { observer } from "mobx-react-lite";

import { useState } from "react";
import useTranslation from "../../../../hooks/useTranslation";
import transactionsStore from "../../../../store/home/basic/transactionsStore";
import TabHeader from "../../../tabHeader/TabHeader";
import EmptyView from "../../../ui/emptyView/EmptyView";
import Chart from "../components/Chart/Chart";
import ProgressInfo from "../components/ProgressInfo/ProgressInfo";
import { Box } from "../components/ProgressInfo/ProgressInfoComponents";
import {
    transactionsPercentsLinesConfigList,
    transactionsValuesLinesConfigList,
} from "../../../../constants/transactionsChartsStatistic";
import ChartLegend from "../components/Chart/ChartLegend/ChartLegend";
import { Segment } from "../components/Chart/ChartLegend/ChartLegendComponents";

const TransactionsCharts = () => {
    const { t } = useTranslation();

    const [configList, setConfigList] = useState(transactionsPercentsLinesConfigList);

    const toggleTransactionsView = (indicator: unknown) => {
        const lineConfig = indicator === "%" ? transactionsPercentsLinesConfigList : transactionsValuesLinesConfigList;

        setConfigList(lineConfig);
    };

    const TransactionsLegend = (
        <ChartLegend
            ChartSettings={(
                <Segment
                    options={["%", "N"]}
                    onChange={toggleTransactionsView}
                />
            )}
        />
    );

    return (
        transactionsStore.periodicStatistic.length ? (
            <>
                <TabHeader title={t.home.chartsTitle.transactions} />

                <Box>
                    <ProgressInfo type="decision" statistic={transactionsStore.afStatisticInfo} />
                    <ProgressInfo type="final" statistic={transactionsStore.coreStatistic} />
                </Box>

                <Chart
                    data={transactionsStore.periodicStatistic}
                    lines={configList}
                    LegendComponent={TransactionsLegend}
                />
            </>
        ) : (
            <EmptyView
                title={t.home.emptyView.title}
                text={t.home.emptyView.basic.text}
            />
        )
    );
};

export default observer(TransactionsCharts);
