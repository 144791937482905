import request from "../request";
import { LimitsTypes } from "../../types/limitsTypes";

interface ResponseType {
    message: string,
    response: LimitsTypes,
}

export const getSelectedLimit = (limitId: string, signal?: AbortSignal) => request<ResponseType>({
    method: "GET",
    uri: `limits/${limitId}/`,
    signal,
});
